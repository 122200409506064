import { Visibility, VisibilityOff } from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  Alert,
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useTheme } from "@mui/system";
import useAuth from "context/AuthContext";
import queryString from "query-string";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";

const LoginError = (props) => (
  <Alert severity="error" style={{ marginTop: 20 }}>
    {props.t("login_error_message")}
  </Alert>
);

const Login = (props) => {
  const { t } = useTranslation(["translation", "login"]);
  const { login, loading } = useAuth();
  const theme = useTheme();
  const styles = {
    // For custom image background
    // https://stackoverflow.com/questions/51014469/using-material-uis-apis-how-do-i-set-a-background-image-in-the-body
    "@global": {
      html: {
        height: "100%",
      },
    },

    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(6),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    siteAvatar: {
      margin: theme.spacing(1),
      backgroundColor: "white",
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      marginTop: theme.spacing(3),
      padding: 15,
      textTransform: "initial",
    },
    loginLink: {
      fontSize: 18,
      color: theme.palette.secondary.main,
      margin: theme.spacing(0, 1),
      textDecoration: "none",
    },
    accountIcon: {
      fontSize: theme.spacing(8),
    },
    input: {},
    logoIcon: {
      color: theme.palette.secondary.main,
      width: "1.5em",
      height: "1.5em",
    },
    logoWrapper: {
      textAlign: "center",
      margin: (theme.spacing(8), theme.spacing(4)),
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      position: "relative",
      margin: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
  };
  const location = useLocation();
  const [error, setError] = useState();
  const [state, setState] = useState({
    email_phone: "",
    password: "",
    showPassword: false,
  });

  const handleChange = (e) => {
    setError(null);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    login(state.email_phone, state.password).catch((error) => {
      setError(error.message);
    });
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let logoUrl = null;
  // check if we get here from the login link in academy's frontend site
  // in this case url should be .../login?siteid=othak OR .../login?siteid=othak&domain=com
  // if 'domain' param exists this means site has its own domain (if not exists means it is under FindSportClub.com subdomain)
  // TODO: CHECK IF SITE EXISTS
  const params = queryString.parse(location.search);
  if (params.siteid) {
    // check existence of domain param (we don't care about param value)
    logoUrl =
      params.domain !== undefined
        ? `${process.env.REACT_APP_HTTP}://${params.siteid}.${params.domain}${process.env.REACT_APP_SITE_PROFILE_PATH_WITH_DOMAIN}${params.siteid}/images/ui/logo.png` // data.logo
        : `${process.env.REACT_APP_HTTP}://${params.siteid}${process.env.REACT_APP_SITE_PROFILE_PATH}${params.siteid}/images/ui/logo.png`; // data.logo
  }

  return (
    <Paper sx={styles.paper} elevation={0}>
      {params.siteid ? (
        <>
          <Avatar sx={styles.siteAvatar} src={logoUrl}></Avatar>
          <Typography component="h1" variant="h6" style={{ textTransform: "initial" }}>
            {params.siteName} {t("Member Dashboard", { ns: "login" })}
          </Typography>
        </>
      ) : (
        <>
          <Avatar sx={styles.avatar}>
            <AccountCircle sx={styles.accountIcon} />
          </Avatar>
          <Typography component="h1" variant="h6" style={{ textTransform: "initial" }}>
            {"FindSportClub " + t("Member Dashboard", { ns: "login" })}
          </Typography>
        </>
      )}
      <Typography variant="subtitle2">{t("Sign in to continue", { ns: "login" })}</Typography>
      <form sx={styles.form} onSubmit={handleFormSubmit}>
        <FormControl margin="normal" required fullWidth>
          <TextField
            label={t("Email or phone", { ns: "login" })}
            id="email_phone"
            name="email_phone"
            autoComplete="true"
            autoFocus
            placeholder={t("Email or phone goes here...")}
            onChange={handleChange}
            variant="outlined"
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <TextField
            label={t("Password")}
            sx={styles.input}
            name="password"
            type={state.showPassword ? "text" : "password"}
            id="password"
            autoComplete="true"
            placeholder={t("password goes here...")}
            onChange={handleChange}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        {/*<FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />*/}

        <div sx={styles.wrapper}>
          <Button
            type="submit"
            value="SUBMIT"
            fullWidth
            variant="contained"
            color="secondary"
            size="large"
            disabled={loading}
            // disabled = {!state.password || !state.email_phone} // not working because browser does not trigger onchange after autofills the values
          >
            {t("Sign in")}
          </Button>
          {loading && <CircularProgress size={24} disableShrink sx={styles.buttonProgress} />}
        </div>
        {error && <LoginError t={t} />}
      </form>
      <Grid container maxwidth="sm" alignItems="center" style={{ marginTop: 24 }} justifyContent="center">
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <span style={{ fontSize: 18, marginRight: 8 }}>{t("Don't have an account yet?", { ns: "login" })}</span>
            <RouterLink
              to={params.siteid ? `/signup/${params.siteid}` : "/signup"}
              sx={styles.loginLink}
              style={{ fontSize: 18 }}
            >
              {t("Sign up")}
            </RouterLink>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ textAlign: "center", marginTop: 16 }}>
            <RouterLink to="/password-reset" sx={styles.loginLink} style={{ fontSize: 16 }}>
              {t("Did you forget your password?", { ns: "login" })}
            </RouterLink>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

// properties validation rules
// Login.propTypes = {
//   styles: PropTypes.object.isRequired, // styles is type of object and required
// };

// withStyles is the higher order component that maps the defined
// classnames to a property 'styles' on the passed in Component
// export default withStyles(styles)(Login);

// multiple hoc export
export default Login;
