import { List, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SiteChild from "./SiteChild";



const SiteChilds = (props) => {
    const { data } = props;
    const { t } = useTranslation() 
    return (
      <>
        <Typography gutterBottom variant="h6">
          {t("Childs in this sport club")}
        </Typography>
        <List>
          {data &&
            data.map((child) => (
              <>
                <SiteChild key={child.id} data={child} />
              </>
            ))}
        </List>
      </>
    );
  }

export default SiteChilds;
