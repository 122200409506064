import {
  Avatar,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const styles = {
  root: {}, // {width: '100%'},
  listItem: {
    paddingRight: 120,
    paddingTop: 16, // add top and bottom padding to be equal in height with left section
    paddingBottom: 16,
  }, //borderWidth: 1, borderColor: '#eeeeee',borderStyle: 'solid', borderRadius: '4px'} // or borderTop: '1px solid red'
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  badgeSubscriptions: {
    left: -3,
    // The border color match the background color.
    // border: `0px solid ${
    //   theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    // }`,
  },
  badgeChilds: {
    // The border color match the background color.
    left: 10,
    // border: `0px solid ${
    //   theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    // }`,
  },
};

const Childs = (props) => {
  const { childs } = props;
  const { t } = useTranslation();
  // console.log(childs);
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h5">{t("My Childs")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <List style={styles.root}>
          {childs.map((child) => (
            <NavLink
              to={{
                pathname: `/home/child/${child.id}`,
                state: { data: child },
              }}
              style={{ textDecoration: "none" }}
              key={child.id}
            >
              <ListItem style={styles.listItem} button>
                <ListItemAvatar>
                  <Avatar style={styles.avatar}>{child.firstname.charAt(0)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${child.lastname} ${child.firstname}`}
                  // secondary={
                  //   <>
                  //     <span
                  //       style={{
                  //         paddingRight: '10px',
                  //         borderRight: '1px solid #acacac',
                  //       }}
                  //     >
                  //       {t('Academies')}:{' '}
                  //       <span style={{ fontWeight: 'bold' }}>
                  //         {academiesNum}
                  //       </span>
                  //     </span>
                  //   </>
                  // }
                />
                <ListItemSecondaryAction>
                  <Button color="primary" aria-label="View" variant="contained">
                    {t("View")}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default Childs;
