import { gql } from "@apollo/client"

export const PERSON_QUERY = gql`query Person($id: ID!) {
  person(id: $id) {
    id
    firstname
    lastname
    address
    mobilephone
    email
   }
}`