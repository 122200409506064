import { gql } from "@apollo/client"

export const SITE_QUERY = gql`query Site($siteid: ID!) {
  site(siteid: $siteid) {
    siteid,
    name,
    domain_name,
    domain_extension,
    logo,
    connected_account
  }
}`
