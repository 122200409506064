import { useQuery } from "@apollo/client";
import { Alert, Grid, Typography } from "@mui/material";
import { STRIPE_PRICES } from "graphql/getPrices";
import { SINGLE_SITE_PROFILE_QUERY } from "graphql/getSingleSiteProfile";
import CheckoutSubscription from "pages/home/stripe/CheckoutSubscription";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { calculatePrice } from "../../../../utils/stripeFunctions";
import StripeCustomerIntegration from "./StripeCustomerIntegration";
import StripePriceItem from "./StripePriceItem";
import CheckoutChilds from "./stripe/CheckoutChilds";

const ManageSiteSubscriptions = (props) => {
  const { siteid, siteUserid } = useParams();
  const { t } = useTranslation();
  const styles = {
    root: {
      width: "100%",
      textAlign: "center",
    },
  };
  const [subscriptionData, setSubscriptionData] = useState(null);
  // const [stateChilds, setStateChilds] = useState(null);
  const [checkedChilds, setCheckedChilds] = useState(0);

  /**
   * Check which child was checked through checkbox. Count the number of checked children
   *
   * @param {*} e
   */
  const checkClick = (e) => {
    const checked = e.target.checked;
    let childsCount = checkedChilds;
    checked ? childsCount++ : childsCount--;
    setCheckedChilds(childsCount);
    if (subscriptionData !== null && childsCount !== 0) {
      const { price } = subscriptionData;
      const tempAmount = calculatePrice({ price, childsCount, tempAmount: 0 });
      const updateSubscriptionData = {
        ...subscriptionData,
        planAmount: tempAmount,
        checkedChilds: childsCount,
      };
      setSubscriptionData(updateSubscriptionData);
    } else if (childsCount === 0) {
      setSubscriptionData(null);
    }
  };

  const buyClick = (data) => {
    const { price } = data;
    const { currency, id, nickname: planName } = price;
    const tempAmount = calculatePrice({ price, childsCount: checkedChilds, tempAmount: 0 });
    setSubscriptionData({
      price: price,
      planAmount: tempAmount,
      planName,
      currency,
      id,
      checkedChilds: checkedChilds,
    });
  };
  const prices = useQuery(STRIPE_PRICES, {
    variables: { siteid },
  });

  const siteProfile = useQuery(SINGLE_SITE_PROFILE_QUERY, {
    variables: { id: siteUserid, siteid },
  });

  const loading = prices.loading || siteProfile.loading;
  const errors = prices.error || siteProfile.error;
  if (loading) return <div>{t("Fetching")}</div>;
  if (errors)
    return (
      <Alert severity="error">
        {t("Sport club has not yet enabled subscriptions. Get in touch with sport club to solve the issue")}
      </Alert>
    );

  const { stripePrices } = prices.data;
  const { singleSiteProfile: profile } = siteProfile.data;
  const { childs } = profile;
  return (
    <>
      <Grid container sx={styles.root} justifyContent="center" spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Typography gutterBottom variant="h6">
                {t("Buy Subscriptions")}
              </Typography>
            </Grid>
            <Grid item>
              <StripeCustomerIntegration siteid={siteid} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
          <CheckoutChilds childs={childs} checkClick={checkClick} />
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
          <CheckoutSubscription
            siteid={siteid}
            customerid={profile.connect_customerid}
            subscriptionData={subscriptionData}
            // childs={stateChilds}
            childs={childs}
          />
        </Grid>
        <Grid item>
          <Typography gutterBottom variant="h6">
            {t("Subscription Packets by Sport Club")} {profile.site.name}
          </Typography>
        </Grid>
        <Grid container justifyContent="center" spacing={3}>
          {stripePrices.map((price) => (
            <StripePriceItem key={price.id} price={price} buyClick={(data) => buyClick(data)} />
          ))}
        </Grid>
      </Grid>
    </>
  );
};
export default ManageSiteSubscriptions;
