import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import HistoryIcon from '@mui/icons-material/History';
import LocationIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import MobilePhoneIcon from "@mui/icons-material/Smartphone";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ProfileHelper from "../../../../utils/ProfileHelper";

const SiteProfileGeneral = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const styles = {
    card: {
      maxWidth: "100%",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    actions: {
      display: "flex",
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition:(theme) => theme.transitions.create("transform", {
        duration:(theme) => theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red[500],
    },  
  }
  const uniqueNames = new Set();
  const uniqueSeasons = data.seasons.filter(season => {
    const isDuplicate = uniqueNames.has(season.name);  
    uniqueNames.add(season.name);
    if (!isDuplicate) {
      return true;
    }
    return false;
  });
  
  return (
    <Card sx={styles.card}>
      <CardHeader
        avatar={
          <Avatar
            aria-label={t("Profile")}
            src={ProfileHelper.getProfilePhoto(data)}
            sx={styles.avatar}
          ></Avatar>
        }
        title={data.lastname}
        subheader={data.firstname}
      />

      <CardContent>
        <List component="nav">
        <ListItem divider>
            <ListItemIcon><HistoryIcon />
            </ListItemIcon>
            <ListItemText primary={uniqueSeasons.map((season) => (<Chip label={season.name} color="primary" />))} secondary={t("Seasons")} />
          </ListItem>
          <ListItem divider>
            <ListItemIcon>
              <MobilePhoneIcon />
            </ListItemIcon>
            <ListItemText primary={data.mobilephone} secondary={t("Mobile phone")} />
          </ListItem>
          <ListItem divider>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary={data.phone} secondary={t("Phone")} />
          </ListItem>
          <ListItem divider>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary={data.email} secondary={t("Email")} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LocationIcon />
            </ListItemIcon>
            <ListItemText primary={`${data.city} , ${data.address}, ${data.postcode}`} secondary={t("Address")} />
          </ListItem>
        </List>
      </CardContent>
      <CardActions sx={styles.actions} disableActionSpacing>
       {data.edit_by_user && <Button
          variant="contained"
          startIcon={<EditIcon />}
          color="primary"
          style={styles.listButton}
          component={NavLink}
          to={{
            pathname: `/home/siteprofile/update/${data.userid}/${data.siteid}`,
          }}
        >
          {t("Update")}
        </Button>}
      </CardActions>
    </Card>
  );
};

export default SiteProfileGeneral;
