import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
// import InputLabel from '@mui/material/InputLabel';

function valuetext(value) {
  // if(value===100)
  //   return `${value}+`;
  // else
  return `${value}`;
}

function valueLabelFormat(value) {
  // return marks.findIndex(mark => mark.value === value) + 1;
  return valuetext(value);
}

const DistanceSlider = (props) => {
  const { filters, setFilters } = props;
  const { t } = useTranslation();

  const handleDistanceChange = (ev, value) => {
    setFilters({
      ...filters,
      distance: value,
    });
  };

  // uncomment marks prop to display them

  return (
    <>
      <Typography
        sx={{
          color: "rgba(0,0,0,0.87)",
          marginTop: (theme) => theme.spacing(2),
        }}
      >
        {/*{filters.distance < 100 ? `Distance < ${filters.distance}` : `Distance: any`}*/}
        {t("distance_sentence", { distance: filters.distance })}
      </Typography>
      <Slider
        id="distance"
        disabled={filters.address ? false : true}
        sx={{
          width: "100%", // 200,
          marginTop: (theme) => theme.spacing(-2),
        }}
        defaultValue={filters.distance}
        onChange={handleDistanceChange}
        getAriaValueText={valuetext}
        aria-labelledby="distance-slider"
        valueLabelDisplay="off"
        valueLabelFormat={valueLabelFormat}
        step={1}
        // marks={marks}
        min={1}
        max={100}
      />
    </>
  );
};

export default DistanceSlider;
