import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import LandingSearch from "./LandingSearch";

// for more advanced content loader
// https://github.com/danilowoz/react-content-loadergress'

const backgroundImage = "/images/hero.jpg";
const memberImage = "/images/member-online.png";
const parentChildImage = "/images/parent-child.png";

export default function Search(props) {
  const styles = {
    landingSection: {
      padding: "50px 0",
    },
    selectEmpty: {
      marginTop: (theme) => theme.spacing(2),
    },
    button: {
      margin: (theme) => theme.spacing(2),
    },
    submitContainer: {
      paddingTop: 25,
    },
    searchTitle: {
      textTransform: "initial",
      fontSize: 30,
    },
    searchHero: {
      minHeight: "20vh",
      width: "100%",
      paddingBottom: 1,
      paddingTop: 10,
    },
    searchHeroImageContainer: {},
    searchHeroMainImage: {
      width: "100%",
    },
    searchHeroFeature: {
      backgroundColor: (theme) => theme.palette.secondary.main,
      paddingTop: 9,
      paddingBottom: 10,
      marginTop: -5,
      color: "white",
    },
    searchHeroFeatureImageContainer: {
      marginTop: 2,
      width: "100%",
    },
    searchHeroFeatureImage: {
      width: "33%",
      height: "auto",
      maxWidth: 520,
    },
    searchHeroSkewEffect: {
      width: "100%",
      height: "18vh",
      transform: "skewY(-2.5deg)",
      marginTop: -56,
      minHeight: 120,
      backgroundColor: (theme) => theme.palette.secondary.main,
    },
    sectionTitle: {
      textTransform: "initial",
      fontSize: 32,
    },
  };
  const { t } = useTranslation();

  // const { t, i18n } = useTranslation();

  return (
    <Box id="search-hero" sx={styles.searchHero}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            color="inherit"
            align="center"
            variant="h4"
            marked="center"
            component="h1"
            sx={styles.searchTitle}
          >
            {t("Find sports or activities near you")}
          </Typography>
          <Typography color="inherit" align="center" variant="subtitle1" marked="center" component="h6">
            <Trans i18nKey="searchHeroSubtitle">
              <strong>Join</strong> to one or more of the listed sports clubs and enjoy easy{" "}
              <strong>online access</strong> to your subscriptions and data
            </Trans>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LandingSearch />
        </Grid>
      </Grid>

      {/*<SearchForm />*/}

      {/* Owner link */}
      <Box sx={styles.ownerLink}>
        <Container maxWidth="md">
          <Grid container alignItems="center" justifyContent="center" item xs={12}>
            <Button color="secondary" size="small" sx={styles.button} component={Link} to="/register">
              {t("Are you sports club administrator?")}
            </Button>
          </Grid>
        </Container>
      </Box>

      {/* hero image */}
      <Box sx={styles.searchHeroFeatureImageContainer}>
        <img src={backgroundImage} style={styles.searchHeroMainImage} alt="" />
      </Box>

      {/* hero features */}
      <Box sx={styles.searchHeroFeature}>
        <Container maxWidth="md">
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <Typography
                color="inherit"
                align="center"
                variant="h4"
                marked="center"
                component="h6"
                sx={styles.sectionTitle}
              >
                {t("Less hassle for parents or individuals")}
              </Typography>
              <Typography color="inherit" align="center" variant="body1" marked="center" component="h6">
                {t("explain_why_use")}
              </Typography>
            </Grid>
            <Grid item>
              <Button size="large" variant="contained" sx={styles.button} component={Link} to="/signup">
                {t("Sign up")}
              </Button>
            </Grid>
            <Grid item>
              <Button size="large" variant="contained" sx={styles.button} component={Link} to="/memberFeatures">
                {t("Discover more...")}
              </Button>
            </Grid>
          </Grid>
          <Box sx={styles.searchHeroFeatureImageContainer}>
            <img src={parentChildImage} sx={styles.searchHeroFeatureImage} alt="" />
            <img src={memberImage} sx={styles.searchHeroFeatureImage} alt="" />
          </Box>
        </Container>
      </Box>

      {/*<div class={styles.searchHeroSkewEffect}>
    </div>*/}
    </Box>
  );
}
