import { Outlet } from "react-router-dom";
//
import { Box } from "@mui/system";
import Footer from "../components/Footer";
import Header from "../components/Header";

// Our approach is header and footer to be permanent on all pages.
// But in landing page there is some special styling for header.

// https://css-tricks.com/couple-takes-sticky-footer/  (flex)
const LandingLayout = ({ children }) => (
  <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
    <Header />
      <Outlet />
    <Footer />
  </Box>
);

export default LandingLayout;
