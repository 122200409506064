import UserAvatar from '../static/images/avatar.png'

export const POLICY_NO_DATA = 0
export const POLICY_BASIC_DATA = 1
export const POLICY_BASIC_DATA_PHOTO = 2
export const POLICY_EXTRA_DATA = 3
export const POLICY_EXTRA_DATA_PHOTO = 4



export const policies = [
      {id: POLICY_NO_DATA, label: 'Do not show any data'},
      {id: POLICY_BASIC_DATA, label: 'basic (name + lastname, no photo)'},
      {id: POLICY_BASIC_DATA_PHOTO, label: 'basic + photo'},
      {id: POLICY_EXTRA_DATA, label: 'full (extra data, no photo)'},
      {id: POLICY_EXTRA_DATA_PHOTO, label: 'full + photo'}
]

export default class ProfileHelper {

    static getSiteLogo = profile => {
      if(!profile || !profile.site)
        return null;
      if (profile.site.domain_name) {
        return `${process.env.REACT_APP_HTTP}://${profile.siteid}.${profile.site.domain_extension}${process.env.REACT_APP_SITE_PROFILE_PATH_WITH_DOMAIN}${profile.siteid}/images/ui/${profile.site.logo}`
      } else {
        return `${process.env.REACT_APP_HTTP}://${profile.siteid}${process.env.REACT_APP_SITE_PROFILE_PATH}${profile.siteid}/images/ui/${profile.site.logo}`
      }
    }

    static getProfilePhoto = profile => {
      // console.log('PUBLIC',process.env.PUBLIC_URL)
      if(!profile.photo)
        return UserAvatar

      // console.log('http://' + profile.siteid + process.env.VUE_APP_SITE_PROFILE_PATH + profile.siteid + '/avatars/' + profile.photo)
      if (profile.domain_name) {
        return `${process.env.REACT_APP_HTTP}://${profile.siteid}.${profile.site.domain_extension}${process.env.REACT_APP_SITE_PROFILE_PATH_WITH_DOMAIN}${profile.siteid}/avatars/${profile.photo}`
      } else {
        return `${process.env.REACT_APP_HTTP}://${profile.siteid}${process.env.REACT_APP_SITE_PROFILE_PATH}${profile.siteid}/avatars/${profile.photo}`
      }
    }

        
    static getPolicyLabel = policyId => {
       var result = policies.filter(policy => policy.id === policyId );
       return result? result[0].label : null; // or undefined
    } 
}

//default export Utils