import { Grid, Typography } from "@mui/material";
import Button from "components/Button";
import Features from "components/Features";
import Pricing from "components/Pricing";
import StaticPage from "components/StaticPage";
import useAuth from "context/AuthContext";
// import Dashboard from './Dashboard';
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import * as Klaro from "klaro";
import HomeLayout from "layouts/home/HomeLayout";
import Child from "pages/home/child/Child";
import AddChild from "pages/home/childs/addChild";
import Childs from "pages/home/childs/Childs";
import UpdateChild from "pages/home/childs/updateChild";
import Overview from "pages/home/overview/Overview";
import UpdateProfile from "pages/home/profile/updateProfile";
import Profiles from "pages/home/profiles/Profiles";
import SearchAcademies from "pages/home/searchAcademies/SearchAcademies";
import Applications from "pages/home/signup/Applications";
import ManageSiteSubscriptions from "pages/home/siteprofile/components/ManageSiteSubscriptions";
import SiteProfile from "pages/home/siteprofile/SiteProfile";
import UpdateSiteUser from "pages/home/siteprofile/updateSiteUser";
import Subscriptions from "pages/home/subscriptions/Subscriptions";
import AcademyProfile from "pages/public/academy/AcademyProfile";
import Contact from "pages/public/contact/Contact";
import PlatformFeatures from "pages/public/features/PlatformFeatures";
import Landing from "pages/public/landing/Landing";
import Login from "pages/public/login/Login";
import PlatformMemberFeatures from "pages/public/memberFeatures/PlatformMemberFeatures";
import PasswordReset from "pages/public/passwordReset/PasswordReset";
import RegistrationSuccess from "pages/public/registration/RegistrationSuccess";
import Why from "pages/public/registration/Why";
import UserRegistration from "pages/public/userRegistration/UserRegistration";
import { lazy, Suspense, useContext, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
// import ScrollToTop from "./components/ScrollToTop"; // scroll to top of the page after location url has changed
// import PageScrollToTop from "./components/PageScrollToTop"; // scroll to top of the page when we are down the page
// import Registration from './pages/public/registration/Registration';
import { Box } from "@mui/system";
import PasswordConfirm from "pages/public/passwordReset/PasswordConfirm";
import Error404 from "./components/error-handling/Error404";
import { AppDataContext } from "./context/index";
import LandingLayout from "./layouts/LandingLayout";
import LoginLayout from "./layouts/LoginLayout";

// import MessengerCustomerChat from "react-messenger-customer-chat";

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

const config = {
  privacyPolicy: "/privacy",
  translations: {
    en: {
      consentModal: {
        description: "Here you can see and customize the information that we collect about you",
        privacyPolicy: {
          name: "Privacy Policy",
          text: "For more info please read the {privacyPolicy}.",
        },
      },
      matomo: {
        description: "Collecting of visitor statistics",
      },
      cloudflare: {
        description: "Protection against DDoS attacks",
      },
      purposes: {
        analytics: "Analytics",
        security: "Security",
        functionality: "Functionality",
        advertising: "Advertising",
      },
    },
  },
  services: [
    {
      // Each app should have a unique (and short) name.
      name: "matomo",

      // If "default" is set to true, the app will be enabled by default
      // Overwrites global "default" setting.
      // We recommend leaving this to "false" for apps that collect
      // personal information.
      // default: true,

      // The title of you app as listed in the consent modal.
      title: "Matomo",

      // The purpose(s) of this app. Will be listed on the consent notice.
      // Do not forget to add translations for all purposes you list here.
      purposes: ["analytics"],

      // A list of regex expressions or strings giving the names of
      // cookies set by this app. If the user withdraws consent for a
      // given app, Klaro will then automatically delete all matching
      // cookies.
      cookies: [
        // you can also explicitly provide a path and a domain for
        // a given cookie. This is necessary if you have apps that
        // set cookies for a path that is not "/" or a domain that
        // is not the current domain. If you do not set these values
        // properly, the cookie can't be deleted by Klaro
        // (there is no way to access the path or domain of a cookie in JS)
        [/^_pk_.*$/, "/"], //for the production version
      ],

      // An optional callback function that will be called each time
      // the consent state for the app changes (true=consented). Passes
      // the `app` config as the second parameter as well.
      // callback : function(consent, app){
      // },

      // If "required" is set to true, Klaro will not allow this app to
      // be disabled by the user.
      // required : false,

      // If "optOut" is set to true, Klaro will load this app even before
      // the user gave explicit consent.
      // We recommend always leaving this "false".
      optOut: true,

      // If "onlyOnce" is set to true, the app will only be executed
      // once regardless how often the user toggles it on and off.
      // onlyOnce: false,
    },

    // The apps will appear in the modal in the same order as defined here.
    {
      name: "cloudflare",
      title: "Cloudflare",
      purposes: ["security"],
      required: true,
    },
  ],
};

window.klaro = Klaro;
window.klaroConfig = config;
Klaro.setup(config);

const Dashboard = lazy(() => import("./Dashboard"));
const RegistrationLanding = lazy(() => import("./pages/public/registration/RegistrationLanding"));
const SearchWithMap = lazy(() => import("./components/SearchWithMap"));
const RegistrationFormPage = lazy(() => import("./pages/public/registration/RegistrationFormPage"));
const Help = lazy(() => import("pages/public/help/Help"));
const Demo = lazy(() => import("pages/public/registration/Demo"));

function App(props) {
  const dataContext = useContext(AppDataContext);
  const { loading } = dataContext;
  const { t } = useTranslation();
  const { trackPageView, trackEvent } = useMatomo();

  // Track page view
  useEffect(() => {
    trackPageView();
  }, []);

  const RequireAuth = ({ children }) => {
    const { loggedIn } = useAuth();
    if (!loggedIn()) return <Navigate to="/login" replace />;
    return children;
  };

  function ErrorFallback({ error, resetErrorBoundary }) {
    // reset in case data is undefined when we have a token refreshed
    if (error.message === "data is undefined") {
      resetErrorBoundary();
    }
    return (
      <Grid container alignItems="center" justifyContent="center" direction="row">
        <Grid item xs={12}>
          <Typography variant="h1" marked="center">
            {t("Something went wrong")}
          </Typography>
          <Typography variant="h5" marked="center" gutterBottom>
            {error.message}
          </Typography>
          <div style={{ marginTop: 50 }}>
            <Button color="secondary" size="large" variant="contained" onClick={resetErrorBoundary}>
              {t("Try again")}
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  }

  // const { trackPageView } = useMatomo();

  // React.useEffect(() => {
  //   trackPageView({
  //     // documentTitle: 'Page title', // optional
  //     // href: 'http://localhost:3000/'+ window.location.hash.substr(1), // optional);
  //   });
  // }, []);

  // For proper scrolling restoration we use ScrollToTop, else when navigate to other route the previous page scroll position persists
  // https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md

  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Suspense fallback={<Loader />}>
        <Box sx={{ minHeight: "100vh" }}>
          {loading && <Loader />}
          {!loading && (
            <Routes>
              <Route element={<Dashboard />}>
                <Route element={<LoginLayout />}>
                  <Route path="/login" element={<Login />} />
                  <Route path="/password-reset" element={<PasswordReset />} />
                  <Route path="/confirm-password-form/:passwordToken" element={<PasswordConfirm />} />
                </Route>
                <Route
                  path="/home"
                  element={
                    <RequireAuth>
                      <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={() => {
                          // reset the state of your app so the error doesn't happen again
                        }}
                      >
                        <HomeLayout />
                      </ErrorBoundary>
                    </RequireAuth>
                  }
                >
                  <Route index element={<Overview />} />
                  <Route path="subscriptions" element={<Subscriptions />} />
                  <Route path="childs" element={<Childs />} />
                  <Route path="childs/add" element={<AddChild />} />
                  <Route path="childs/update/:id" element={<UpdateChild />} />
                  <Route path="child/:id" element={<Child />} />
                  <Route path="profile" element={<UpdateProfile />} />
                  <Route path="siteprofile/:siteid" element={<SiteProfile />} />
                  <Route path="siteprofile/update/:userid/:siteid" element={<UpdateSiteUser />} />
                  <Route path="profiles" element={<Profiles />} />
                  {/* <Route
                      path="/home/subscription/:id"
                      component={withTransition(Subscription)}
                    /> */}

                  <Route path="applications" element={<Applications />} />
                  <Route path="manageSiteSubscriptions/:siteid/:siteUserid" element={<ManageSiteSubscriptions />} />
                  <Route path="search/:userid" element={<SearchAcademies />} />
                </Route>
              </Route>
              {/* Public pages */}
              <Route path="/search" element={<SearchWithMap />} />
              <Route path="/register" element={<RegistrationLanding />}>
                <Route path="/register/why" element={<Why />} />
                <Route path="/register/pricing" element={<Pricing />} />
                <Route path="/register/architecture" element={<Features />} />
                {/* <Route path="/register/pricing" element={<Pricing />} /> */}
              </Route>
              <Route path="/register-form" element={<RegistrationFormPage />} />
              <Route path="/support" element={<Help />} />
              <Route path="/demo" element={<Demo />} />
              {/* all other routes which do not need custom layout are rendered in LandingLayout */}
              <Route element={<LandingLayout />}>
                <Route path="/signup" element={<UserRegistration />} />
                <Route path="/signup/:siteid" element={<UserRegistration />} />
                {/* registerSuccess is a "private" route because it shows only after registration and cant be accessible as a normal route, so we use location.state */}
                <Route path="/registerSuccess" element={<RegistrationSuccess />} />
                <Route path="/contact" element={<Contact />} />
                {/*<Route exact path="/search" render={() => <Search/> } />*/}
                {/*<Route exact path="/search" render={() => <SearchWithMap /> } />*/}
                <Route path="/features" element={<PlatformFeatures />} />
                <Route path="/memberFeatures" element={<PlatformMemberFeatures />} />
                <Route path="/academy/:siteid" element={<AcademyProfile />} />
                <Route path="/terms" element={<StaticPage name={"terms"} />} />
                <Route path="/privacy" element={<StaticPage name={"privacy"} />} />
                <Route path="/" element={<Landing />} />
              </Route>

              <Route path="/*" element={<Error404 />} />
            </Routes>
          )}
        </Box>
        {/* <PageScrollToTop {...props}>
              <Fab color="primary" size="small" aria-label="scroll back to top">
                <Icon>Keyboard arrow up</Icon>
              </Fab>
            </PageScrollToTop> */}
      </Suspense>
    </Box>
  );
}

export default App;
