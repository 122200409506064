const ROLE_PARENT = "parent";
const ROLE_PLAYER = "player";
const ROLE_PARENT_PLAYER = "parent_player";

const roles = [
  { value: ROLE_PARENT, label: "Parent" },
  { value: ROLE_PLAYER, label: "Adult athlete" },
  { value: ROLE_PARENT_PLAYER, label: "Both of the above" },
];

export {
  roles,
  ROLE_PLAYER,
  ROLE_PARENT,
  ROLE_PARENT_PLAYER,
  ROLE_PARENT as ROLE_DEFAULT,
};
