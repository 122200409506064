import CloseIcon from "@mui/icons-material/Close";
import DirectionsIcon from "@mui/icons-material/Directions";
import MapCenterIcon from "@mui/icons-material/GpsFixed";
import LocationIcon from "@mui/icons-material/LocationOn";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import NavigationIcon from "@mui/icons-material/Search";
import { Box, Grid, Typography } from "@mui/material";
import MuiButton from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SiteHelper from "../utils/SiteHelper";
import SiteCardLogo from "./SiteCardLogo";
import SiteSports from "./SiteSports";
import SignupToAcademy from "./academy_registration/SignupToAcademy";

const SiteCard = (props) => {
  const styles = {
    root: {
      // display: "flex",
      // flexWrap: "wrap",
    },
    button: {
      margin: (theme) => theme.spacing(2),
    },
    cardLink: {
      textDecoration: "none",

      "&:focus, &:hover, &:visited, &:link, &:active": {
        textDecoration: "none",
      },
    },
    cardViewButton: {
      // color: "#673ab7"
    },
    cardActions: {
      padding: (theme) => theme.spacing(2),
      justifyContent: "space-between",
    },
    buttonIcon: {
      marginRight: (theme) => theme.spacing(1),
    },
    city: {
      textTransform: "uppercase",
    },
    region: {
      textTransform: "capitalize",
    },
    sport: {
      marginRight: (theme) => theme.spacing(0.5),
    },
    wrapIcon: {
      verticalAlign: "middle",
      display: "inline-flex",
    },
    locationIcon: {
      color: "#fb5f53",
    },
    locationDetails: {
      fontWeight: 100,
      fontSize: 14,
      color: "grey",
    },
    sportsTitle: {
      fontWeight: 100,
      fontSize: 14,
      color: "grey",
      marginLeft: 4,
    },
    siteLogoWrap: {},
    siteName: {
      // height: 68,
      // overflow: 'hidden',
      color: "black",
    },
    name: {
      fontSize: 16,
    },
    distance: {
      // marginLeft: 16
    },
    signupDialog: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content",
      padding: (theme) => theme.spacing(1),
    },
    closeSignupDialogButton: {
      position: "absolute",
      right: (theme) => theme.spacing(1),
      top: (theme) => theme.spacing(1),
      color: (theme) => theme.palette.grey[500],
    },
  };
  const { site, compact, setCurrentMapCenter, setPlace, signed, pending } = props;

  const { t } = useTranslation();

  const [applied, setApplied] = React.useState(pending);

  const handleClickLocateOnMap = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    setCurrentMapCenter({
      lat: site.mapCoordinates.x,
      lng: site.mapCoordinates.y,
    });
    setPlace(site);
  };

  const handleClickDirections = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    let url = "https://www.google.com/maps/dir/?api=1&destination=";
    if (site.mapCoordinates) url = url + site.mapCoordinates.x + "," + site.mapCoordinates.y;
    window.open(url); // opens a new tab
  };

  const [signupOpen, setSignupOpen] = React.useState(false);

  const handleSignupOpen = () => {
    setSignupOpen(true);
  };

  const handleSignupClose = () => {
    setSignupOpen(false);
  };

  return (
    <>
      <Dialog open={signupOpen} onClose={handleSignupClose}>
        <div style={styles.signupDialog}>
          <SignupToAcademy site={site} handleClose={handleSignupClose} setApplied={setApplied} />
          <IconButton aria-label="close" sx={styles.closeSignupDialogButton} onClick={handleSignupClose} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </Dialog>

      <Card
        sx={{
          textAlign: "left",
          margin: compact ? (theme) => theme.spacing(1) : (theme) => theme.spacing(1),
          marginTop: (theme) => theme.spacing(1),
          borderRadius: (theme) => theme.spacing(1),      
          // Provide some spacing between cards
          border: "1px solid #dedede",
          display: "flex",
          flexDirection: "column",
    
          // Justify the content so that CardContent will always be at the top of the card,
          // and CardActions will be at the bottom
          justifyContent: "space-between",
    
        }}
        elevation={0}
      >
        <Box sx={{ position:"relative"}}>
        <SiteCardLogo site={site} size={50} />
        </Box>
        <CardActionArea
          sx={styles.cardLink}
          component={Link}
          to={{ pathname: `/academy/${site.siteid}`, state: { ...site } }}
        >
          <CardMedia
            alt={site.name}
            sx={{ height: 200 }}
            image={SiteHelper.getSiteDefaultImage({ site })}
            title={site.name}
          />
          {/*
                  Displays site profile gallery as carousel (disabled for now for speed purposes, if enabled then CardMedia must be disabled) 
                  <CardCarousel gallery = {site.gallery ? site.gallery : null } />
                */}
          <CardContent sx={styles.siteName}>
            <Typography sx={styles.name} variant="h5" component="h2" noWrap gutterBottom>
              {site.name ? site.name : site.siteid}
            </Typography>

            {/*<Rating value={4} readOnly />*/}
            {/* <Typography gutterBottom component="p" color="textSecondary">{site.description}</Typography> */}
            <Typography sx={styles.cityRegion} variant="subtitle2" component="h6" color="textSecondary" noWrap>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <LocationIcon sx={styles.locationIcon} />
                </Grid>
                <Grid item sx={styles.locationDetails}>
                  <span style={styles.city}>{site.cityDetails && site.cityDetails.cities_name}</span>
                  <span style={styles.region}>{site.regionDetails && `, ${site.regionDetails.prefectures_name}`}</span>
                </Grid>
                <Grid item></Grid>
              </Grid>
            </Typography>

            {site.distance && (
              <Typography noWrap style={{ paddingBottom: 5 }}>
                <span style={styles.distance}>{t("distanceAway", { km: site.distance.toFixed(1) })}</span>
                <IconButton
                  title="reveal on map"
                  style={{ marginLeft: 15 }}
                  color="secondary"
                  aria-label="reveal on map"
                  onClick={handleClickLocateOnMap}
                  size="large"
                >
                  <MapCenterIcon />
                </IconButton>

                <IconButton
                  title="get directions to this location"
                  style={{ marginLeft: 5 }}
                  color="secondary"
                  aria-label="Get directions to this location"
                  onClick={handleClickDirections}
                  size="large"
                >
                  <DirectionsIcon />
                </IconButton>
              </Typography>
            )}

            <SiteSports sports={site.sports} />
          </CardContent>
        </CardActionArea>

        {!compact && (
          <CardActions sx={styles.cardActions}>
            <MuiButton
              size="small"
              variant="contained"
              color="secondary"
              sx={styles.cardViewButton}
              component={Link}
              to={`/academy/${site.siteid}`}
            >
              <NavigationIcon sx={styles.buttonIcon} />
              {t("View")}
            </MuiButton>
            {props.home && (
              <MuiButton
                onClick={handleSignupOpen}
                disabled={signed || pending || applied}
                size="small"
                variant="outlined"
                color="secondary"
                sx={styles.cardViewButton}
                startIcon={<PersonAddIcon sx={styles.buttonIcon} />}
              >
                {signed ? t("Signed") : pending || applied ? t("Pending") : t("Sign up")}
              </MuiButton>
            )}
            {!props.home && (
              <MuiButton
                size="small"
                variant="outlined"
                color="secondary"
                sx={styles.cardViewButton}
                component={Link}
                to={`/signup/${site.siteid}`}
                startIcon={<PersonAddIcon sx={styles.buttonIcon} />}
              >
                {t("Sign up")}
              </MuiButton>
            )}
          </CardActions>
        )}
      </Card>
    </>
  );
};

export default SiteCard;
