import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import useAuth from "context/AuthContext";
import { PERSON_WITH_CHILDS_QUERY } from "graphql/getPersonWithChilds";
import { useTranslation } from "react-i18next";
import Childs from "./components/Childs";
import Sites from "./components/Sites";
import Welcome from "./components/Welcome";

const styles = {
  root: {
    width: "100%",
    maxWidth: 500,
  },
  infoSection: {
    marginTop: 32,
  },
};

const Overview = (props) => {
  const { user } = useAuth()
  const { t } = useTranslation()

  const { loading, error, data } = useQuery(PERSON_WITH_CHILDS_QUERY, {
    variables: { id: user.id },
  });
  if (loading) return <div>{t("Fetching")}</div>;
  if (error) return <div>{t("Error")}</div>;

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
          <Welcome user={user} />
        </Grid>
        {/* <Grid container style={{textAlign: "center"}}>
                  <Grid item xs={12} md={3} style={{marginTop: '20px'}}>
                     <CurrentDebtOverview user={user} data={data}/>  
                  </Grid>
                  <Grid item xs={12} md={9}  style={{marginTop: '70px'}}>
                     <SubscriptionStatistics user={user} /> 
                  </Grid>               
                </Grid> */}
        <Grid item xs={12} md={6} style={styles.infoSection}>
          <Sites user={user} siteProfiles={data.person.siteProfiles} />
        </Grid>
        <Grid item xs={12} md={6} style={styles.infoSection}>
          <Childs childs={data.person.childs} />
        </Grid>
        <Grid item xs={12} style={styles.infoSection}>
          {/* <Subscriptions user={user} siteProfiles={data.person.siteProfiles}/>  */}
        </Grid>
        <Grid item xs={12} style={styles.infoSection}>
          {/* <MonthObligations user={user} siteProfiles={data.person.siteProfiles}/>  */}
        </Grid>
        {/* <Grid item xs={12}>
                  <Counters user={user} data={data.person.siteProfiles}/> 
                </Grid>   */}
      </Grid>
    </>
  );
};

export default Overview;
