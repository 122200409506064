import { gql } from "@apollo/client"

export const SITE_USER_QUERY = gql`query SingleSiteProfile ($userid: ID!, $siteid: String!) {
  singleSiteProfile(userid: $userid, siteid: $siteid) {
    id,
    userid,
    siteid,
    firstname,
    lastname,
    photo,
    personal_data_policy,
    mobilephone,
    phone,
    address,
    postcode,
    connect_customerid,
    email,
    status,
    edit_by_user,
  }
}`
