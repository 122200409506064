import { gql } from "@apollo/client"

export const PERSON_WITH_CHILDS_QUERY = gql`query PersonWithChilds ($id: ID!) {
  person(id:$id) {
    id,
    firstname,
    lastname,
    photo,
    mobilephone,
    phone,
    address,
    postcode,
    email,
    siteProfiles(id:$id) {
      id,
      userid,
      siteid,
      firstname,
      lastname,
      childs {
        firstname
        lastname
      },
      site {
        siteid,
        name,
        logo,
        domain_name
      }
    },
    childs(parentId:$id) {
      id,
      firstname,
      lastname
    },
    userRegistrations(id:$id) {
      id,
      userid,
      siteid,
      status,
      data,
      comments,
      created_at 
    }
  }
}`
