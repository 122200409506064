// KEEP THIS WRAPPER FOR FUTURE USE E.G. IF WE WANT DIFFERENT HEADER BEHAVIOR (HIDE ON SCROLL) FOR LANDING PAGE

import { Box } from "@mui/material";
import React from "react";
import CommonHeader from "./CommonHeader";
import MobileHeader from "./MobileHeader";
import MobileMenu from "./MobileMenu";

const Header = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <>
      <Box sx={{ 
        display: { xs: "none", sm: "none", md: "flex" },
      }}>
        <CommonHeader />
      </Box>
      <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
        <MobileHeader handleToggle={handleDrawerToggle} />
        <MobileMenu open={mobileOpen} handleToggle={handleDrawerToggle} />
      </Box>
    </>
  );
};

export default Header;
