import { useMutation } from "@apollo/client";
import { Alert, Button, Collapse, Typography } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import { CREATE_STRIPE_SUBSCRIPTION } from "graphql/createStripeSubscription";
import React, { useState } from "react";

const CheckoutWithCustomer = (props) => {
  const stripe = useStripe();
  const [error, setError] = useState(null);
  const [open, setOpen] = React.useState(true);
  const [subscribed, setSubscribed] = useState("start");
  const { t, data, siteid, customerid, childsData, handleCustomerActionRequired } = props;
  const { id: priceid, checkedChilds: quantity } = data;

  const confirmClick = async (createSubscription, stripeSubscriptionInput) => {
    setOpen(true);
    setError(null);
    // complete subscription
    const vars = { variables: { input: stripeSubscriptionInput } };
    try {
      let {
        data: { createStripeSubscription: subscription },
      } = await createSubscription(vars);
      const {
        latest_invoice: { payment_intent: paymentIntent },
      } = subscription;
      if (subscription.status === "incomplete") {
        if (paymentIntent.status === "requires_payment_method") {
          throw new Error("Requires payment method. Setup your card through Stripe With Manage Billing");
        } else if (paymentIntent.status === "requires_action") {
          const { message } = await handleCustomerActionRequired({
            paymentMethodId: subscription.default_payment_method,
            paymentIntent,
            stripe,
          });
          if (message === "success") {
            setSubscribed("active");
          }
        }
      } else if (subscription.status === "active") {
        setSubscribed("active");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  let stripeSubscription = {
    siteid,
    priceid,
    quantity,
    customerid: customerid,
    childs: childsData,
  };
  const [createStripeSubscription, stripeSubscriptionMutation] = useMutation(CREATE_STRIPE_SUBSCRIPTION);
  
  if (subscribed === "active")
    return (
      <Typography variant="h6" color="textSecondary">
        <Alert severity="success">{t("Successfully created subscription")}</Alert>
      </Typography>
    );

  return (
    <>
      {error && (
        <Collapse in={open}>
          <div style={{ margin: 16 }}>
            <Alert
              onClose={() => {
                setOpen(false);
              }}
              severity="error"
            >
              {error}
            </Alert>
          </div>
        </Collapse>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => confirmClick(createStripeSubscription, stripeSubscription)}
      >
        Confirm order
      </Button>
    </>
  );
};

export default CheckoutWithCustomer;
