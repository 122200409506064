import { Loader } from "@googlemaps/js-api-loader";
import GoogleMap from "google-maps-react-markers";
import React, { useCallback, useEffect, useRef, useState } from "react";

import InfoWindow from "./InfoWindow";
import SiteMarker from "./SiteMarker";
// import SiteHelper from '../utils/SiteHelper';
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import Fab from "@mui/material/Fab";
// import Button from '@mui/material/Button'
import SearchIcon from "@mui/icons-material/Search";
import Grow from "@mui/material/Grow";
import { useTranslation } from "react-i18next";
import { geocodeByCoordinates } from "../utils/Geocode";

const TOOLBAR_HEIGHT = 64;

// also usefull:
// https://codesandbox.io/s/react-google-maps-api-ir5ks?from-embed

const SitesMap = (props) => {
  const { i18n, t } = useTranslation(["maps"]);


  const styles = {
    restoreCenterButton: {
      position: "absolute",
      top: "10vh",
      left: "7vw",
    },
    searchHereButton: {
      position: "absolute",
      top: "10vh",
      fontSize: 12,
    },
  };

  const {
    places,
    center,
    handleSubmit,
    filters,
    setFilters,
    mustUpdateMap,
    setCurrentMapCenter,
    setMustUpdateMap,
    place,
    setPlace,
  } = props; // place used to force show a site
  const defaultProps =
    i18n.language === "en"
      ? {
          center: {
            lat: 37.971676,
            lng: 23.725738,
          },
          zoom: 11,
        }
      : {
          center: {
            lat: 37.980682040052066,
            lng: 23.720542329151066,
          },
          zoom: 11,
        };

  // FOR FUTURE USE: create an array of refs, one for each marker, it will be used to change style etc when clicked
  // https://stackoverflow.com/questions/54633690/how-can-i-use-multiple-refs-for-an-array-of-elements-with-hooks

  const markerRef = useRef(places ? places.map(() => React.createRef()) : null);

  // const [loading, setLoading] = useState(true)
  // const [showCommonInfoWindow, setShowCommonInfoWindow] = useState(false);
  // const [googleMap, setGoogleMap] = useState();
  const mapRef = useRef(null);
  // const [mapReady, setMapReady] = useState(false)

  const [googleMaps, setGoogleMaps] = useState();
  const [mapStatus, setMapStatus] = useState();
  const [geocoder, setGeocoder] = useState();
  const [circle, setCircle] = useState();
  const [showRestoreCenterButton, setShowRestoreCenterButton] = useState(false);
  const [showSearchHereButton, setShowSearchHereButton] = useState(false);
  const [searchHereHereByCircle, setSearchHereByCircle] = useState(false);
  // const [clickCoordinates, setClickCoordinates] = useState()
  const [mustSubmit, setMustSubmit] = useState(false);

  // click on info window close button
  const handleClickCloseInfoWindow = (ev) => {
    // setShowCommonInfoWindow(false);
    setPlace(null);
  };

  // restores current map center to the initial center (the coords of the place selected in places autocomplete)
  const handleRestoreCenterClick = (ev) => {
    if (mapRef) {
      mapRef.current.setCenter(center);
      // mapRef.current.setCenter(defaultProps.center);
    }
    setShowRestoreCenterButton(false);
    setShowSearchHereButton(false);
    // setShowCommonInfoWindow(false);
    setPlace(null);
  };

  // search based on current map center or on current circle  (e.g. after map dragged by user or circle dragged/edited by user)
  const handleSearchHereClick = (ev) => {
    setShowSearchHereButton(false);
    setShowRestoreCenterButton(false);
    // setShowCommonInfoWindow(false);
    setPlace(null);
    let latLng = null;
    let radius = null;
    if (searchHereHereByCircle && circle) {
      latLng = circle.getCenter();
      radius = Math.round(circle.getRadius() / 1000);
    } else {
      latLng = mapRef.current.getCenter();
    }
    const coordinates = { lat: latLng.lat(), lng: latLng.lng() };
    //setCurrentMapCenter(coordinates)
    geocodeByCoordinates(geocoder, coordinates).then((placeData) => {
      //  console.log('coordinates',coordinates)
      setFilters({
        ...filters,
        coordinates: coordinates,
        address: placeData.formatted_address,
        distance: radius ? radius : filters.distance, // if radius changed then set distance accordingly
      });
      setMustSubmit(true); // εδω ειναι το προβλημα θα πρεπει να εκτελειται αφου εχουν αλλαξει τιμες τα φιλτρα, ενω τωρα αλλαζουν στον επομενο κυκλο
      //  console.log('placeData', placeData);
    });
  };

  const onChangeCallback = ({ center, zoom, bounds, marginBounds }) => {
    // console.log('bounds change',center, zoom, bounds, marginBounds)
    setShowRestoreCenterButton(true)
  };

  const onMarkerClick = (e, { key }) => {
    const index = places.findIndex((place) => place.siteid === key);
    // use these for single info window, always in the same place (rendering different infowindow inside Marker component is another approach but infowindow cannot be placed absolutely because map is divided in tiles)
    setPlace(places[index]);
    // recenter map to marker position
    setCurrentMapCenter({ lat: places[index].mapCoordinates.x, lng: places[index].mapCoordinates.y });
  };

  // If we want to add a marker using the api directly:
  // The objects {map, maps} in the callback function gives you access to:
  // maps -> Animation, ControlPosition, MapTypeControlStyle, MapTypeId, ...
  // map -> qg {gm_bindings_: Object, _gm: rf, gm_accessors: Object, streetViewControl: true…
  const initMap = ({ map, maps }) => {
    mapRef.current = map;
    setGoogleMaps(maps);
    setGeocoder(new maps.Geocoder());
    // draw initial circle
    const newCircle = drawCircle(maps, map);
    setCircle(newCircle);
  };

  const drawCircle = useCallback(
    (maps, map) => {
      // console.log('draw', 'maps',maps, 'map', map, 'center',center, 'distance',filters.distance)
      // alert("clicked");
      const _circle = new maps.Circle({
        strokeColor: "#0000ff",
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: "#0000ff",
        fillOpacity: 0.1,
        map: map,
        center: center,
        radius: 1000 * filters.distance, // radius is measured in meters so we convert from kilometers
        draggable: true,
        editable: true,
      });
      // add events
      maps.event.addListener(_circle, "dragend", function (event) {
        // console.log('dragend',event)
        setSearchHereByCircle(true);
        setShowSearchHereButton(true);
      });
      maps.event.addListener(_circle, "center_changed", function (event) {
        // console.log('center_changed',event)
        setSearchHereByCircle(true);
        setShowSearchHereButton(true);
      });
      maps.event.addListener(_circle, "radius_changed", function (event) {
        // console.log('radius_changed',event)
        setSearchHereByCircle(true);
        setShowSearchHereButton(true);
      });
      return _circle;
    },
    [center, filters.distance]
  );

  // If we want additional options like street view, satellite etc :
  // https://levelup.gitconnected.com/reactjs-google-maps-with-custom-marker-ece0c7d184c4
  // const getMapOptions = (maps: any) => {
  //   return {
  //     disableDefaultUI: false,
  //     mapTypeControl: true,
  //     streetViewControl: true,
  //     styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
  //   };
  // };

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GMAPS_API_KEY,
      id: "googleMapsApi",
      // version: "weekly",
      libraries: ["places", "geocoding"],
    });
    // console.log('distance',filters.distance,mustUpdateMap)
    loader.importLibrary("places").then(async ({ places }) => {
      setMapStatus("ready");
    });
    if (mustUpdateMap) {
      // map must be updated because filters has changed and submitted
      if (mapRef.current) {
        mapRef.current.setCenter(center);
      }
      setMustUpdateMap(false);
      // setShowCommonInfoWindow(false);
      setPlace(null);
      if (googleMaps) {
        // remove existing circle and create a new one (maybe we can move the circle instead)

        circle.setMap(null);
        const newCircle = drawCircle(googleMaps, mapRef.current);
        setCircle(newCircle);
      }
    }
  }, [center, circle, drawCircle, googleMaps, mustUpdateMap, setMustUpdateMap, setPlace]);

  useEffect(() => {
    if (mustSubmit) {
      setMustSubmit(false);
      handleSubmit();
    }
  }, [mustSubmit, handleSubmit]);

  // if(loading)
  //   return 'loading'

  return (
    // https://stackoverflow.com/questions/41405343/adding-marker-to-google-maps-in-google-map-react
    /* This is the missing part in docs:
     *
     * Basically, you only need to add a Child Component that
     * takes 'lat' and 'lng' Props. And that Component should
     * returns a text, image, super-awesome-pin (aka, your marker).
     *
     */

    // Important! Always set the container height explicitly

    // NOW WE LOAD THE LIB IN INDEX.HTML TO AVOID "API LOADED MULTIPLE TIMES..." CAUSED BY LOADING THE LIB FROM MUI-PLACES-AUTOCOMPLETE AND GOOGLE-MAP-REACT
    // bootstrapURLKeys={{ key: 'AIzaSyCB_UrAX3ZkxiXvewfhGUlZz_Rc4kHsn9Y' }}
    // For each site a marker is created
    <div
      style={{
        height: props.dashboardMode ? `calc(100vh - 2 * ${TOOLBAR_HEIGHT}px)` : `calc(100vh - ${TOOLBAR_HEIGHT}px)`,
        width: "100%",
        display: "flex",
        position: "relative",
        justifyContent: "center",
      }}
    >
      <GoogleMap
        apiKey={process.env.REACT_APP_GMAPS_API_KEY}
        onGoogleApiLoaded={initMap}
        loadScriptExternally={true}
        status={mapStatus}
        libraries={["places", "geocoding"]}
        defaultCenter={props.center ? props.center : defaultProps.center}
        defaultZoom={props.zoom ? props.zoom : defaultProps.zoom}
        onChange={onChangeCallback}
      >
        {places &&
          places.map(
            (p, i) =>
              p.mapCoordinates && (
                <SiteMarker
                  ref={markerRef.current[i]}
                  key={p.siteid}
                  lat={p.mapCoordinates.x}
                  lng={p.mapCoordinates.y}
                  text={p.name}
                  place={p}
                  onClick={onMarkerClick}
                  open={place ? p.siteid === place.siteid : false}
                />
              )
          )}
      </GoogleMap>
      {!mustUpdateMap && place && <InfoWindow place={place} handleClickClose={handleClickCloseInfoWindow} />}
      <Grow in={showRestoreCenterButton}>
        <Fab
          sx={styles.restoreCenterButton}
          onClick={handleRestoreCenterClick}
          color="secondary"
          title="restore map center"
          aria-label="restore map center"
        >
          <GpsFixedIcon />
        </Fab>
      </Grow>
      <Grow in={showSearchHereButton}>
        <Fab
          variant="extended"
          sx={styles.searchHereButton}
          onClick={handleSearchHereClick}
          color="secondary"
          title={t("SearchCenterMap")}
          aria-label={t("SearchCenterMap")}
        >
          <SearchIcon />
          {searchHereHereByCircle ? t("Search inside circle") : t("Search around new center")}
        </Fab>
      </Grow>
    </div>
  );
};

export default SitesMap;
