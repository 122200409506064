import { Drawer } from "@mui/material";
import AppDrawerMenuList from "./AppDrawerMenuList";
import AppDrawerUser from "./AppDrawerUser";

const AppDrawer = (props) => {
  const { width, user, searchClose } = props;
  return (
    <Drawer
      variant="persistent"
      sx={ {
        // width: width,
        flexShrink: 0,
        display: { xs:"none", sm: "none", md:searchClose?"block":"none" },
        "& .MuiDrawer-paper": {
          // width: width,
          boxSizing: "border-box",
        },
      }}
      open
    >
      <AppDrawerUser {...props} />
      <AppDrawerMenuList user={user} />
    </Drawer>
  );
};

export default AppDrawer;
