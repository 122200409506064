import { useQuery } from "@apollo/client";
import { Alert, Card, CardContent, Typography } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { SITE_QUERY } from "graphql/getSite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckoutForm from "../overview/components/stripe/CheckoutForm";
import CheckoutWithCustomer from "./components/CheckoutWithCustomer";

const styles = {
  root: {
    borderRadius: 12,
    minWidth: 256,
    maxWidth: 600,

    textAlign: "center",
  },
};

const handlePaymentMethodRequired = async ({ subscription }) => {
  if (subscription.latest_invoice.payment_intent.status === "requires_payment_method") {
    // Using localStorage to store the state of the retry here
    // (feel free to replace with what you prefer)
    // Store the latest invoice ID and status
    sessionStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
    sessionStorage.setItem("latestInvoicePaymentIntentStatus", subscription.latest_invoice.payment_intent.status);
    throw new Error("Your card was declined.");
  } else {
    return subscription;
  }
};

const handleCustomerActionRequired = async ({ paymentMethodId, paymentIntent, stripe }) => {
  return stripe
    .confirmCardPayment(paymentIntent.client_secret, {
      payment_method: paymentMethodId,
    })
    .then((result) => {
      if (result.error) {
        // start code flow to handle updating the payment details
        // Display error message in your UI.
        // The card was declined (i.e. insufficient funds, card has expired, etc)
        throw result;
      } else {
        if (result.paymentIntent.status === "succeeded") {
          // There's a risk of the customer closing the window before callback
          // execution. To handle this case, set up a webhook endpoint and
          // listen to invoice.payment_succeeded. This webhook endpoint
          // returns an Invoice.
          return {
            message: "success",
          };
        }
      }
    });
};

/**
 * Performs a payment for the children that are checked for the subscription
 * It needs to know the children and the number of checked children
 * @param {*} props 
 * @returns 
 */
const CheckoutSubscription = (props) => {
  const { subscriptionData, siteid, customerid, childs } = props;
  const { t } = useTranslation();

  useEffect(() => {
    return function cleanup() {
      sessionStorage.removeItem("latestInvoiceId");
      sessionStorage.removeItem("latestInvoicePaymentIntentStatus");
    };
  });
  const [stripePromise, setStripePromise] = useState(null)

  const { loading, error } = useQuery(SITE_QUERY, {
    variables: { siteid },
    onCompleted(data) {
      setStripePromise(() => loadStripe(process.env.REACT_APP_STRIPE_KEY, { stripeAccount: data.site.connected_account }))
    },
  });
  if (!subscriptionData) return null;
  if (loading) return <div>{t("Fetching")}</div>;
  if (error) return <div>{error.message}</div>;
  const { id: priceid, checkedChilds: quantity } = subscriptionData;
  const childsData = childs.filter((child) => child.checked).map((child) => child.id);

  return (
    <Elements stripe={stripePromise}>
      <Card>
        <CardContent>
          <Typography variant="h5">
            Amount {subscriptionData.planAmount / 100} {subscriptionData.currency}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {subscriptionData.price.product.name}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Program: {subscriptionData.planName}
          </Typography>
          {error && (
            <div style={{ margin: 16 }}>
              <Alert severity="error">{error}</Alert>
            </div>
          )}
          {customerid === null ? (
            <CheckoutForm
              siteid={siteid}
              priceid={priceid}
              quantity={quantity}
              childs={childsData}
              handleCustomerActionRequired={handleCustomerActionRequired}
              handlePaymentMethodRequired={handlePaymentMethodRequired}
            />
          ) : (
            <CheckoutWithCustomer
              siteid={siteid}
              handleCustomerActionRequired={handleCustomerActionRequired}
              data={subscriptionData}
              customerid={customerid}
              childsData={childsData}
            />
          )}
        </CardContent>
      </Card>
    </Elements>
  );
};
export default CheckoutSubscription;
