import Search2 from './Search2';
// import Admin from './Admin';
import { Box } from '@mui/material';
import LatestNews from './LatestNews';
import LatestSites from './LatestSites';
import SocialButtons from './SocialButtons';


const Landing = (props) => {
  return (
    <Box sx={{
      flexGrow: 1
    }}>
     <SocialButtons />
      <Search2 />

      {/* <Hero2 links={props.links} /> */}
      {/*<Admin links={props.links}/>*/}
      <LatestSites />
      <LatestNews />
    </Box>
  );
}

export default Landing;
