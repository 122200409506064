import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { blue } from "@mui/material/colors";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { policies } from "../../utils/ProfileHelper";

// const emails = ['username@gmail.com', 'user02@gmail.com'];
const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

const PolicyDialog = (props) => {
  const handleClose = () => {
    props.onClose(props.selectedValue);
  };

  const handleListItemClick = (value) => {
    props.onClose(value);
  };

  const { open } = props;
  const classes = styles;
  const { t } = useTranslation()
  // policies.map(policy=><h1>{console.log(policy)}</h1>)
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{t("Set personal data policy")}</DialogTitle>
      <div>
        <List>
          {policies.map((policy) => (
            <ListItem button onClick={() => handleListItemClick(policy.id)} key={policy.id}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={t(policy.label)} />
            </ListItem>
          ))}
          {/*<ListItem button onClick={() => handleListItemClick('addAccount')}>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="add account" />
            </ListItem>*/}
        </List>
      </div>
    </Dialog>
  );
};

PolicyDialog.propTypes = {
  onClose: PropTypes.func,
  selectedValue: PropTypes.number,
  open: PropTypes.bool,
};

export default PolicyDialog;
