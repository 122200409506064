import React from 'react'
import { Avatar } from '@mui/material';
import { useQuery } from '@apollo/client';
import { SITE_QUERY } from 'graphql/getSite';

const SiteAvatar = props => {
  const { siteid } = props
  let imageUrl = ''
  const { loading, error, data } = useQuery(SITE_QUERY, {
    variables: { siteid }
  });
  if (loading) return <div>Fetching</div>
  if (error) return <div>Error</div>
  const { site } = data  
  if (site.domain_name) {
    imageUrl = `${process.env.REACT_APP_HTTP}://${siteid}.${site.domain_extension}${process.env.REACT_APP_SITE_PROFILE_PATH_WITH_DOMAIN}${siteid}/images/ui/site.logo`
  } else {
    imageUrl = `${process.env.REACT_APP_HTTP}://${siteid}${process.env.REACT_APP_SITE_PROFILE_PATH}${siteid}/images/ui/${site.logo}`
  }
return (
    <Avatar src={imageUrl}></Avatar>
  )
}

export default SiteAvatar