import { gql } from "@apollo/client"

export const APPLICATIONS_QUERY = gql`query UserRegistrations ($id: ID!) {
  userRegistrations(id: $id) {
    comments
    data
    id
    siteid
    status
    userid
    siteName
    created_at
  }
}`