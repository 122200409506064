import { gql } from "@apollo/client"
/**
 * get childs by parent userid
 */
export const CHILDS_QUERY = gql`query Childs ($id: ID!) {
  childs(id:$id) {
    id,
    firstname,
    lastname,
    birth_date,
     siteProfiles {
      id,
      userid,
      siteid,
      firstname,
      birth_date,
      lastname,
      photo,
      personal_data_policy,
      site {
        siteid,
        name
      }
    }
  }
}`