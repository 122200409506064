import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';

const API_URL = process.env.REACT_APP_PUBLIC_API_URL;

const StaticPage = props => {

  const [loading, setLoading]= useState(true)
  const [html, setHtml] = useState({})
  const [error, setError] = useState()
  const { name } = props;

  useEffect(() => {
    fetch(`${API_URL}/${name}`)
      .then(res => {
        return res.text();
      })
      .then(content => {
        // dangerouslySetInnerHTML requires using an object with an `__html` key
        setHtml({
          __html: content
        });
        // console.log('content',content)
        setLoading(false)
      })
      .catch(error=> {
        setError(error)
        setLoading(false)
      })
  },[name])

  
  if(loading)
    return <div>loading...</div>

  if(error)
    return <div>An error occured</div>
  
  return (
    <Container sx={{ mt:(theme) => theme.spacing(5) }} maxWidth="lg">
      <Grid container justifyContent="center">
        <div style={{ textAlign: 'justify', padding: 32}} dangerouslySetInnerHTML={html} />
      </Grid>
    </Container>
  );

}

export default StaticPage