import { useTheme } from "@emotion/react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import useAuth from "context/AuthContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import GoogleMapsAutocomplete from "./common/GoogleMapsAutocomplete";
import SportSelector from "./common/SportSelector";

// for form
// https://stackblitz.com/edit/react-7wuxzm?file=AddressForm.js

// other example
// https://github.com/fulin426/Amp-Search

// filters have the default values and click in find button redirects to search page, passes the filters and displays the results immediately
const GeoSearchFormLanding = (props) => {
  let navigate = useNavigate();
  const { filters, setFilters } = props;
  // const dataContext = useContext(AppDataContext);
  // const { sports, /* regions, sites, auth */  } = dataContext;
  const { loggedIn, getProfile } = useAuth();
  // const [currentSuggestion, setCurrentSuggestion] = useState(null);
  // const [geocoder, setGeocoder] = useState();
  // const [showPlaceClear, setShowPlaceClear] = useState(false);

  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const styles = {
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    searchHero: {
      paddingTop: 100,
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 180,
      width: "90%",
    },
    sportSelect: {
      textAlign: "left",
    },
    refineSearchIcon: {
      marginRight: theme.spacing(1),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
    },
    submitContainer: {
      paddingTop: theme.spacing(2),
    },
    currentLocationIcon: {
      backgroundColor: "#e4e4e4",
    },
    selectClearButton: {
      marginLeft: -96, // 48+48
    },
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (loggedIn()) {
      const profile = getProfile();
      navigate(`/home/search/${profile.id}`, { state: filters });
    } else {
      navigate(`/search`, { state: filters });
    }
  };

  
  const lang = i18n.language;
  return (
    <form
      sx={{
        display: "flex",
        flexWrap: "wrap",
      }}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Grid container spacing={{ xs: 6, md: 2 }}  alignItems="center" justifyContent="center">
        <Grid
          item
          xs={12}
          md={6}
        >
          <SportSelector sx={{
            // textAlign: "left",
            width: "90%",
            margin: (theme) => theme.spacing(1),
            minWidth: 180,
            [theme.breakpoints.down("md")]: {
              // display: "block",
              // marginTop: (theme) => theme.spacing(4),
            },      
    }} t={t} filters={filters} setFilters={setFilters} lang={lang} />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          // sx={{
          //   [theme.breakpoints.down("sm")]: {
          //     // marginTop: 8,
          //   },
          // }}
        >
          <FormControl sx={styles.formControl}>
            <GoogleMapsAutocomplete filters={filters} setFilters={setFilters} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sx={styles.submitContainer}>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            sx={styles.button}
            type="submit"
          >
            {t("Find")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default GeoSearchFormLanding;
