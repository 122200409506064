import { useTheme } from "@emotion/react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext } from "react";
import { AppDataContext } from "../../context/index";

const SportSelector = (props) => {

    const { filters, setFilters, lang, t, variant, sx } = props;
    const dataContext = useContext(AppDataContext);
    const theme = useTheme();
  const { sports, /* regions, sites, auth */  } = dataContext;

    function handleChange(event) {
        setFilters({
          ...filters,
          [event.target.name]: event.target.value,
        });
      }    
  return (
    <FormControl sx={sx} variant={variant} >
      <InputLabel htmlFor="sportid">{t("Sport or Activity")}</InputLabel>
      <Select
        sx ={{
          textAlign: "left",         
        }}
        value={filters.sportid}
        onChange={handleChange}
        label="Sport or Activity"
        inputProps={{
          name: "sportid",
          id: "sportid",
        }}
      >
        <MenuItem value="">{t("All sports")}</MenuItem>
        {sports &&
          sports.map((sport) => (
            <MenuItem value={sport.sportid} key={sport.sportid}>
              {sport.translation && sport.translation[lang]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SportSelector;
