import { Alert, Collapse } from "@mui/material"



const SuccessComponent = (props) => (
    <Collapse in={props.open}>
    <Alert severity="success" sx={{ width: "100%" }}>
         {props.message}
     </Alert>
     </Collapse>
  )
 
export default SuccessComponent