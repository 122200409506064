import DirectionsIcon from "@mui/icons-material/Directions";
import MapIcon from "@mui/icons-material/Map";
import PhotoIcon from "@mui/icons-material/Photo";
import SiteIcon from "@mui/icons-material/Public";
import { Link, ToggleButton, ToggleButtonGroup } from "@mui/material";
// import Button from '../../../components/Button';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/system";
import useAuth from "context/AuthContext";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useParams } from "react-router-dom";
import MapContainer from "../../../components/MapContainer";
import SiteHelper from "../../../utils/SiteHelper";
import AcademyDetails from "./AcademyDetails";
import AcademyNews from "./AcademyNews";
import AcademyProfileGallery from "./AcademyProfileGallery";

const API_URL = process.env.REACT_APP_PUBLIC_API_URL;
const API_URL_SITE = `${API_URL}/sites/`;
const DIRECTIONS_URL = "https://www.google.com/maps/dir/?api=1&destination=";

const AcademyProfile = () => {
  // const dataContext = useContext(AppDataContext);
  // const { fetchSite } = dataContext;
  // const {sites, getSiteById, getSiteAddress} = dataContext;
  // const {site} = props.location.state;
  // const {SiteHelper.getSiteUrl} = dataContext
  const { t } = useTranslation();

  // const { backgroundsx, children} = props;

  const { siteid } = useParams();
  // const { auth } = useContext(AppDataContext);
  const { loggedIn } = useAuth();
  const [site, setSite] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [topView, setTopView] = useState("photos"); // what to display in top view (photos or map)
  const [directionsUrl, setDirectionsUrl] = useState(DIRECTIONS_URL);

  const theme = useTheme();
  const styles = {
    typography: {
      flexGrow: 1,
      textAlign: "left",
    },
    container: {
      marginTop: theme.spacing(24),
      marginBottom: theme.spacing(14),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    mainContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: (theme) => theme.spacing(5),
    },
    latestNewsContainer: {
      margin: "0 auto",
      padding: "25px",
    },
    aside: {
      backgroundColor: "rgb(250, 250, 250)",
      padding: "0px",
      minHeight: "450px",
      borderTop: "1px solid rgb(224, 224, 224)",
    },
    academyTopView: {
      // based on https://www.quintoandar.com.br/imovel/892952716
      position: "relative",
      height: "24vw", // 22.22vw',
      [theme.breakpoints.down("lg")]: {
        height: "33.33vw",
      },
      [theme.breakpoints.down("sm")]: {
        height: "66.67vw",
      },
    },
    academyDetails: {
      textAlign: "left",
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
    },
    socialLink: {
      marginRight: theme.spacing(4),
    },
    socialIcon: {
      fontSize: "48px",
    },
    socialBox: {
      background: "#fff",
      padding: "24px",
      borderRadius: "5px",
      border: "1px solid #e0e0e0",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,.08)",
    },
    signUpContainer: {
      padding: 4,
      [theme.breakpoints.down("md")]: {
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "row",
        width: "100%",
        zIndex: 10,
        background: "rgba(0, 0, 0, 0) none repeat scroll 0% 0%",
        boxSizing: "border-box",
        boxShadow: 0,
        padding: 10,
      },
    },
  };

  const handleChangeView = (event, newView) => {
    setTopView(newView);
  };

  const fetchSite = () => {
    setLoading(true);
    fetch(API_URL_SITE + siteid)
      .then((res) => res.json())
      .then((res) => {
        // set state in here

        if (res) {
          setSite(res);
          if (res.mapCoordinates)
            setDirectionsUrl(
              "https://www.google.com/maps/dir/?api=1&destination=" + res.mapCoordinates.x + "," + res.mapCoordinates.y
            );
          else setDirectionsUrl(DIRECTIONS_URL); // default option, blank fields in directions page
        } else setError("Requested sport club not found");

        // console.log('site data', res);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    // LOAD FROM CONTEXT (REQUIRES ALL SITES TO BE ALREADY LOADED)
    // setLoading(true);
    // const res = getSiteById(siteid);
    // if(res)
    //   setSite(res);
    // else
    //   setError('Requested academy not found');
    // setLoading(false);

    fetchSite(siteid);
  }, [siteid]); // hooked on id, if id changes then useEffect executes again

  if (loading)
    return (
      <>
        <Grid container direction="row" alignItems="center" justifyContent="center" style={{ minHeight: "50vh" }}>
          <Grid item xs={12}>
            <Typography color="inherit" align="center" variant="h6">
              <p>Loading data...</p>
            </Typography>
            <div>
              <CircularProgress sx={styles.progress} color="secondary" />
            </div>
          </Grid>
        </Grid>
      </>
    );

  if (error)
    return (
      <Typography vaiant="h4" color="error">
        <Grid container direction="row" alignItems="center" justifyContent="center" style={{ height: "100vh" }}>
          <Grid item xs={12}>
            <Typography color="inherit" align="center" variant="h6">
              {error}
            </Typography>
          </Grid>
        </Grid>
      </Typography>
    );

  return (
    <>
      {/* top, different for photos/map */}
      <Box id="academy-top-view" sx={styles.academyTopView}>
        {topView === "photos" && <AcademyProfileGallery site={site} />}
        {topView === "map" && (
            <MapContainer
              site={site}
              center={{
                lat: site.mapCoordinates.x,
                lng: site.mapCoordinates.y,
              }}
            />
        )}
      </Box>

      {/* main content */}
      <Box component="section" style={styles.mainContainer}>
        <Grid container spacing={4} direction="row" justifyContent="center" maxWidth="xl">
          <Grid item xs={12} md={8}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    flexFlow: "row wrap",
                    padding: (theme) => theme.spacing(2),
                    gap: (theme) => theme.spacing(2),
                  }}
                >
                  <ToggleButtonGroup value={topView} exclusive onChange={handleChangeView} aria-label="">
                    <ToggleButton value="photos" aria-label="view photos">
                      <PhotoIcon />
                      {t("Photos")}
                    </ToggleButton>
                    <ToggleButton value="map" aria-label="view map">
                      <MapIcon />
                      {t("Map")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Button
                    href={SiteHelper.getSiteUrl(site)}
                    target="_blank"
                    variant={"outlined"}
                    color="secondary"
                  >
                    <SiteIcon sx={styles.buttonIcon} />
                    {t("Visit site")}
                  </Button>
                  <Button target="_blank" href={directionsUrl} variant={"outlined"} color="secondary">
                    <DirectionsIcon sx={styles.buttonIcon} />
                    {t("Get directions")}
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <AcademyDetails site={site} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            {!loggedIn() ? (
              <Grid item xs={12} sx={styles.signUpContainer}>
                <Button
                  size="large"
                  variant="contained"
                  fullWidth
                  color="secondary"
                  component={RouterLink}
                  to={`/signup/${site.siteid}`}
                  startIcon={<PersonAddIcon sx={styles.buttonIcon} />}
                >
                  {t("Sign up")}
                </Button>
              </Grid>
            ) : (
              ""
            )}

            <Grid sx={styles.academyDetails} item xs={12}>
              <Box sx={styles.socialBox}>
                <Typography
                  sx={styles.typography}
                  component="h6"
                  variant="h6"
                  gutterBottom
                  style={{ textTransform: "initial", marginTop: "20px" }}
                >
                  {t("Social Links")}
                </Typography>

                <div style={{ paddingTop: (theme) => theme.spacing(10) }}>
                  <Link href={site.facebook} target="_blank" sx={styles.socialLink}>
                    <FacebookIcon sx={styles.socialIcon} color={site.facebook ? "secondary" : "disabled"} />
                  </Link>
                  <Link href={site.instagram} target="_blank" sx={styles.socialLink}>
                    <InstagramIcon sx={styles.socialIcon} color={site.instagram ? "secondary" : "disabled"} />
                  </Link>
                  <Link href={site.youtube} target="_blank" sx={styles.socialLink}>
                    <YouTubeIcon sx={styles.socialIcon} color={site.youtube ? "secondary" : "disabled"} />
                  </Link>
                  <Link href={site.twitter} target="_blank" sx={styles.socialLink}>
                    <TwitterIcon sx={styles.socialIcon} color={site.twitter ? "secondary" : "disabled"} />
                  </Link>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <div style={{ marginTop: 20 }}>
        <aside id="latest-news" style={styles.aside}>
          <section style={styles.latestNewsContainer}>
            <AcademyNews site={site} />
          </section>
        </aside>
      </div>
    </>
  );
};

export default AcademyProfile;
