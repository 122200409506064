import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import FeatureItem from "./FeatureItem";

// import DashboardIcon from '@mui/material/Dashboard';
// import SiteIcon from '@mui/material/Public';
// import MemberDashboardIcon from '@mui/material/AccountBox';

// import TeamIcon from '@mui/material/People';
import MemberIcon from "@mui/icons-material/SupervisorAccount";
// import CoachIcon from '@mui/material/AssignmentInd';
// import MessageIcon from '@mui/material/Message';
// import SeasonIcon from '@mui/material/DynamicFeed';
// import AttendanceIcon from '@mui/material/HowToReg';
// import NotificationIcon from '@mui/material/Announcement';
import SubscriptionIcon from "@mui/icons-material/GroupAdd";
// import SportIcon from '@mui/material/Grain';
// import TrainingIcon from '@mui/material/PermContactCalendar';
// import ChartIcon from '@mui/material/InsertChart';
import ProfileIcon from "@mui/icons-material/AccountCircle";
import PersonalDataIcon from "@mui/icons-material/Block";
import ChildIcon from "@mui/icons-material/SupervisedUserCircle";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function MemberFeatures(props) {
  const { t } = useTranslation(["translation", "login"]);
  const styles = {
    features: {
      paddingTop: 80,
      paddingBottom: 40,
      // backgroundColor: theme.palette.secondary.light,
      // color: theme.palette.secondary.light
      // backgroundColor: "#a4ffcf", //#73f9b2"
    },
    divider: {
      margin: 30,
    },
    sectionTitle: {
      fontSize: 32,
      textTransform: "capitalize",
    },
  };
  return (
    <>
      <section id="member-backend" sx={styles.features}>
        <Container maxWidth="lg">
          {/*<Divider className={classes.divider} />*/}
          {/* <Typography variant="h4" component="h3" gutterBottom marked="center" className={classes.sectionTitle} >
          Member dashboard
        </Typography>  */}
          <Typography variant="h2" sx={{ paddingBottom: 5 }}>
            {t("Member Dashboard", { ns: "login" })}
          </Typography>
          <Grid container direction="row" alignItems="flex-start" justifyContent="center" style={{ minHeight: "20vh" }}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FeatureItem
                icon={MemberIcon}
                title={t("Multiple memberships")}
                text={t("multiple_memberships_explain")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FeatureItem icon={SubscriptionIcon} title={t("Subscriptions")} text={t("subscriptions_explain")} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FeatureItem icon={ChildIcon} title={t("Children")} text={t("children_explain")} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FeatureItem icon={ProfileIcon} title={t("Global profile")} text={t("global_profile_explain")} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FeatureItem icon={PersonalDataIcon} title={t("Personal data")} text={t("personal_data_explain")} />
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
}

export default MemberFeatures;
