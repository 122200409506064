import { amber, deepOrange, green, grey, lightGreen, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const rawTheme = createTheme({
  palette: {
    primary: {
      light: green[200],
      main: green[400], 
      dark: green[500],
    },
    // redish
    secondary: {
      light: amber[300],
      main: amber[500], 
      dark: amber[700],
    },
    warning: {
      light: deepOrange[200],
      main: deepOrange[400], 
      dark: deepOrange[500],
    },
    error: {
      light: red[200],
      main: red[400], 
      dark: red[500],
    },
    success: {
      light: lightGreen[300],
      main: lightGreen[500], 
      dark: lightGreen[700],
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
  textTransform: 'initial',
};

let theme = {
  ...rawTheme,
  palette: {
    // mode: 'dark',
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      "&::placeholder": grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: "3rem",
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: "2rem",
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: "1.75rem",
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: "1.5rem",
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: "1.35rem",
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      // fontSize: "1.25rem",
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      // fontSize: "1rem",
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      // fontSize: "0.9rem",
    },
    body2: {
      ...rawTheme.typography.body1,
      // fontSize: "0.8rem",
    },
  },
};
// theme = responsiveFontSizes(theme);
export default theme;