// https://dev.to/jessicabetts/how-to-use-google-maps-api-and-react-js-26c2
// for more details and markers etc... https://scotch.io/tutorials/react-apps-with-the-google-maps-api-and-google-maps-react

import { Loader } from "@googlemaps/js-api-loader";
import GoogleMap from "google-maps-react-markers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Marker from "./Marker";
// import InfoWindow from './InfoWindow';

// const apiKey = 'AIzaSyCB_UrAX3ZkxiXvewfhGUlZz_Rc4kHsn9Y'; // from akadim/common/config/params
// const defaultLocation = {latitude: '37.971634', longitude: '23.725755'}; // athens, acropol
// const defaultZoom = 16;

const mapStyles = {
  width: "100%",
  height: "100%",
  display: "flex",
};

const MapContainer = (props) => {
  const { i18n } = useTranslation();
  const { center, zoom, site } = props;
  const [mapStatus, setMapStatus] = useState();

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GMAPS_API_KEY,
      id: "googleMapsApi",
      // version: "weekly",
      libraries: ["places", "geocoding"],
    });
    // console.log('distance',filters.distance,mustUpdateMap)
    loader.importLibrary("places").then(async ({ places }) => {
      setMapStatus("ready");
    });
  },[]);

  const defaultProps =
    i18n.language === "en"
      ? {
          center: {
            lat: 37.971676,
            lng: 23.725738,
          },
          zoom: 16,
        }
      : {
          center: {
            lat: 37.980682040052066,
            lng: 23.720542329151066,
          },
          zoom: 16,
        };

  return (
    <>
      <GoogleMap
        apiKey={process.env.REACT_APP_GMAPS_API_KEY}
        libraries={["places", "geocoding"]}
        loadScriptExternally={true}
        defaultCenter={center ? center : defaultProps.center}
        defaultZoom={zoom ? zoom : defaultProps.zoom}
        status={mapStatus}
        style={mapStyles}
      >
        <Marker lat={center.lat} lng={center.lng} text={site.name ? site.name : site.siteid} place={site} />
      </GoogleMap>
    </>
  );
};

export default MapContainer;
