import { useTheme } from "@emotion/react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import PropTypes from "prop-types";
import Paper from "./Paper";

const AppForm = (props) => {
  const { children, maxWidth } = props;
  const theme = useTheme()

  return (
    <Container maxWidth={maxWidth ? maxWidth : "md"}>
      <Box sx={{
        display: "flex",
      }} mt={4} mb={8}>
        <Paper
          sx={{
            padding:(theme) => theme.spacing(2, 2),
            borderRadius: 16,
            [theme.breakpoints.up("md")]: {
              padding: theme.spacing(2, 2),
            },
          }}
        >
          {children}
        </Paper>
      </Box>
    </Container>
  );
};

AppForm.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppForm;
