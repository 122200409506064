import { useMutation } from "@apollo/client";
import { Cake, Info, LocalHospital } from "@mui/icons-material";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";
import {
  Avatar,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import ErrorComponent from "components/error-handling/ErrorComponent";
import { SITE_PROFILE_UPDATE_POLICY } from "graphql/SiteProfileUpdatePersonalDataPolicy";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import "react-sweet-progress/lib/style.css";
import PolicyDialog from "../../../../components/common/PolicyDialog";
import ProfileHelper from "../../../../utils/ProfileHelper";

const ChildSiteItem = (props) => {
  const { profile } = props;
  const { t, i18n } = useTranslation();
  const styles = {
    flexContainer: {
      display: "flex",
      maxWidth: 900,
      padding: 0,
    },
    card: {
      // maxWidth: 400,
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    actions: {
      display: "flex",
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: (theme) =>
        theme.transitions.create("transform", {
          duration: (theme) => theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red[500],
    },
    bigAvatar: {
      width: "64px",
      height: "64px",
    },
    profileCaption: {
      color: (theme) => theme.palette.primary.main,
      textTransform: "uppercase",
    },
  };
  const [isError, setIsError] = useState(false);
  const uniqueNames = new Set();
  const uniqueSeasons = profile.seasons.filter((season) => {
    const isDuplicate = uniqueNames.has(season.name);
    uniqueNames.add(season.name);
    if (!isDuplicate) {
      return true;
    }
    return false;
  });

  const [policy, setPolicy] = useState({
    open: false,
    selectedPolicy: profile.personal_data_policy, //policies[1],
  });

  const handleClickOpen = () => {
    setPolicy((policy) => ({
      ...policy,
      ...{ open: true },
    }));
  };

  const policyLabel = t(ProfileHelper.getPolicyLabel(policy.selectedPolicy));

  const [updateSiteProfilePersonalPolicy, { data, loading, error }] = useMutation(SITE_PROFILE_UPDATE_POLICY, {
    onError() {
      setIsError(true);
      setTimeout(() => setIsError(false), 8000);
    },
  });
  if (loading) return <div>{t("Fetching")}</div>;

  return (
    <>
      {error && <ErrorComponent open={isError} error={error} />}
      <List sx={styles.flexContainer}>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={ProfileHelper.getSiteLogo(profile)}></Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={styles.profileCaption}>
                  {`${t("Child profile in")} ${profile.site.name}`}
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText
            primary={uniqueSeasons.map((season) => (
              <Chip label={season.name} color="primary" />
            ))}
            secondary={t("Seasons")}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SportsBaseballIcon />
          </ListItemIcon>
          <ListItemText
            primary={profile.sports.map((sport) => (
              <Chip label={sport.translation[i18n.language]} color="primary" />
            ))}
            secondary={t("Sports")}
          />
        </ListItem>
      </List>
      <List sx={styles.card}>
        <ListItem>
          <ListItemAvatar>
            <Avatar sx={styles.bigAvatar} src={ProfileHelper.getProfilePhoto(profile)}></Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${profile.lastname} ${profile.firstname}`}
            secondary={t("Personal data") + `: ${policyLabel}`}
          />
          <ListItemIcon>
            <Cake />
          </ListItemIcon>
          <ListItemText
            primary={t("Birth Day")}
            secondary={<Moment format="l" date={profile.birth_date} />}
          ></ListItemText>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText primary={t("General Information")} secondary={profile.general_information}></ListItemText>
          <ListItemIcon>
            <LocalHospital />
          </ListItemIcon>
          <ListItemText primary={t("Medical information")} secondary={profile.medical_data}></ListItemText>
          <ListItemSecondaryAction>
            <IconButton aria-label="Settings" onClick={handleClickOpen} size="large">
              <SettingsIcon />
            </IconButton>
            <PolicyDialog
              selectedValue={policy.selectedPolicy}
              open={policy.open}
              onClose={(value) => {
                // update db only if there is a value selected and different from previous value
                if (value !== undefined && value !== policy.selectedPolicy) {
                  setPolicy({ selectedPolicy: value, open: false });
                  updateSiteProfilePersonalPolicy({
                    variables: { id: profile.id, policy: value },
                  });
                } else {
                  setPolicy((policy) => ({ ...policy, ...{ open: false } }));
                }
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
};

export default ChildSiteItem;
