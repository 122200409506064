import { Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
// import { AppDataContext } from '../../../context/index';
import { useTranslation } from "react-i18next";
import SiteHelper from "../../../utils/SiteHelper";

const API_URL = process.env.REACT_APP_PUBLIC_API_URL;

function AcademyNews(props) {
  const styles = {
    title: {
      textAlign: "center",
      fontWeight: "bold",
      marginBottom: (theme) => theme.spacing(4),
    },
    card: {
      margin: "0 8px",
    },
    link: {
      textDecoration: "none",
      "&:focus, &:hover, &:visited, &:link, &:active": {
        textDecoration: "none",
      },
    },
    cardLink: {
      textDecoration: "none",
      "&:focus, &:hover, &:visited, &:link, &:active": {
        textDecoration: "none",
      },
    },
    postTitle: {
      fontSize: 16,
      fontWeight: 100,
    },
  };
  let siteUrl = SiteHelper.getSiteUrl(props.site);

  const { siteid } = props.site;
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const postsNumber = 4;

  useEffect(() => {
    fetch(`${API_URL}/posts/${siteid}/${postsNumber}`)
      .then((posts) => posts.json())
      .then((posts) => {
        setPosts(posts);
        setLoading(false);
      });
  }, [siteid]);

  // console.log(posts);

  if (loading)
    return (
      <>
        <Grid container direction="row" alignItems="center" justifyContent="center" style={{ minHeight: "50vh" }}>
          <Grid item xs={12}>
            <Typography color="inherit" align="center" variant="h6">
              <p>Loading data...</p>
            </Typography>
            <div>
              <CircularProgress sx={styles.progress} color="secondary" />
            </div>
          </Grid>
        </Grid>
      </>
    );

  if (posts.length < 1)
    return (
      <Typography variant="h5" sx={styles.typography}>
        No news found
      </Typography>
    );

  return (
    <React.Fragment>
      <Typography variant="h5" sx={styles.title} gutterBottom>
        {t("Latest News")}
      </Typography>
      {/* show all at once - no pagination */}
      <Grid container spacing={4} justifyContent="center" direction="row">
        {posts.map((post) => (
          <Grid item xs={12} sm={6} md={3} lg={3} key={post.id}>
            <Card sx={styles.card} elevation={0}>
              {/* 
                using Link as component see 3rd answer: 
                https://stackoverflow.com/questions/37669391/how-to-get-rid-of-underline-for-link-component-of-react-router 
              */}
              <CardActionArea
                sx={styles.cardLink}
                component={Link}
                href={`${siteUrl}/site/view-post?id=${post.id}`}
                target="_blank"
              >
                <CardMedia
                  component="img"
                  alt={post.title}
                  height="200"
                  image={SiteHelper.getPostImage(post)}
                  title={post.title}
                />
                <CardContent>
                  <Typography sx={styles.postTitle} gutterBottom variant="h6" component="h3">
                    {post.title}
                  </Typography>
                  <Typography gutterBottom component="p" color="textSecondary">
                    {post.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        direction="row"
        style={{ paddingTop: 30 }}
      >
        <Link sx={styles.link} href={`${siteUrl}/site/show-news`} target="_blank">
          <Button color="secondary">{t("View all news")}</Button>
        </Link>
      </Grid>
    </React.Fragment>
  );
}

export default AcademyNews;
