import React from "react";
import SiteLogo from "./SiteLogo";

//  based on: https://gist.github.com/jobsamuel/56496033bfb4d0f3e316aeb88341ed16
// Also here we use a ref passed from parent to allow accessing each marker's dom element
const SiteMarker = React.forwardRef((props, ref) => {
  const { place, text, open, onClick } = props;

  return (
    <>
      <div onClick={(e) => (onClick ? onClick(e, { key:place.siteid }) : null)} id={`site-marker-${place.siteid}`} className={`site-marker ${open ? "open" : "close"}`} ref={ref}>
        <div className="pin">
          <div className="pin-image">
            <SiteLogo site={place} size={38} border={"none"} />{" "}
          </div>
        </div>
        <div className="pulse"></div>
        <div className="marker-text" style={{ marginLeft: -(text.length * 4) }}>
          {text}
        </div>
        {/* {props.show && <InfoWindow place={place} />} */}
      </div>
    </>
  );
});

export default SiteMarker;
