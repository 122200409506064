import SportsSailingIcon from "@mui/icons-material/DirectionsBoat";
import { default as SportsDefaultIcon, default as SportsRunningIcon } from "@mui/icons-material/DirectionsRun";
import SportsGymnasticsIcon from "@mui/icons-material/EmojiPeople";
import SportsSwimmingIcon from "@mui/icons-material/Pool";
import SportsRowingIcon from "@mui/icons-material/Rowing";
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import SportsCricketIcon from "@mui/icons-material/SportsCricket";
import SportsFootballIcon from "@mui/icons-material/SportsFootball"; // american football
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import SportsHockeyIcon from "@mui/icons-material/SportsHockey";
import SportsBoxIcon from "@mui/icons-material/SportsMma";
import SportsRugbyIcon from "@mui/icons-material/SportsRugby";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import SportsVolleyballIcon from "@mui/icons-material/SportsVolleyball";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";

function getSportIcon(sportname) {
  switch (sportname.toLowerCase()) {
    case "football":
      return <SportsSoccerIcon />;
    case "basket":
      return <SportsBasketballIcon />;
    case "volley":
      return <SportsVolleyballIcon />;
    case "americanfootball":
      return <SportsFootballIcon />;
    case "hockey":
      return <SportsHockeyIcon />;
    case "swimming":
      return <SportsSwimmingIcon />;
    case "baseball":
      return <SportsBaseballIcon />;
    case "cricket":
      return <SportsCricketIcon />;
    case "handball":
      return <SportsHandballIcon />;
    case "rugby":
      return <SportsRugbyIcon />;
    case "rowing":
      return <SportsRowingIcon />;
    case "box":
      return <SportsBoxIcon />;
    case "running":
      return <SportsRunningIcon />;
    case "tennis":
      return <SportsTennisIcon />;
    case "sailing":
      return <SportsSailingIcon />;
    case "gymnastics":
      return <SportsGymnasticsIcon />;
    default:
      return <SportsDefaultIcon />;
  }
}

const SiteSports = (props) => {
  const { sports } = props;
  const { i18n } = useTranslation();

  return (
    <>
      {sports.map((sport) => (
        <Chip
          sx={{
            marginRight: (theme) => theme.spacing(0.5),
            marginBottom: (theme) => theme.spacing(0.5),
          }}
          label={sport.translation && sport.translation[i18n.language]}
          icon={getSportIcon(sport.name)}
          key={sport.sportid}
        />
      ))}
    </>
  );
};

export default SiteSports;
