import SaveIcon from "@mui/icons-material/Save";
import { Button, TextField } from "@mui/material";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const SiteUserForm = (props) => {
  const { siteUserMutation, initialState } = props;
  const { t } = useTranslation();
  const ajv = new Ajv({ allErrors: true });
  addFormats(ajv);

  const [eachEntry, setEachEntry] = useState(initialState);
  const { id, lastname, firstname, address, postcode, mobilephone } = eachEntry;
  const [error, setError] = useState([]);

  const handleInputChange = (e) => {
    const data = { [e.target.name]: e.target.value };
    const valid = ajv.validate(schema, data);
    if (!valid) {
      setError(ajv.errors);
    }
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const schema = {
    type: "object",
    required: ["firstname", "lastname", "mobilephone"],
    properties: {
      firstname: { type: "string", minLength: 1, maxLength: 255 },
      lastname: { type: "string", minLength: 1, maxLength: 255 },
      address: { type: "string", minLength: 1, maxLength: 500 },
      postcode: { type: "string", pattern: "^[0-9]{5}$" },
      mobilephone: { type: "string", minLength: 10, maxLength: 10 },
    },
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let siteUser = {
      id,
      lastname,
      firstname,
      address,
      postcode,
      mobilephone,
    };
    const valid = ajv.validate(schema, siteUser);
    if (!valid) {
      setError(ajv.errors);
      return;
    }
    const vars = { input: siteUser };
    // const vars = siteUserId === undefined ? { input: siteUser } : { id: siteUserId, input: siteUser };
    siteUserMutation({ variables: vars });
  };
  const [lastnameError] = error.filter((err) => err.instancePath === "/lastname");
  const [firstnameError] = error.filter((err) => err.instancePath === "/firstname");
  const [addressError] = error.filter((err) => err.instancePath === "/address");
  const [postcodeError] = error.filter((err) => err.instancePath === "/postcode");
  const [mobilephoneError] = error.filter((err) => err.instancePath === "/mobilephone");

  // const lastnameErrorMessage = lastnameError.message;
  return (
    <>
      <form>
        <TextField
          name="lastname"
          id="lastname"
          label={t("Lastname")}
          fullWidth
          error={error.filter((err) => err.instancePath === "/lastname").length !== 0}
          helperText={lastnameError !== undefined ? lastnameError.message : ""}
          defaultValue={lastname}
          onChange={handleInputChange}
          required
          margin="normal"
        />

        <TextField
          name="firstname"
          id="firstname"
          label={t("Firstname")}
          onChange={handleInputChange}
          error={error.filter((err) => err.instancePath === "/firstname").length !== 0}
          helperText={firstnameError !== undefined ? firstnameError.message : ""}
          fullWidth
          required
          margin="normal"
          defaultValue={firstname}
        />

        <TextField
          name="address"
          id="address"
          label={t("Address")}
          onChange={handleInputChange}
          error={error.filter((err) => err.instancePath === "/address").length !== 0}
          helperText={addressError !== undefined ? addressError.message : ""}
          fullWidth
          margin="normal"
          defaultValue={address}
        />
        <TextField
          name="postcode"
          id="postcode"
          label={t("Post code")}
          onChange={handleInputChange}
          error={error.filter((err) => err.instancePath === "/postcode").length !== 0}
          helperText={postcodeError !== undefined ? postcodeError.message : ""}
          fullWidth
          margin="normal"
          defaultValue={postcode}
        />
        <TextField
          name="mobilephone"
          id="mobilephone"
          label={t("Mobilephone")}
          onChange={handleInputChange}
          error={error.filter((err) => err.instancePath === "/mobilephone").length !== 0}
          helperText={mobilephoneError !== undefined ? mobilephoneError.message : ""}
          fullWidth
          required
          margin="normal"
          defaultValue={mobilephone}
        />
        <div>
          <Button
            sx={{
              marginTop: (theme) => theme.spacing(3),
            }}
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            onClick={handleFormSubmit}
          >
            <SaveIcon />
            {t("Save")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default SiteUserForm;
