import ContactMailIcon from "@mui/icons-material/ContactMail";
import SigninIcon from "@mui/icons-material/LockOpen";
import SignupIcon from "@mui/icons-material/PersonAdd";
import { AppBar, Button, IconButton, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import MuiLink from "@mui/material/Link";
import { Box, flexbox } from "@mui/system";
import useAuth from "context/AuthContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";
import logo from "../images/applogo.png";
import HeaderUserMenu from "./HeaderUserMenu";
import LanguageSwitcher from "./common/LanguageSwitcher";

const CommonHeader = (props) => {
  const theme = useTheme();

  const { children } = props;
  // const { auth } = useContext(AppDataContext);
  const { loggedIn } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn()); // TODO: do it in app context???
  const location = useLocation();
  const isWidthUp = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation();

  // const useIsWidthUp = (breakpoint) => {
  //   return useMediaQuery(theme.breakpoints.up(breakpoint));
  // }

  // revert to position fixed, to enable hide on scroll uncomment component HideOnScroll
  return (
    <>
      {/*<HideOnScroll {...props}>*/}
      <AppBar elevation={0}>
        <Toolbar sx={{
            display: flexbox,
            justifyContent: "space-between",
            flexDirection: "row"
          }}>
          <RouterLink to="/">
            <img src={logo} alt="" />
          </RouterLink>
          {children}
          <Box sx={{
            justifyContent: "space-around",
            gap: (theme) => theme.spacing(1),
            display: "flex"
          }}>
            {isWidthUp && location.pathname !== "/login" && location.pathname !== "/password-reset" && (
              <Button
                color="secondary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                }}
                component={MuiLink}
                href="/register"
              >
                {t("Register Sport Club")}
              </Button>
            )}
            {/* do not show sign in button in login screen, in other pages display sign in when not logged in (else a user menu will be displayed, see below)  */}
            {/* also not show text in singin button for mobile */}
            {location.pathname !== "/login" && location.pathname !== "/password-reset" && !isLoggedIn && (
              <>
                {isWidthUp && (
                  <Button
                    color="secondary"
                    // variant="outlined"
                    sx={{ color: "black" }}
                    component={RouterLink}
                    to="/login"
                  >
                    <SigninIcon />
                    {t("Sign in")}
                  </Button>
                )}
                {!isWidthUp && (
                  <IconButton
                    color="secondary"
                    // variant="outlined"
                    title="Sign In"
                    component={RouterLink}
                    to="/login"
                    size="large"
                  >
                    <SigninIcon />
                  </IconButton>
                )}
                {isWidthUp && (
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      color: "white",
                    }}
                    component={RouterLink}
                    to="/signup"
                  >
                    <SignupIcon/>{t("Sign up")}</Button>
                )}
                {!isWidthUp && (
                  <IconButton
                    color="secondary"
                    variant="outlined"
                    title="Sign up"
                    component={RouterLink}
                    to="/signup"
                    size="large"
                  >
                    <SignupIcon />
                  </IconButton>
                )}

                {isWidthUp && (
                  <>
                    <Button
                      color="secondary"
                      sx={{ 
                        color: "black", 
                      }}
                      component={RouterLink}
                      to="/contact"
                    >
                      <ContactMailIcon
                        sx={{
                          marginRight: theme.spacing(1),
                        }}
                      />
                      {t("Contact")}
                    </Button>
                  </>
                )}
                {!isWidthUp && (
                  <IconButton
                    color="secondary"
                    title="Contact"
                    component={RouterLink}
                    to="/contact"
                    size="large"
                  >
                    <ContactMailIcon />
                  </IconButton>
                )}
                <LanguageSwitcher />
              </>
            )}
            {isLoggedIn && <HeaderUserMenu setIsLoggedIn={setIsLoggedIn} />}
            </Box>
        </Toolbar>
      </AppBar>
      {/* When you render the app bar position fixed, the dimension of the element doesn't impact the rest of the page. This can cause some part of your content to be invisible, behind the app bar. Here are 3 possible solutions:
      You can render a second <Toolbar /> component: */}
      {/*</HideOnScroll>*/}
    </>
  );
};

export default CommonHeader;
