import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { PERSON_QUERY } from "graphql/getPerson";
import React from "react";
import { useTranslation } from "react-i18next";

const Welcome = (props) => {
  const { user } = props;
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(PERSON_QUERY, {
    variables: { id: user.id },
  });
  if (loading) return <div>{t("Fetching")}</div>;
  if (error) return <div>{error.message}</div>;
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        {`${t("Welcome to FindSportClub member dashboard")}, ${data.person.lastname} ${data.person.firstname}`}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
        {t("Here you can view your subscriptions, childs and profiles you have in FindSportClub powered sport clubs")}
      </Typography>
    </>
  );
};

export default Welcome;
