import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import withTransition from "../components/withTransition";

const styles = (theme) => ({
  // For custom image background
  // https://stackoverflow.com/questions/51014469/using-material-uis-apis-how-do-i-set-a-background-image-in-the-body
  "@global": {
    // for full screen background
    // body: {
    //   // url background
    //   // backgroundImage: "url('https://www.slingshotsponsorship.com/wp-content/uploads/2017/03/sams-blog.jpg')",
    //   // for local image:
    //   // DISABLED backgroundImage: "url('/images/login-bg.jpg')",
    //   backgroundRepeat: "no-repeat",
    //   backgroundPosition: "center center",
    //   backgroundSize: "cover",
    //   backgroundAttachment: "fixed",
    //   height: "100%"
    // },

    // for 2 bottom left & right images
    // body: {
    //   [theme.breakpoints.up('lg')]: {
    //     backgroundImage: 'url("/images/signin-left.png"), url("/images/signin-right.png")',
    //     backgroundRepeat: 'no-repeat, no-repeat',
    //     backgroundAttachment: 'fixed, fixed',
    //     backgroundSize: '178px, 178px',
    //     backgroundPosition: 'left bottom, right bottom'
    //   },
    // },
    html: {
      height: "100%",
    },
    // "#componentWithId": {
    //   height: "100%"
    // }
  },

  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
    padding: 15,
    textTransform: "initial",
  },
  loginLink: {
    fontSize: 18,
    color: theme.palette.secondary.main,
    margin: theme.spacing(0, 1),
    textDecoration: "none",
  },
});

const LoginLayout = () => {
  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};

// withStyles is the higher order component that maps the defined
// classnames to a property 'classes' on the passed in Component
// export default withStyles(styles)(Login);

// multiple hoc export
export default LoginLayout;
