import { useMutation } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import SuccessComponent from "components/common/SuccessComponent";
import ErrorComponent from "components/error-handling/ErrorComponent";
import useAuth from "context/AuthContext";
import { CHILD_CREATE } from "graphql/createChild";
import { CHILDS_QUERY } from "graphql/getChilds";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ChildForm from "./childForm";

const AddChild = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth()
  const initialInputState = { lastname: "", firstname: "", birth_date: "" };
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [createChild, { data, loading, error }] = useMutation(CHILD_CREATE, {
    refetchQueries: [
      {
        query: CHILDS_QUERY,
        variables: {id: user.id } 
      } // DocumentNode object parsed with gql
    ],
    onCompleted(data) {
      setIsSuccess(true);
      setTimeout(() => setIsSuccess(false), 5000);
    },
    onError() {
      setIsError(true);
      setTimeout(() => setIsError(false), 8000);
    },
  });
  if (loading) return <div>{t("Fetching")}</div>;

  return (
    <Stack spacing={2} alignItems="center">
      <Typography variant="h5" gutterBottom>
        {t("Add new child")}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" style={{ marginBottom: "20px" }} gutterBottom>
        {t("Here you can add childs in FindSportClub app")}
      </Typography>
      <SuccessComponent open={isSuccess} type="success" message={t("Child Added")} />
      {error && <ErrorComponent open={isError} error={error} />}
      <ChildForm childMutation={createChild} initialState={initialInputState} />
    </Stack>
  );
};

export default AddChild;
