import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";

import { Card } from "@mui/material";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import TutorialItem from "./TutorialItem";

import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { ACCENT_COLOR } from "consts/colors";
import RegistrationHeader from "../registration/RegistrationHeader";
import "../registration/styles.css"; // use styles from registration landing page

const Workflow = ({ styles, handleOpen }) => (
  <section id="workflow" style={{ marginBottom: 100 }}>
    <h1>Basic workflow</h1>
    <p sx={styles.subtitle}>
      {" "}
      This workflow covers the basic scenarios for managing the subscriptions of members. You can click to enlarge.
    </p>
    <Card sx={styles.root} raised={true}>
      <CardActionArea>
        <CardMedia
          onClick={handleOpen}
          sx={styles.media}
          component="img"
          src="/images/findsportclub_horizontal.svg"
          title="Basic Workflow"
        />
        {/* <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
        Basic Workflow  
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          This workflow covers the basic scenarios for managing the subscriptions of members
        </Typography>
      </CardContent> */}
      </CardActionArea>
    </Card>
  </section>
);

function Help(props) {
  const styles = {
    root: {
      // display: "flex",
      // alignItems: "flex-start",
      // flexGrow: 1,
    },
    logoLink: {
      marginRight: "auto",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    workflowImage: {
      height: "auto",
      width: "100%",
      maxHeight: 500,
    },
    modalPaper: {
      backgroundColor: (theme) => theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: (theme) => theme.shadows[5],
      padding: (theme) => theme.spacing(2, 4, 3),
    },
    help: {
      paddingTop: (theme) => theme.spacing(5),
      paddingBottom: (theme) => theme.spacing(5),
      backgroundColor: (theme) => theme.palette.secondary.light,
      //   color: 'white',
      // color: theme.palette.secondary.light
      // backgroundColor: "#a4ffcf", //#73f9b2"
    },
    helpTitle: {
      fontSize: 42,
      textTransform: "capitalize",
      //   color: 'white'
    },
    tutorialTitle: {
      fontSize: 38,
      paddingTop: (theme) => theme.spacing(5),
      textTransform: "capitalize",
    },
    subtitle: {
      fontSize: 20,
    },
  };
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const videos = [
    {
      src: "457196222",
      text: "Create subscription packets for members",
    },
    {
      src: "457196419",
      text: "Register a member to stripe",
    },
    {
      src: "457195462",
      text: "Add a child and his parent",
    },
    {
      src: "457195572",
      text: "Member registers and subscribes to a packet",
    },
    {
      src: "457195969",
      text: "Admin subscribes the member",
    },
    {
      src: "457387105",
      text: "Site Branding",
    },
  ];
  return (
    <>
      <RegistrationHeader />
      <div className="intro-row" style={{ backgroundColor: ACCENT_COLOR }}>
        <Container maxWidth="lg">
          <h1>How to setup your club</h1>
          <div className="subtitle">
            <span className="inner-text">
              The video tutorials below will guide you to setup your club using the backend dashboard
            </span>
          </div>

          <Grid
            container
            direction="row"
            // alignItems="flex-start"
            justifyContent="center"
            // sx={{ minHeight: "30vh" }}
            spacing={1}
          >
            {videos.map((video) => (
              <Grid item key={video.src} xs={12} sm={6} md={6}>
                <TutorialItem video={video} />
              </Grid>
            ))}
          </Grid>

          <Workflow styles={styles} handleOpen={handleOpen} />
        </Container>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        sx={styles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 500,
        // }}
      >
        <Fade in={open}>
          <div style={styles.modalPaper}>
            <h1 id="transition-modal-title">Basic workflow</h1>
            <img style={styles.workflowImage} src="/images/findsportclub_horizontal.svg" alt="" />
          </div>
        </Fade>
      </Modal>
    </>
  );
}
export default Help;
