import { useMutation, useQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import SuccessComponent from "components/common/SuccessComponent";
import ErrorComponent from "components/error-handling/ErrorComponent";
import useAuth from "context/AuthContext";
import { PERSON_UPDATE } from "graphql/PersonUpdate";
import { PERSON_QUERY } from "graphql/getPerson";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProfileForm from "./ProfileForm";

const UpdateProfile = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const personQuery = useQuery(PERSON_QUERY, {
    variables: { id: user.id },
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [updatePerson, personUpdateMutation] = useMutation(PERSON_UPDATE, {
    onCompleted(data) {
      setIsSuccess(true);
      setTimeout(() => setIsSuccess(false), 5000);
    },
    onError() {
      setIsError(true);
      setTimeout(() => setIsError(false), 8000);
    }
  });
  const loading = personQuery.loading || personUpdateMutation.loading;
  const error = personQuery.error || personUpdateMutation.error;

  if (loading) return <div>{t("Fetching")}</div>;

  const person = personQuery.data.person;
  const initialInputState = {
    lastname: person.lastname,
    firstname: person.firstname,
    address: person.address,
    mobilephone: person.mobilephone,
    email: person.email
  };

  return (
    <>
      <Stack spacing={2} alignItems="center">
        <Typography variant="h5" gutterBottom>
          {t("Update Profile")}
        </Typography>
        <SuccessComponent open={isSuccess} type="success" message={t("Profile updated")} />
         {error && (<ErrorComponent open={isError} error={error} />) }
        <ProfileForm personId={person.id} personMutation={updatePerson} initialState={initialInputState} />
      </Stack>
    </>
  );
};

export default UpdateProfile;
