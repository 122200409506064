import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { showSubscriptionsCalculations } from "../../../../utils/stripeFunctions";

const StripePriceItem = (props) => {
  const styles = {
    root: {
      borderRadius: 12,
      minWidth: 256,
      maxWidth: 500,

      textAlign: "center",
    },
    cardPricing: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      marginBottom: (theme) => theme.spacing(2),
    },
    header: {
      textAlign: "center",
      spacing: 10,
    },
    list: {
      padding: "20px",
    },
    button: {
      margin: (theme) => theme.spacing(1),
    },
    action: {
      display: "flex",
      justifyContent: "space-around",
    },
  };
  const { t } = useTranslation();
  const { price, buyClick } = props;

  return (
    <>
      <Grid item md>
        <Card sx={styles.root} width="400px">
          <CardHeader
            titleTypographyProps={{ color: "primary", variant: "h6" }}
            title={`${price.nickname}-${price.product.name}`}
            sx={styles.header}
          />
          <Divider variant="middle" />
          <CardContent sx={styles.cardPricing}>
            <div sx={styles.list}>
              {showSubscriptionsCalculations(price, t).map((item, index) => (
                <Typography variant="subtitle2" key={index} align="left">
                  {item}
                </Typography>
              ))}
            </div>
          </CardContent>
          <Divider variant="middle" />
          <CardActions sx={styles.action}>
            {/* <Button variant="contained" color="primary" onClick={handleChange} sx={styles.button}>
              Calculate
            </Button> */}
            <Button variant="contained" color="primary" onClick={() => buyClick({ price })} sx={styles.button}>
              Select
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </>
  );
};

export default StripePriceItem;
