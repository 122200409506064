import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Features from "../../../components/Features";
import Pricing from "../../../components/Pricing";
import Help from "../help/Help";

function PlatformFeatures(props) {
  // const classes = useStyles();
  return (
    <>
      <section style={{ marginTop: 100, marginBottom: 30 }}>
        <Container maxWidth="md">
          <Typography
            variant="h6"
            component="p"
            gutterBottom
            marked="center"
            // className={classes.sectionTitle}
            style={{ fontSize: 22, fontWeight: 100, textTransform: "initial" }}
          >
            FindSportClub is an integrated system combining the latest web technologies in order to facilitate the daily
            operation of modern sport clubs and to connect the people involved or who are interest to participate
            (members, candidate members, teams, coaches, parents, administrators, sponsors). FindSportClub is a
            multi-sport and multi-season solution.
          </Typography>
        </Container>
      </section>
      <Features />
      <Pricing />
      <Help />
    </>
  );
}

export default PlatformFeatures;
