import { Alert, Collapse } from "@mui/material";
import { useTranslation } from "react-i18next";

const ErrorComponent = (props) => {
  const { error } = props;
  const { t } = useTranslation();
  let errors = [];
  if (error.networkError) errors = [...error.networkError.result.errors];
  else if (error.graphQLErrors) errors = [...error.graphQLErrors];
  return (
    <Collapse in={props.open}>
      <Alert severity="error" sx={{ width: "100%" }}>
        {errors.map(({ message }) => t(message))}
      </Alert>
    </Collapse>
  );
};

export default ErrorComponent;
