const DOMAIN_NAME = process.env.REACT_APP_SITE_DOMAIN_NAME

export default class SiteHelper {


    static getSiteLogo = site => {
      if (site.domain_name === 2) {
        return `${process.env.REACT_APP_HTTP}://${site.siteid}.${site.domain_extension}${process.env.REACT_APP_SITE_PROFILE_PATH_WITH_DOMAIN}${site.siteid}/images/ui/${site.logo}`
      } else {
        return `${process.env.REACT_APP_HTTP}://${site.siteid}${process.env.REACT_APP_SITE_PROFILE_PATH}${site.siteid}/images/ui/${site.logo}`
      }
    }

    static getSiteDefaultImage = ({site, thumb=true}) => {
      
      // console.log(site);
      if (!site || !site.findsportclub_image) {
        return "/images/academy_cover.jpg";
      }
      const image = site.findsportclub_image;
      const imageFilename = image.substring(0, image.lastIndexOf("."));
      const extension = image.substring(image.lastIndexOf(".")+1,image.length);
      let imageFinal = `${imageFilename}.${extension}`;
      if (thumb) {
        imageFinal = `${imageFilename}_thumb.${extension}`;
      }
      if (site.domain_name===2) {
        return `${process.env.REACT_APP_HTTP}://${site.siteid}.${site.domain_extension}${process.env.REACT_APP_SITE_PROFILE_PATH_WITH_DOMAIN}${site.siteid}/images/findsportclub/${imageFinal}`
      } else {
        return `${process.env.REACT_APP_HTTP}://${site.siteid}${process.env.REACT_APP_SITE_PROFILE_PATH}${site.siteid}/images/findsportclub/${imageFinal}`
      }
    }

    static getSiteUrl = (site) => {
      if(!site)
        return '';

      let protocol = 'https';
      if(process.env.NODE_ENV === 'development') {
        protocol = 'http';
      }
      
      if(site.domain_extension!==null) 
        return `${protocol}://${site.siteid}.${site.domain_extension}`; // own domain e.g. othak.gr
      else
        return `${protocol}://${site.siteid}.${DOMAIN_NAME}`; // subdomain e.g. othak.findsportclub.com
    }

    static getPostImage = (post) => {
      if (post.photo==null) {
        return `${this.getSiteUrl(post.site)}/admin/uploads/${post.siteid}/articles/default_thumb.jpg`;
      } else {
        const imageFilename = post.photo.substring(0, post.photo.lastIndexOf("."));
        const extension = post.photo.substring(post.photo.lastIndexOf(".")+1, post.photo.length);
        return `${this.getSiteUrl(post.site)}/admin/uploads/${post.siteid}/articles/${imageFilename}_thumb.${extension}`;
      }
    }
}

    

//default export Utils