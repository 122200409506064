import { Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Box } from '@mui/system';
import { useEffect /* useContext */, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SiteHelper from '../../../utils/SiteHelper';

const API_URL = process.env.REACT_APP_PUBLIC_API_URL;

function LatestNews(props) {

  const styles = {
    title: {
      textAlign: 'left',
      fontWeight: 'bold',
      marginBottom:(theme) => theme.spacing(4),
    },
    card: {
      margin: '0 8px',
    },
    link: {
      textDecoration: 'none',
      '&:focus, &:hover, &:visited, &:link, &:active': {
        textDecoration: 'none',
      },
    },
    cardLink: {
      textDecoration: 'none',
      '&:focus, &:hover, &:visited, &:link, &:active': {
        textDecoration: 'none',
      },
    },
    landingSection: {
      padding: '50px 0',
      backgroundColor: '#f8f8f8', // theme.palette.secondary.light,
    },
    sectionTitle: {
      textTransform: 'initial',
      fontSize: 32,
    },    
  }

  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()
  // const postsNumber = 4;

  useEffect(() => {
    fetch(`${API_URL}/posts/`)
      .then((posts) => posts.json())
      .then((posts) => {
        setPosts(posts);
        setLoading(false);
      });
  }, []);

  // console.log(posts);

  if (loading)
    return <>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='center'
        sx={{ minHeight: '50vh' }}
      >
        <Grid item xs={12}>
          <Typography color='inherit' align='center' variant='h6'>
            <p>Loading data...</p>
          </Typography>
          <div>
            <CircularProgress
              sx={styles.progress}
              color='secondary'
            />
          </div>
        </Grid>
      </Grid>
    </>;

  if (posts.length < 1)
    return (
      <Typography variant='h5' sx={styles.typography}>
        No news found
      </Typography>
    );

  return <>
    <Box component="section" sx={styles.landingSection}>
      <Container maxWidth='lg'>
        <Typography
          sx={styles.sectionTitle}
          variant='h4'
          marked='center'
          component='h2'
        >
           {t("Latest News")}
        </Typography>

        {/* show all at once - no pagination */}
        <Grid
          container
          spacing={0}
          justifyContent='center'
          direction='row'
          style={{ paddingTop: 50 }}
        >
          {posts.map((post) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={post.id}>
              <Card sx={styles.card} elevation={0}>
                {/* 
                using Link as component see 3rd answer: 
                https://stackoverflow.com/questions/37669391/how-to-get-rid-of-underline-for-link-component-of-react-router 
              */}
                <CardActionArea
                  sx={styles.cardLink}
                  component={Link}
                  href={`${SiteHelper.getSiteUrl(
                    post.site
                  )}/site/view-post?id=${post.id}`}
                  target='_blank'
                >
                  <CardMedia
                    component='img'
                    alt={post.title}
                    height='200px'
                    image={SiteHelper.getPostImage(
                      // post.siteid,
                      // SiteHelper.getSiteUrl(post.site),
                      post
                    )}
                    title={post.title}
                  />
                  <CardContent>
                    <Typography
                      sx={styles.postName}
                      gutterBottom
                      variant='h5'
                      component='h2'
                    >
                      {post.title}
                    </Typography>
                    <Typography
                      gutterBottom
                      component='p'
                      color='textSecondary'
                    >
                      {post.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        {/* <Grid container spacing={40} justify="center" alignItems="center" direction="row">
        <Link sx={styles.link} href={`${getSiteAddress(post.siteid)}/site/show-news`} target="_blank">
        <Button color="secondary">
          View all news
        </Button>
        </Link>
      </Grid>*/}
      </Container>
    </Box>
  </>;
}

export default LatestNews;
