import { Divider, Drawer, Toolbar } from "@mui/material";
import AppDrawerMenuList from "./AppDrawerMenuList";
import AppDrawerUser from "./AppDrawerUser";

const AppDrawerOverlay = (props) => {
  const { open, width, user, theme, onClose, searchClose } = props;
  return (
    // overlay container, click or key press on it will close the drawer
    <div tabIndex={0} role="button" onClick={onClose} onKeyDown={onClose}>
      <Drawer
        variant="temporary"
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={open}
        sx={{
          display: { sm: "block", md: searchClose?"none":"block" },
          // display: { sm: "block"},
          "& .MuiDrawer-paper": { boxSizing: "border-box", width },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Toolbar />
        <Divider />
        <AppDrawerUser {...props} />
        <AppDrawerMenuList user={user} />
      </Drawer>
    </div>
  );
};

export default AppDrawerOverlay;
