import decode from "jwt-decode";
import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router";

// https://dev.to/finiam/predictable-react-authentication-with-the-context-api-g10
const AuthContext = createContext();
/**
 * We refer to token for validating that user is authenticated but keep the user profile in
 * a separate state
 *
 */
export const AuthProvider = ({ children }) => {
  // const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const domain = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  const login = async (email_phone, password, redirect = true) => {
    // Get a token from api server using the fetch api
    setLoading(true);
    try {
      const res = await prepareFetch(
        `${domain}/v1/public/user/authenticate`,
        {
          method: "POST",
          body: JSON.stringify({
            email_phone,
            password,
          }),
        },
        redirect
      );
      // antonis: in combination with redirect flag set token only if exists in response (else immediately after login() the loggedIn() returns TRUE!!! ).
      // This happens because when redirect=TRUE the _checkStatus changes the window.location so the below line is not executed. But when redirect=FALSE
      // the _checkStatus returns the response here so we must check if login was successfull  (accessToken exists)
      if (res.accessToken) {
        setTokenAndRefreshToken(res); // Setting the token in localStorage
      } else if (res.message) {
        throw new Error(res.message)
      }
      // setUser(getProfile(res.accessToken));
      navigate("/home", {replace: true});
      //   return res;
    } finally {
      setLoading(false);
      // return
    }
  };
  const refreshToken = async (userid, refreshToken) => {
    // Get a token from api server using the fetch api
    const res = await prepareFetch(`${domain}/v1/public/user/refreshAccessToken`, {
      method: "POST",
      body: JSON.stringify({
        userid,
        refreshToken,
      }),
    });
    setTokenAndRefreshToken(res); // Setting the token in localStorage
    return res;
  };

  const loggedIn = () => {
    // Checks if there is a saved token and it's still valid
    const token = getToken(); // GEtting token from localstorage
    return !!token && !isTokenExpired(token); // handwaiving
  };

  const isTokenExpired = (token) => {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  };

  const setTokenAndRefreshToken = (res) => {
    // Saves user token to localStorage
    sessionStorage.setItem("access_token", res.accessToken);
    sessionStorage.setItem("refresh_token", res.refreshToken);
  };

  const getToken = () => {
    // Retrieves the user token from localStorage
    return sessionStorage.getItem("access_token");
  };

  const logout = () => {
    // Clear user token and profile data from localStorage
    // localStorage.removeItem('access_token')
    // setUser(undefined);
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
  };

  const getProfile = () => {
    const token = getToken()
    if (token) {
      // Using jwt-decode npm package to decode the token
      const decoded = decode(token);
      return decoded.data;
    }
    return false
  };

  const user = getProfile()

  const prepareFetch = async (url, options ) => {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (loggedIn()) {
      headers["Authorization"] = "Bearer " + getToken();
    }

    const res = await fetch(url, {
      headers,
      ...options,
    });
    // const resStatus = checkStatus(res, redirect);
    return res.json();
    //   .then((response) => _checkStatus(response, redirect))
    //   .then((response) => response.json());
  };

  // function checkStatus(response, redirect) {
  //   // raises an error in case response status is not a success
  //   if (response.status >= 200 && response.status < 300) {
  //     // Success status lies between 200 to 300
  //     return response;
  //   } else {
  //     // console.log(response)
  //     if (response.status === 401) {
  //       sessionStorage.removeItem("access_token");
  //       sessionStorage.removeItem("refresh_token");
  //       console.log('lets redirect')
  //       // by default on error will redirect back to login but if redirect is false then it is up to the caller to handle the situation, e.g. if we just want to display an error message on login form
  //       if (redirect) {
  //         navigate("/login");
  //       }
  //     }
  //     return response;
  //   }
  // }

  const resetPassword = async (email) => {
    // Get a token from api server using the fetch api
    const res = await prepareFetch(`${domain}/v1/public/user/password-reset`, {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
    });
    return res;
  };

  // const userFromProfile = getProfile()

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      login,
      logout,
      getProfile,
      getToken,
      loggedIn,
      resetPassword,
      refreshToken,
    }),
    // [loading, error]
  );

  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
  return useContext(AuthContext);
}
