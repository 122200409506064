import HomeButton from "@mui/icons-material/Home";
import { AppBar, Button, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import HeaderUserMenu from "components/HeaderUserMenu";
import { ACCENT_COLOR, BG_DARK_COLOR } from "consts/colors";
import useAuth from "context/AuthContext";
import logo from "images/applogo-white.png";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Link as RouterLink } from "react-router-dom";
import RegistrationMobileHeader from "./RegistrationMobileHeader";
import RegistrationMobileMenu from "./RegistrationMobileMenu";

const MyNavLink = (props) => {
  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 300);
  };
  return <NavLink {...props} onClick={handleClick} />;
};

const RegistrationDesktopHeader = ({ classes, isLoggedIn, setIsLoggedIn }) => {
  const { t } = useTranslation();
  return (
    <>
      <AppBar
        sx={{
          display: { sm: "none", md: "flex" },
          background: BG_DARK_COLOR,
          
        }}
        // position="fixed"
        elevation={0}
      >
        <Toolbar
          sx={{
            display: "flexbox",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <RouterLink to="/">
            <img src={logo} style={{ height: 38 }} alt="" />
          </RouterLink>
          <Box
            sx={{
              justifyContent: "space-around",
              gap: (theme) => theme.spacing(3),
              display: "flex",
            }}
          >
            <NavLink style={classes.iconNavLink} to="/register">
              <HomeButton />
            </NavLink>
            {/* <NavLink sx={classes.topNavLink} to="/blog/">
            {t("Blog")}
          </NavLink> */}
            <MyNavLink style={classes.topNavLink} to="/register/why">
              {t("Why us")}
            </MyNavLink>
            <MyNavLink style={classes.topNavLink} to="/register/pricing">
              {t("Pricing")}
            </MyNavLink>
            <MyNavLink style={classes.topNavLink} to="/register/architecture">
              {t("Features")}
            </MyNavLink>
            <NavLink style={classes.topNavLink} to="/support">
              {t("How to")}
            </NavLink>
            {/* <NavLink sx={classes.topNavLink} to="/demo">
          {t("Demo")}
          </NavLink> */}
            <NavLink style={classes.topNavLink} to="/contact">
              {t("Contact")}
            </NavLink>
            </Box>
            <Button component={RouterLink} to={"/register-form"} color="secondary" variant="outlined">
              {t("Register")}
            </Button>
         

          {isLoggedIn && <HeaderUserMenu setIsLoggedIn={setIsLoggedIn} />}
        </Toolbar>
      </AppBar>
    </>
  );
};

const RegistrationHeader = (props) => {
  // const { auth } = useContext(AppDataContext);
  const { loggedIn } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn());
  const styles = {
    root: {
      flexGrow: 1,
    },
    logoIcon: {
      color: (theme) => theme.palette.secondary.main,
      width: "1.5em",
      height: "1.5em",
    },
    betaTitle: {
      display: "inline-flex",
      backgroundColor: "transparent",
      position: "absolute",
      left: 200,
      top: 16,
      border: "1px solid " + ACCENT_COLOR,
      color: ACCENT_COLOR,
      padding: "2px 4px",
      fontSize: 14,
      borderRadius: 4,
      alignItems: "center",
    },
    buttonIcon: {
      marginRight: (theme) => theme.spacing(1),
    },
    topNavLinks: {
      marginRight: (theme) => theme.spacing(3),
    },
    iconNavLink: {
      fontSize: ".875rem",
      // fontWeight: 600,
      textTransform: "none",
      lineHeight: "19px",
      textDecoration: "none",
      cursor: "pointer",
      margin: "0 24px",
      color: "rgba(255,255,255,0.8)",
      transition: "all 0.25s ease",
      "&:hover": {
        color: ACCENT_COLOR,
      },
    },
    topNavLink: {
      fontSize: ".875rem",
      fontWeight: 600,
      textDecoration: "none",
      cursor: "pointer",
      color: "rgba(255,255,255,0.8)",
      transition: "all 0.25s ease",
      "&:hover": {
        color: ACCENT_COLOR,
      },
    },
    button: {
      height: 60,
      borderRadius: 30,
      minWidth: 180,
      margin: (theme) => theme.spacing(2),
      color: "rgb(255,255,255 / 0.7)",
      borderColor: "rgb(255,255,255 / 0.5)",
      transition: "all 0.25s ease",
      "&:hover": {
        textDecoration: "none",
        borderColor: "rgb(255,255,255 / 1)",
      },
    },
    accentButton: {
      backgroundColor: ACCENT_COLOR,
      borderColor: ACCENT_COLOR,
      color: "rgba(255,255,255,1)",
      "&:hover": {
        backgroundColor: "white",
        color: ACCENT_COLOR,
        borderColor: ACCENT_COLOR,
      },
    },
    navRegisterButton: {
      display: "inline-flex",
      height: 30,
      minWidth: 50,
      padding: "3px 20px",
      borderRadius: 15,
    },
  };
  const [mobileOpen, setMobileOpen] = React.useState(false);
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <>
      <RegistrationDesktopHeader classes={styles} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <RegistrationMobileHeader
        handleToggle={handleDrawerToggle}
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
      />
      <RegistrationMobileMenu open={mobileOpen} handleToggle={handleDrawerToggle} />
    </>
  );
};

export default RegistrationHeader;
