import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// validation using yup test callback
const confirmPassword = (value) => {
    return value === document.getElementById('password').value;
  };
// alternative field level validation (working). To use this uncomment validation property on Field component and comment passwordConfirm.test()
// const validatePasswordConfirm = (passwordConfirm) => {
//   let error
//   if (!passwordConfirm) {
//     error = "Password confirmation is required!"
//   }
//   else if (passwordConfirm !== document.getElementById("password").value) {
//     error = "Passwords do not match"
//   }
//   // console.log('validate pass',passwordConfirm, error)
//   return error
// }
  
const userValidationSchema = {
    firstname: Yup.string().required('Required').min(3).max(255),
    lastname: Yup.string().required('Required').min(3).max(255),
    email: Yup.string().email().required('Required').min(5),
    address: Yup.string().min(3).max(500),

    // now we do this on submit because formik triggers validation on every field onchange and causes unnecessary api calls (the same with field level validations)
    // .test('uniqueEmail', 'This email is already taken!', (value) => isEmailAvailableAsync(value)),
    // now we do this on submit because formik triggers validation on every field onchange and causes unnecessary api calls (the same with field level validations)
    // .test('uniqueUser', 'This username is already taken!', (value) => isUsernameAvailableAsync(value)),
    mobilephone: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Required')
      .length(10),
    // address: Yup.string()
    //   .required('Required')
    //   .min(3)
    //   .max(500),
    password: Yup.string()
      .min(6, 'Password has to be longer than 6 characters!')
      .required('Password is required'),
    passwordConfirm: Yup.string()
      .required('Password confirmation is required')
      .test('confirmPassword', 'Passwords do not match', (value) =>
        confirmPassword(value)
      ),

    // None of the following methods worked so we should use .test() or field level validation from https://github.com/jaredpalmer/formik/issues/90
    //.oneOf([values.password], 'Passwords are not the same!')
    // https://wanago.io/2019/10/28/writing-react-forms-with-formik-validating-data-using-yup
    // .oneOf([Yup.ref('password'),null], 'Password does not match')
    // .required('Password confirmation is required!'),

    // consent: Yup.bool()
    //  .test(
    //    'consent',
    //    'You have to agree with our Terms and Conditions!',
    //    value => value === true
    //  )
    //  .required(
    //    'You have to agree with our Terms and Conditions!'
    //  ),
  };

  export default userValidationSchema;