import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Grid, IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import { useTheme } from "@mui/system";
import Button from "components/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
// @see https://stackoverflow.com/questions/50831450/how-to-overwrite-styles-and-styles-in-material-ui-react
// @see material-ui customize

// Material UI uses this higher-order component approach, which allows theming reuse:
// define styles by returning them from a function that accepts a
// theme object with customizations

// const styles = function(theme) {return ....}

// Using custom error handling to avoid formik etc...
// based on https://upmostly.com/tutorials/form-validation-using-custom-react-hooks
const PasswordConfirm = (props) => {
  const { t } = useTranslation();
  const { passwordToken } = useParams();
  const theme = useTheme()
  const styles = {
    paper: {
      marginTop: theme.spacing(6),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      marginTop: theme.spacing(3),
      padding: 15,
      textTransform: "initial",
    },
    loginLink: {
      fontSize: 18,
      color: theme.palette.secondary.main,
      margin: theme.spacing(0, 1),
      textDecoration: "none",
    },
    accountIcon: {
      fontSize: theme.spacing(8),
    },    
  }
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmissionCompleted, setSubmissionCompleted] = useState(false);

  //   const [touched, setTouched] = useState({}); // touched status for every form field e.g. touched.email

  const [values, setValues] = useState({ password: "", passwordConfirm: "" }); // form values e.g. values.email
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const checkPasswordValidation = (value) => {
    const isWhitespace = /^(?=.*\s)/;
    if (isWhitespace.test(value)) {
      return "Password must not contain Whitespaces.";
    }
    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹])/;
    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(value)) {
      return "Password must have at least one Uppercase Character.";
    }
    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(value)) {
      return "Password must have at least one Lowercase Character.";
    }
    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(value)) {
      return "Password must contain at least one Digit.";
    }
    if (!isContainsSymbol.test(value)) {
      return "Password must contain at least one Special Symbol.";
    }
    const isValidLength = /^.{8,14}$/;
    if (!isValidLength.test(value)) {
      return "Password must be 8-14 Characters Long.";
    }
    const isEnglishLetters = /[^\w~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]+/;
    if (isEnglishLetters.test(value)) {
      return "Password must contain only english letters or numbers and symbols";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values.password !== values.passwordConfirm) {
      setErrors({ ...errors, passwordConfirm: t("Passwords don't match") });
      return;
    }
    // send password and token to server
    const reqData = {
      token : passwordToken,
      password: values.password
    }
    const passwordConfirmFormEndpoint = process.env.REACT_APP_PUBLIC_API_URL + '/user/confirm-password-token';
    fetch(passwordConfirmFormEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(reqData),
    })
    .then((res) => res.json())
    .then((res) => {
      // api returns 'success'
      if (res.message !== 'success') {
        throw new Error(res.message);
      }
      setSubmissionCompleted(true)
    })
    .catch((error) => {
      setErrors({message: error.message});
    });
  };

  const handleChange = (event) => {
    if (event.target.name === "password") {
      const res = checkPasswordValidation(event.target.value);
      if (res) {
        setErrors({ ...errors, password: t(res) });
      } else {
        setErrors({});
      }
    } else if (event.target.name === "passwordConfirm") {
      setErrors({});
    }

    setValues({ ...values, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Paper sx={styles.paper} elevation={0}>
        <Grid container maxwidth="sm" alignItems="center" style={{ marginTop: 24 }} justifyContent="center">
          <Grid item xs={12}>
            <div style={{ textAlign: "center", fontSize: 18 }}>{t("Recover your password")}</div>
          </Grid>
        {!isSubmissionCompleted && (
        <form sx={styles.form} onSubmit={(e) => handleSubmit(e)}>
          <TextField
            name="password"
            id="password"
            label={t("Password")}
            error={typeof errors.password !== "undefined"}
            helperText={errors && errors.password}
            sx={styles.textField}
            fullWidth
            value={values.password}
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            required
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="passwordConfirm"
            id="password-confirm"
            label={t("Confirm Password")}
            type={"password"}
            error={typeof errors.passwordConfirm !== "undefined"}
            sx={styles.textField}
            onChange={handleChange}
            // error={touched.passwordConfirm && errors && errors.passwordConfirm}
            helperText={errors && errors.passwordConfirm}
            value={values.passwordConfirm}
            fullWidth
            required
            margin="normal"
          />

          <div>
            <Button sx={styles.submit} variant="contained" type="submit" color="primary" size="large">
              {t("Save")}
            </Button>
          </div>
          {(errors.length!==0) && (
            <div style={{ margin: 16 }}>
              <Alert severity="error">{errors.message}</Alert>
            </div>
          )}
        </form>)}
        {isSubmissionCompleted && (
          
          <Alert severity="success">{t('congrats_change_pass')}</Alert>

        )}
        </Grid>
      </Paper>
    </>
  );
};

// withStyles is the higher order component that maps the defined
// classnames to a property 'styles' on the passed in Component
// export default withStyles(styles)(Login);

// multiple hoc export
export default PasswordConfirm;
