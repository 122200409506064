const PRIVATE_API_URL = process.env.REACT_APP_PRIVATE_API_URL;
const registrationFormEndpoint = PRIVATE_API_URL + "/academies/register-user";

const AcademyRegistrationHandle = async (
  setSubmissionCompleted,
  siteid,
  userData,
  setSubmissionError,
  setApplied,
  getToken,
  values,
  actions
) => {
  actions.setStatus(undefined);
  let serverErrors = {};
  // ABORT IF SERVER/API VALIDATIONS FAIL...
  if (Object.keys(serverErrors).length) {
    actions.setStatus(serverErrors); // this will allow us to display server errors in a separate section
    actions.setErrors(serverErrors); // this will display server errors in each field's helper as client validation errors
    actions.setSubmitting(false);
    setSubmissionCompleted(false);
    return false;
  }
  // IF NO SERVER ERRORS CONTINUE WITH THE SUBMIT
  actions.setSubmitting(true);

  // add siteid
  if (!siteid) {
    throw new Error("Siteid must be specified");
  }

  let sportsIds = [];
  values.sports.forEach((sport) => {
    sportsIds.push(sport.sportid);
  });
  
  const childsData = Object.entries(values.childs)
    .map(([index, child]) => {
        return {
          id: index,
          sport: child.sport,
        };
    });
  const requestData = {
    userid: userData.id,
    siteid: siteid,
    comments: values.comments,
    data: {
      role: values.role,
      childsData,
      // values.role === MemberRoles.ROLE_PLAYER ? 0 : values.childs,
      sports: sportsIds.toString(),
    },
  };

  // for credentials etc
  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  const token = getToken();
  fetch(registrationFormEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify(requestData),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      return res;
    })
    .then((res) => res.json())
    .then((res) => {
      // api returns 'success'
      if (res.message !== "success") {
        throw new Error("Server API error");
      }
      actions.setSubmitting(false);
      setSubmissionCompleted(true);
      setSubmissionError(false);
      setApplied(true); // if everything was ok then update applied status (this will allow us to provide visual feedback to user in sitecard e.g. disable signup button)
    })
    .catch((error) => {
      // network failure or if anything prevented the request from completing
      actions.setSubmitting(false);
      setSubmissionCompleted(true);
      setSubmissionError(error);
    });
};

export default AcademyRegistrationHandle;
