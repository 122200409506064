// main issue is that geocoder.geocode() is asynchronous and takes a callback so we use a promise
// @see https://developers.google.com/maps/documentation/javascript/reference/geocoder
// https://developers.google.com/maps/documentation/javascript/localization
const geocodeByCoordinates = (geocoder, coords) => {
    return new Promise(function(resolve, reject) {
        geocoder.geocode({'location': {lat: coords.lat, lng: coords.lng}}, function(results, status) {
            // console.log('geocode',status,results)
            let result =  null
            if (status === 'OK') {
              if (results[0]) {
                // console.log('results',results[0])
                result = results[0]; // .formatted_address;
              } else {
                result = 'Cannot retrieve address info for this location'
              }
            } else {
              // result = 'Google maps failed due to: ' + status;
              result = 'Cannot retrieve address info for this location'
            }
            resolve(result);
        });
    });
}

const geocodeByPlaceID = async (geocoder, placeId) => {
  // @based on mui places autocomplete
  // After the component is mounted it is safe to create a new instance of the Geocoder client.
  // That's because at this point the Google Maps JavaScript API has been loaded. Also if we do it
  // before the component is mounted (i.e. in 'componentWillMount()') we won't be safe to render
  // on the server (SSR) as the 'window' object isn't available.
  // if(!geocoder) {
  //   geocoder = new window.google.maps.Geocoder()
  // }
  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
        reject(
          new Error(
            `Geocoding query for a place with an ID of '${placeId}' failed - response status: ${status}`,
          ),
        )
        return
      }

      resolve(results)
    })
  })
}

const getAddressFromCoordinates = (geocoder, coords) => {
    // if(!geocoder) {
    //   geocoder = new window.google.maps.Geocoder()
    // }
    return new Promise(function(resolve, reject) {
        geocoder.geocode({'location': {lat: coords.lat, lng: coords.lng}}, function(results, status) {
            // console.log('geocode',status,results)
            let result =  null
            if (status === 'OK') {
              if (results[0]) {
                result = results[0].formatted_address;
                // console.log('getAddress', result)
              } else {
                result = 'Cannot retrieve address info for this location'
              }
            } else {
              // result = 'Google maps failed due to: ' + status;
              result = 'Cannot retrieve address info for this location'
            }
            resolve(result);
        });
    });
}



// Returns the specific information e.g. city, region, country etc from google place 
// based on https://stackoverflow.com/questions/8082405/parsing-address-components-in-google-maps-upon-autocomplete-select stephen.hanson
// @see https://developers.google.com/maps/documentation/javascript/reference/geocoder#GeocoderResult
// param place is the result of google reverse geocode by placeid @see geocodeByPlaceID
// TODO: fix cases when city and region are under different components e.g. administrative_area_level_2 or 3
function getAddressComponent(place, componentName, property) {
  var comps = place.address_components.filter(function(component) {
    return component.types.indexOf(componentName) !== -1;
  });

  if(comps && comps.length && comps[0] && comps[0][property]) {
    return comps[0][property];
  } else {
    return null;
  }
}

// Returns the address data as we wanted them for academy registration
// TODO: for 5+4 zip code https://stackoverflow.com/questions/15386100/extracting-plus-4-zip-code-from-geocoding-using-google-api-jquery
function getAddressData(place) {
  // workaround because region in some countries maybe is stored in level_1,2,3,4
  let region = getAddressComponent(place, 'administrative_area_level_1', 'short_name');
  if(!region)
    region = getAddressComponent(place, 'administrative_area_level_2', 'short_name');
  if(!region)
    region = getAddressComponent(place, 'administrative_area_level_3', 'short_name');
  if(!region)
    region = getAddressComponent(place, 'administrative_area_level_4', 'short_name');
  let streetNumber = getAddressComponent(place, 'street_number', 'long_name');
  return {
    'google_place_id': place.place_id,
    'country': getAddressComponent(place, 'country', 'long_name'),
    'country_iso_code': getAddressComponent(place, 'country', 'short_name'),
    'region': region,
    'city': getAddressComponent(place, 'locality', 'long_name'),
    'postal_code': getAddressComponent(place, 'postal_code', 'short_name'),
    'address': getAddressComponent(place, 'route', 'long_name') + (streetNumber ? ' ' + streetNumber : ''), // name + number
  }
}

export { geocodeByCoordinates, geocodeByPlaceID, getAddressComponent, getAddressData, getAddressFromCoordinates };

