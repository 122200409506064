import { Skeleton } from '@mui/material';

const SiteCardSkeleton = props => {
  const styles = {
    sport: {
      width: '20%',
      height: 32,
      marginRight: 5,
      // float: 'left',
      display: 'inline-block'
    },
    button: {
      display: 'block',
    }    
  }
  return (
    <div style={{padding: 15}}>
      <Skeleton variant="rectangular" width={'100%'} height={200} />
      <Skeleton height={32} />
      <Skeleton height={32}/>
      <div>
        <Skeleton width={"30%"} height={32} />
        <Skeleton variant="rectangular" width={'100%'} height={2} />
        <Skeleton sx={styles.sport} /> 
        <Skeleton sx={styles.sport} /> 
        <Skeleton sx={styles.sport} /> 
        <Skeleton sx={styles.sport} /> 
      </div>
      <Skeleton  width={"100%"} height={60} />
    </div>
  );
}

export default SiteCardSkeleton;