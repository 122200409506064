import { Typography } from '@mui/material';
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button';
import SectionDivider from '../../../components/SectionDivider';
import SiteCards from '../../../components/SiteCards';


const API_URL = process.env.REACT_APP_PUBLIC_API_URL;

function LatestSites(props) {
  const styles = {
    landingSection: {
      padding: '50px 0',
    },
    buttonContainer: {
      // margin: 15
    },
    latestSitesContainer: {
      // display: 'flex',
      transition: 'all 0.5s ease 0s',
      marginTop: 50
      // overflowX: 'auto' // horizontal scrolling instead of responsive (but must be done in sitecards component)
    },
    sectionTitle: {
      textTransform: 'initial',
      fontSize: 32
    }
  }
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const { t } = useTranslation()

  // const dataContext = useContext(AppDataContext);
  // const { sports, regions, sites, applyFilter } = dataContext;
  // const latestSites = applyFilter(sites, {limit: 3, sortField: 'created_at', sortOrder: 'desc'})
  
  useEffect(() => {
    fetch(`${API_URL}/sites/latest/3`)
    .then(res=>res.json())
    .then(res=>{
      setData(res);
      setLoading(false);
    })
    .catch(error=>{setError(error.statusText); setLoading(false)})
  },[]);

  if(loading)
    return 'loading...'

  if(error)
    return <div>{error}</div>

  return (
    <Box component="section" sx={styles.landingSection}>
    <Container maxWidth="lg">
      <Typography variant="h4" sx={styles.sectionTitle} marked="center" component="h2">
          {t("Latest Clubs")}
      </Typography>
      <div styles={styles.latestSitesContainer}>
        <SiteCards data={data} compact={true} itemsLG={3} itemsMD={3} />
      </div>
      <SectionDivider />
      <Grid container alignItems="center" justifyContent="center" >
          <Button
            color="secondary"
            sx={styles.button}
            component={Link}
            to={{ pathname: `/search`, state: {} }}
          >
            {t("View More")}
          </Button>
        </Grid>   
      </Container>
    </Box>
  );
}

export default LatestSites;