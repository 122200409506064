import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";


function FeatureItem(props) {
  const Icon = props.icon; // first capital in Icon needed by JSX else will translate to native dom element, also not working when destructuring props. https://stackoverflow.com/questions/42510874/react-pass-component-as-prop-without-using-this-props-children
  const { title, text, size } = props;
  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Icon sx = {{
            width: (size) === "large" ? "3.5em" : "2.5em",
            height: (size) === "large" ? "3.5em" : "2.5em"
          }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" >
            {text}
          </Typography>
        </Grid>
      </Grid>
  );
}

export default FeatureItem;
