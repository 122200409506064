import { useQuery } from "@apollo/client";
import { PERSON_WITH_CHILDS_QUERY } from "graphql/getPersonWithChilds";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import SearchWithMap from "../../../components/SearchWithMap";



// Search Academies from dashboard.
// The user must have logged in first (to get personalized search e.g. cannot signup to an academy which is already a member)
const  SearchAcademies = (props) => {
  const { userid } = useParams()
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(PERSON_WITH_CHILDS_QUERY, {
    variables: { id: userid },
    fetchPolicy: "network-only",
  });
  if (loading) return <div>{t("Fetching")}</div>;
  if (error) return <div>{error.message}</div>;
  const signedSites = [];
  data.person.siteProfiles.forEach((siteProfile) => signedSites.push(siteProfile.siteid));
  const pendingSites = [];
  if (data.person.userRegistrations) {
    data.person.userRegistrations.forEach((registration) => pendingSites.push(registration.siteid));
  }
  return (
    // pass all props from wrapper component including signedSites
    <SearchWithMap {...props} userid={userid} signedSites={signedSites} pendingSites={pendingSites} home={true} />
  );
};

export default SearchAcademies;
