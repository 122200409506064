import { gql } from "@apollo/client"

export const PERSON_UPDATE = gql`mutation UpdatePerson ($input: PersonInput!) {
  updatePerson (person: $input) {
  lastname,
  firstname,
  address,
  mobilephone,
  email,
  id
  }
}`
