import { gql } from "@apollo/client"

export const SINGLE_SITE_PROFILE_QUERY = gql`query SingleSiteProfile ($id: ID!, $siteid: String!) {
  singleSiteProfile(userid: $id, siteid: $siteid) {
    id,
    userid,
    siteid,
    firstname,
    lastname,
    photo,
    personal_data_policy,
    mobilephone,
    phone,
    address,
    postcode,
    connect_customerid,
    email,
    status,
    edit_by_user,
    site{
      siteid,
      name,
      domain_name,
      logo
    },
    seasons {
      name
    },
    childs {
      id,
      firstname,
      lastname,
      siteid,
      photo,
      personal_data_policy
    },
  }
}`
