import CloseIcon from "@mui/icons-material/Close";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import RegisterClubIcon from "@mui/icons-material/GroupAdd";
import SigninIcon from "@mui/icons-material/LockOpen";
import SignupIcon from "@mui/icons-material/PersonAdd";
import { IconButton, List, ListItemButton, ListItemIcon /* ListItemAvatar, IconButton */, ListItemText } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { ROOT_ACCENT_COLOR } from "consts/colors";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const DRAWER_WIDTH = 300;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    position: "absolute",
    left: 15,
    marginLeft: -12,
    marginRight: 20,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  logoIcon: {
    color: theme.palette.secondary.main,
    width: "1.5em",
    height: "1.5em",
  },
  logoLink: {
    marginRight: "auto",
  },
  signinButton: {
    color: "black",
  },
  signupButton: {
    color: "white",
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
    marginLeft: theme.spacing(1),
    marginTop: 3,
    // color: 'white'
  },
  betaTitle: {
    flexGrow: 1,
    textAlign: "left",
    marginLeft: theme.spacing(1),
    // marginTop: 1,
    color: "red",
  },
  normalHeader: {
    // color: 'black'
    background: "white",
  },
  landingHeader: {
    background: "white",
    // boxShadow: 'none'
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  signinButtonMobile: {},
  signupButtonMobile: {},
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },

  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  closeMenuButton: {
    marginRight: "auto",
    marginLeft: 0,
  },
  listItem: {
    fontWeight: "600",
    transition: "all 0.2s ease",
    "&:hover": {
      // color: "#fff",
      backgroundColor: ROOT_ACCENT_COLOR,
    },
  },
});

const Menu = ({ classes, handleToggle, t }) => (
  <>
    <List>
      <NavLink to="/login" style={{ textDecoration: "none" }}>
        <ListItemButton className={classes.listItem} onClick={handleToggle}>
          <ListItemIcon>
            <SigninIcon />
          </ListItemIcon>
          <ListItemText primary={t("Sign in")} secondary={t("for registered members")} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/signup" style={{ textDecoration: "none" }}>
        <ListItemButton className={classes.listItem} onClick={handleToggle}>
          <ListItemIcon>
            <SignupIcon />
          </ListItemIcon>
          <ListItemText primary={t("Sign up")} secondary={t("register member account")} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/contact" style={{ textDecoration: "none" }}>
        <ListItemButton className={classes.listItem} onClick={handleToggle}>
          <ListItemIcon>
            <ContactMailIcon />
          </ListItemIcon>
          <ListItemText primary={t("Contact")} secondary={t("send us your questions")} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/register" style={{ textDecoration: "none" }}>
        <ListItemButton className={classes.listItem} onClick={handleToggle}>
          <ListItemIcon>
            <RegisterClubIcon />
          </ListItemIcon>
          <ListItemText primary={t("Register Sport Club")} secondary={t("for club admins")} />
        </ListItemButton>
      </NavLink>
    </List>
  </>
);

// https://medium.com/@tsubasakondo_36683/create-responsive-drawer-menu-with-react-material-ui-617a42764b69
export const MobileMenu = (props) => {
  const theme = useTheme();
  const classes = styles;
  // const container = window !== undefined ? () => window().document.body : undefined;
  const { t } = useTranslation()

  return (
    <>
      {/* <nav className={classes.drawer}> */}
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Box
        component="nav"
        sx={{ width: { sm: 300 }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={props.open}
          onClose={props.handleToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <IconButton onClick={props.handleToggle} className={classes.closeMenuButton} size="large">
            <CloseIcon />
          </IconButton>
          <Menu t={t} classes={classes} handleToggle={props.handleToggle} />
        </Drawer>
      </Box>
    </>
  );
};

export default MobileMenu;
