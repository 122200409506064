import { Grid, Typography } from "@mui/material";
// import CircularProgress from '@mui/material/CircularProgress';
// import CardCarousel from './CardCarousel';
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import SiteCard from "./SiteCard";
import SiteCardSkeleton from "./SiteCardSkeleton";


// we must use either react-window/react-virtualized OR infinite scroll
// read: https://dev.to/nishanbajracharya/what-i-learned-from-building-my-own-virtualized-list-library-for-react-45ik
// https://web.dev/virtualize-long-lists-react-window
// https://github.com/bvaughn/react-virtualized/blob/master/docs/InfiniteLoader.md
// https://virtuoso.dev/customize-structure/
// https://blog.logrocket.com/windowing-wars-react-virtualized-vs-react-window/
// https://addyosmani.com/blog/react-window/

// based on example complete:
// article: https://appendto.com/2018/10/airnyt-airbnb-nyt-clone/
// src: https://github.com/kpennell/nytairbnbbucketlist/blob/master/src
// demo: http://nytrecsalaairbnb.surge.sh/

const SiteCards = (props) => {
  const theme = useTheme()
  const styles = {
    cardsContainer: {
      paddingLeft: (theme) => theme.spacing(1),
      paddingRight: (theme) => theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        paddingLeft: (theme) => theme.spacing(1),
        paddingRight: (theme) => theme.spacing(1),
      },
    },
  }
  const { data, loading } = props;
  const { t } = useTranslation()
  const compact = props.compact ? props.compact : false;
  // console.log("data cards", data);
  // how many items to show in one row in different breakpoints
  const itemsXS = props.itemsXS ? props.itemsXS : 1;
  const itemsSM = props.itemsSM ? props.itemsSM : 2;
  const itemsMD = props.itemsMD ? props.itemsMD : 3;
  const itemsLG = props.itemsLG ? props.itemsLG : 3;

  if (!loading && (!data || data.length < 1))
    return (
      <Typography color="inherit" align="center" variant="h6">
        <p>{t("noResults")}</p>
      </Typography>
    );

  return (
    <>
      {/* react-window infinite load attempt <SitesGrid sites={data} />*/}

      {/* 
        show all at once - no pagination
        With Skeleton we create an empty array to render placeholders until data is loaded
       */}
      <Grid
        sx={styles.cardsContainer}
        container
        // spacing={compact ? 0 : 1}
        // justifyContent="flex-start"
        // direction="row"
      >
        {(loading ? Array.from(new Array(8)) : data).map((site, index) => (
          <Grid
            item
            xs={12 / itemsXS}
            sm={12 / itemsSM}
            md={12 / itemsMD}
            lg={12 / itemsLG}
            key={site ? site.siteid : index}
            // sx={{ padding: 0 }}
          >
            {site ? (
              <SiteCard
                home={props.home ? props.home : false}
                signed={
                  props.signedSites
                    ? props.signedSites.includes(site.siteid)
                    : false
                }
                pending={
                  props.pendingSites
                    ? props.pendingSites.includes(site.siteid)
                    : false
                }
                site={site}
                compact={compact}
                setCurrentMapCenter={props.setCurrentMapCenter}
                setMustUpdateMap={props.setMustUpdateMap}
                setPlace={props.setPlace}
              />
            ) : (
              <SiteCardSkeleton />
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default SiteCards;
