import Avatar from "@mui/material/Avatar";
import SiteHelper from "../utils/SiteHelper";

// https://medium.com/@webcore1/react-fallback-for-broken-images-strategy-a8dfa9c1be1e
// https://github.com/mui-org/material-ui/issues/11128
const defaultImage = "/images/academy_logo_default.png";

function SiteCardLogo(props) {
  const { site, size } = props;
  const srcImage = site.logo ? SiteHelper.getSiteLogo(site) : defaultImage;

  return (
    <>
      <Avatar
        alt="logo"
        src={srcImage}
        sx={{
          height: size,
          width: size,
          margin: 1,
          backgroundColor: "#fff",
          padding: 1,
          boxShadow: "0 0 4px #404040",
          borderRadius: "50%",
          border: "2px solid #f3f3f3",
          position: "absolute",
          zIndex: 1,
        }}
        imgProps={{
          onError: (e) => {
            e.target.src = defaultImage;
          },
        }}
      />
    </>
  );
}

export default SiteCardLogo;
