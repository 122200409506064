import { gql } from "@apollo/client"

export const SITE_USER_UPDATE = gql`mutation updateSiteUser ($input: SiteUserInput!) {
  updateSiteUser (siteUser: $input) {
  lastname,
  firstname,
  address,
  postcode,
  mobilephone,
  id
  }
}`
