import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const StripeSubscriptionsGeneral = (props) => {
  const { siteid, userid } = props;
  const { t } = useTranslation()

  return (
    <>
    <Box mt={8} ml={5}>
      <Typography gutterBottom variant='h5'>
        <Button
          
          variant='contained'
          startIcon={<ShoppingCartIcon />}
          size="large"
          color='primary'
          component={NavLink}
          to={{ pathname: `/home/manageSiteSubscriptions/${siteid}/${userid}` }}
        >
          {t('Buy Subscriptions')}
        </Button>
      </Typography>
      </Box>
    </>
  );
}

export default StripeSubscriptionsGeneral;
