import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import useAuth from "context/AuthContext";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import logo from "../images/applogo.png";
import HeaderUserMenu from "./HeaderUserMenu";
import LanguageSwitcher from "./common/LanguageSwitcher";

export const MobileHeader = (props) => {
  // const { auth } = useContext(AppDataContext);
  const { loggedIn } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn()); // TODO: do it in app context???

  // revert to position fixed, to enable hide on scroll uncomment component HideOnScroll
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar  position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="Open drawer"
            sx={{ mx: 2 }}
            onClick={props.handleToggle}
          >
            <MenuIcon />
          </IconButton>
          <RouterLink to="/">
            <img src={logo} alt="" />
          </RouterLink>
          {isLoggedIn && <HeaderUserMenu setIsLoggedIn={setIsLoggedIn} />}
          <Box component="div" sx={{ flexGrow: 1 }}>
          </Box>
          <LanguageSwitcher />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MobileHeader;
