import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useMutation } from "@apollo/client";
import CardSection from "./CardSection";
import { Button, Typography, Collapse, Alert } from "@mui/material";
import { CREATE_STRIPE_SUBSCRIPTION } from "graphql/createStripeSubscription";
import { UPDATE_PAYMENT_METHOD } from "graphql/updatePaymentMethodAndPayInvoice";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const [error, setError] = useState(null);
  const [open, setOpen] = React.useState(true);
  const [subscribed, setSubscribed] = useState("start");
  const elements = useElements();
  const { t, priceid, quantity, siteid, childs, handleCustomerActionRequired, handlePaymentMethodRequired } = props;

  const handleSubmit = async ({ event, createSubscription, updatePaymentMethod, subscription }) => {
    event.preventDefault();
    setOpen(true);
    setError(null);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    try {
      // check if this is a retry
      const latestInvoicePaymentIntentStatus = sessionStorage.getItem("latestInvoicePaymentIntentStatus");
      const paymentRes = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        const invoiceId = sessionStorage.getItem("latestInvoiceId");
        // const isPaymentRetry = true;
        // update customer payment method
        // pay the invoice with the new method
        const updateMethodInput = {
          siteid,
          paymentMethodId: paymentRes.paymentMethod.id,
          invoiceId,
        };
        const updateMethodVars = { variables: { input: updateMethodInput } };
        const {
          data: { updatePaymentMethodAndPayInvoice: invoice },
        } = await updatePaymentMethod(updateMethodVars);

        // check if invoice is paid ok, otherwise restart the procedure
        // I should check again the guides to make code more stable
        const invoicePaymentIntent = invoice.payment_intent;
        if (invoicePaymentIntent.status === "succeeded") {
          setSubscribed("active");
          return;
        } else if (invoicePaymentIntent.status === "requires_payment_method") {
          throw new Error("Your card was declined.");
        } else if (invoicePaymentIntent.status === "requires_action") {
          const { message } = await handleCustomerActionRequired({
            paymentMethodId: paymentRes.paymentMethod.id,
            paymentIntent: invoicePaymentIntent,
            stripe,
          });
          if (message === "success") {
            setSubscribed("active");
          }
        }
      }

      const method = { paymentMethod: paymentRes.paymentMethod.id };

      const subscriptionInput = { ...subscription, ...method };
      const vars = { variables: { input: subscriptionInput } };
      // pass payment method, price.id and quantity to server to create the subscription
      // the server checks if customer exists, and get the customer from stripe,
      // or create a new one. Then uses the price.id to create a new subscription

      let {
        data: { createStripeSubscription: subscriptionRes },
      } = await createSubscription(vars);
      const {
        latest_invoice: { payment_intent: paymentIntent },
      } = subscriptionRes;
      if (subscriptionRes.status === "incomplete") {
        if (paymentIntent.status === "requires_payment_method") {
          subscriptionRes = await handlePaymentMethodRequired({
            subscription: subscriptionRes,
            // paymentMethodId: paymentRes.paymentMethod.id,
          });
        } else if (paymentIntent.status === "requires_action") {
          const { message } = await handleCustomerActionRequired({
            // subscription: subscriptionRes,
            paymentMethodId: paymentRes.paymentMethod.id,
            paymentIntent,
            stripe,
          });
          if (message === "success") {
            setSubscribed("active");
          }
          return;
          // TODO check also in the webhook
        }
      } else if (subscriptionRes.status === "active") {
        setSubscribed("active");
      }
    } catch (error) {
      if (error.error !== undefined) {
        setError(error.error.message);
      } else {
        setError(error.message);
      }
    }
  };

  const [createSubscription, createStripeMutation] = useMutation(CREATE_STRIPE_SUBSCRIPTION);
  const [updatePaymentMethodAndPayInvoice, updatePAymentMethodMutation] = useMutation(UPDATE_PAYMENT_METHOD);

  const stripeSubscription = {
    siteid,
    priceid,
    quantity,
    childs,
  };

  if (subscribed === "active")
    return (
      <Typography variant="h6" color="textSecondary">
        <Alert severity="success">{t("Successfully created subscription")}</Alert>
      </Typography>
    );

  return (
    <form
      onSubmit={(e) =>
        handleSubmit({
          event: e,
          createSubscription: createSubscription,
          updatePaymentMethod: updatePaymentMethodAndPayInvoice,
          subscription: stripeSubscription,
        })
      }
    >
      <CardSection />
      {error && (
        <Collapse in={open}>
          <div style={{ margin: 16 }}>
            <Alert
              onClose={() => {
                setOpen(false);
              }}
              severity="error"
            >
              {error}
            </Alert>
          </div>
        </Collapse>
      )}
      <Button variant="contained" color="primary" disabled={!stripe} type="submit">
        Confirm order
      </Button>
    </form>
  );
};

export default CheckoutForm;
