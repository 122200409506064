import { CircularProgress, Container, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../Button";

// import Snackbar from './Snackbar';
import SuccessIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import Alert from "@mui/material/Alert";

// import { string } from 'yup';
import useAuth from "context/AuthContext";
import RegistrationMembersSelect from "pages/public/userRegistration/components/RegistrationMembersSelect";
import { useTranslation } from "react-i18next";
import * as MemberRoles from "../../utils/MemberRoles";
import Fade from "../Fade";
import FormikSportsSelect from "../FormikSportsSelect";
import CheckoutChildsAndSports from "../user_registration/CheckoutChildsAndSports";
import AcademyRegistrationHandle from "./academyRegistrationHandle";

// https://benjaminbrooke.me/posts/react-form-validation-with-graphql-formik-and-yup/

// const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
const PRIVATE_API_URL = process.env.REACT_APP_PRIVATE_API_URL;

// const registrationFormEndpoint = PRIVATE_API_URL + '/academies/register-user';
const prefillFormEndpoint = PRIVATE_API_URL + "/academies/valid-user";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  // @see:
  // https://stackoverflow.com/questions/55811114/async-validation-with-formik-yup-and-react
  // https://www.reactnativeschool.com/build-and-validate-forms-with-formik-and-yup/handling-server-errors
  // https://itnext.io/simple-react-form-validation-with-formik-yup-and-or-spected-206ebe9e7dcc
  // https://wanago.io/2019/10/28/writing-react-forms-with-formik-validating-data-using-yup
  sports: Yup.array() // easier to handle with multiple select
    .required("Required")
    .min(1, "Pick at least one sport")
    // childs: Yup.number()
    //   .max(10)
    //   // .when("role", {
    //   is: MemberRoles.ROLE_PARENT || MemberRoles.ROLE_PARENT_PLAYER,
    //   then: Yup.number().min(1),
    // }),
    .when("role", (role, schema) => {
      if (role === MemberRoles.ROLE_PARENT || role === MemberRoles.ROLE_PARENT_PLAYER) {
        return schema.min(1).required("Required");
      }
      return schema.min(0);
    }),
  comments: Yup.string().max(500),
});

// https://dev.to/shoupn/javascript-fetch-api-and-using-asyncawait-47mp
// https://stackoverflow.com/questions/55811114/async-validation-with-formik-yup-and-react
// async function isValueAvailableAsync(field, value) {
//   try {
//     let response = await fetch(
//       `${PUBLIC_API_URL}/user/exists/field/${field}/value/${value}`
//     );
//     response = await response.json();
//     return !response.found; // we invert the value to return true if available
//   } catch (error) {
//     return false;
//   }
// }

// Form to signup logged in user to another academy (site_user)
const SignupToAcademy = (props) => {
  const { site, setApplied } = props;
  const { siteid, sports } = site;
  const { getToken } = useAuth();
  const { t } = useTranslation();
  const styles = {
    sectionTitle: {
      textTransform: "initial",
      fontSize: 32,
    },
    buttonWrapper: {
      position: "relative", // for circular progress to displayed absolutery in this
    },
    buttonProgress: {
      color: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      width: 32,
      height: 32,
      marginTop: -16,
      marginLeft: -16,
    },
    registerSuccess: {
      marginTop: (theme) => theme.spacing(4),
      marginBottom: (theme) => theme.spacing(4),
      minHeight: 400,
    },
    buttonSuccess: {
      marginTop: (theme) => theme.spacing(4),
      marginBottom: (theme) => theme.spacing(4),
    },
    successIcon: {
      fontSize: 72,
      color: "green",
    },
    errorIcon: {
      fontSize: 48,
      color: (theme) => theme.palette.error.dark,
    },
    loginLink: {
      fontSize: 18,
      color: (theme) => theme.palette.secondary.main,
      margin: (theme) => theme.spacing(0, 1),
    },
    consentLink: {
      fontSize: 14,
      color: (theme) => theme.palette.secondary.main,
      margin: (theme) => theme.spacing(0, 1),
    },
    status: {
      borderColor: (theme) => theme.palette.error.light,
      // backgroundColor: theme.palette.error.light,
      padding: (theme) => theme.spacing(1),
      margin: (theme) => theme.spacing(2, 1, 1, 1),
    },
  };

  const [userData, setUserData] = useState();
  const initialValues = {
    sports: sports && sports.length === 1 ? [sports[0]] : [], // if there is only one sport preselect it
    comments: "",
    childs: {},
    // childs: [],
    role: MemberRoles.ROLE_DEFAULT,
  };

  const [showChilds, setShowChilds] = useState(initialValues.role !== MemberRoles.ROLE_PLAYER);

  const [isSubmissionCompleted, setSubmissionCompleted] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [error, setError] = useState(false);

  const checkClick = (e) => {};

  const handleClickRetry = (event) => {
    setSubmissionError(false);
    setSubmissionCompleted(false);
  };

  useEffect(() => {
    const token = getToken();
    fetch(`${prefillFormEndpoint}/${siteid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
    })
      .then((res) => {
        // console.log("res", res);
        // we accept 404 for the error message which contains
        if ((res.status >= 200 && res.status <= 299) || res.status === 404) {
          return res;
        } else {
          throw new Error(res.statusText);
        }
      })
      .then((res) => res.json())
      .then((res) => {
        // api should return user data from site_user
        if (!res.id) {
          if (res.message) throw new Error(res.message);
          // specific error message
          else throw new Error("Server API error"); // general message
        }
        // to update initialValues use Formik enableReinitialize @see https://github.com/jaredpalmer/formik/issues/1453
        setUserData(res);
        // setInitialValues({
        //   firstname: res.firstname,
        //   lastname: res.lastname,
        //   email: res.email,
        //   mobilephone: res.mobilephone,
        //   role: MemberRoles.ROLE_DEFAULT,
        // });
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [siteid, getToken]);

  return (
    <>
      {!isSubmissionCompleted && (
        <Container maxWidth="lg">
          <Typography sx={styles.sectionTitle} variant="h4" component="h4" gutterBottom marked="center" align="center">
            {t("Sign_up_to", { siteid: siteid })}
          </Typography>

          {error && (
            <div style={{ margin: 16 }}>
              <Alert severity="error">{error}</Alert>
            </div>
          )}

          {/* <AppForm sx="form" maxWidth={"sm"}>*/}
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validateOnBlur
            validateOnChange
            onSubmit={async (values, actions) =>
              AcademyRegistrationHandle(
                setSubmissionCompleted,
                siteid,
                userData,
                setSubmissionError,
                setApplied,
                getToken,
                values,
                actions
              )
            }
          >
            {(props) => {
              const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, status } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <RegistrationMembersSelect
                      FormikSportsSelect={FormikSportsSelect}
                      site={site}
                      formik={props}
                      MemberRoles={MemberRoles}
                      sports={sports}
                      initialValues={initialValues}
                      setShowChilds={setShowChilds}
                    />
                    <Grid item xs={12}>
                      <Fade show={showChilds}>
                        {userData !== undefined && (
                          <CheckoutChildsAndSports
                            sports={site.sports}
                            childs={userData.childs}
                            checkClick={checkClick}
                          />
                        )}
                      </Fade>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        // component={TextField}
                        multiline
                        label={t("Comments")}
                        name="comments"
                        fullWidth
                        sx={styles.textField}
                        value={values.comments}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.comments && touched.comments && errors.comments}
                      />
                      <FormHelperText>{t("Write any specific question, request or condition about")}</FormHelperText>
                    </Grid>

                    {/* before actual submit display async validations (server) errors in a separate section */}
                    {status && (
                      <Grid item xs={12} sx={styles.status}>
                        {/*{console.log('status',status)}*/}
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: 14,
                            marginBottom: 8,
                          }}
                        >
                          <strong>{`The following errors occured when trying to submit the form. Please try to fix them and hit the submit button again:`}</strong>
                        </div>
                        {Object.keys(status).map((key) => (
                          <FormHelperText key={key} error={true} filled={true}>
                            - {status[key]}
                          </FormHelperText>
                        ))}
                      </Grid>
                    )}
                    <Grid item xs={12} style={{ marginTop: 24 }}>
                      <div sx={styles.buttonWrapper}>
                        <Button
                          type="submit"
                          disabled={isSubmitting || error}
                          variant="contained"
                          sx={styles.button}
                          color="secondary"
                          fullWidth
                        >
                          {t("Submit")}
                        </Button>
                        {isSubmitting && <CircularProgress disableShrink size={24} sx={styles.buttonProgress} />}
                      </div>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
          {/* </AppForm> */}
        </Container>
      )}

      {isSubmissionCompleted && !submissionError && (
        <Container maxWidth="lg" sx={styles.registerSuccess} style={{ textAlign: "center" }}>
          <div style={{ margin: 32, textAlign: "center" }}>
            <SuccessIcon sx={styles.successIcon} />
          </div>
          <Typography variant="h5" style={{ fontSize: 20, textTransform: "initial" }} gutterBottom>
            {t("thank_you_signup_academy")}
          </Typography>
          <div style={{ marginTop: 40 }}>
            <Button variant="contained" sx={styles.button} color="secondary" onClick={props.handleClose}>
              {t("Close")}
            </Button>
          </div>
        </Container>
      )}

      {/* if for some reason submit fails */}
      {submissionError && (
        <div style={{ minHeight: 300, margin: 64 }}>
          <div style={{ margin: 32 }}>
            <ErrorIcon sx={styles.errorIcon} />
          </div>
          <Typography
            variant="h3"
            style={{
              fontSize: 18,
              textTransform: "initial",
              fontWeight: "normal",
              color: "red",
              margin: 32,
            }}
          >
            An error ocurred during form submission. Please try again or contact us if the error persists.
          </Typography>
          <Button variant="contained" sx={styles.button} color="secondary" onClick={handleClickRetry}>
            Try again
          </Button>
        </div>
      )}
    </>
  );
};

export default SignupToAcademy;
