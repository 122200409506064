import { Email } from "@mui/icons-material";
import { Button, FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import useAuth from "context/AuthContext";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

// @see https://stackoverflow.com/questions/50831450/how-to-overwrite-styles-and-styles-in-material-ui-react
// @see material-ui customize

// Material UI uses this higher-order component approach, which allows theming reuse:
// define styles by returning them from a function that accepts a
// theme object with customizations

const API_URL = process.env.REACT_APP_PUBLIC_API_URL;

// Using custom error handling to avoid formik etc...
// based on https://upmostly.com/tutorials/form-validation-using-custom-react-hooks
const PasswordReset = (props) => {
  const { t } = useTranslation();
  const styles = {
    // For custom image background
    // https://stackoverflow.com/questions/51014469/using-material-uis-apis-how-do-i-set-a-background-image-in-the-body
    "@global": {
      // for full screen background
      // body: {
      //   // url background
      //   // backgroundImage: "url('https://www.slingshotsponsorship.com/wp-content/uploads/2017/03/sams-blog.jpg')",
      //   // for local image:
      //   // DISABLED backgroundImage: "url('/images/login-bg.jpg')",
      //   backgroundRepeat: "no-repeat",
      //   backgroundPosition: "center center",
      //   backgroundSize: "cover",
      //   backgroundAttachment: "fixed",
      //   height: "100%"
      // },

      // for 2 bottom left & right images
      // body: {
      //   [theme.breakpoints.up('lg')]: {
      //     backgroundImage: 'url("/images/signin-left.png"), url("/images/signin-right.png")',
      //     backgroundRepeat: 'no-repeat, no-repeat',
      //     backgroundAttachment: 'fixed, fixed',
      //     backgroundSize: '178px, 178px',
      //     backgroundPosition: 'left bottom, right bottom'
      //   },
      // },
      html: {
        height: "100%",
      },
      // "#componentWithId": {
      //   height: "100%"
      // }
    },
    paper: {
      marginTop: (theme) => theme.spacing(6),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: (theme) => `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: (theme) => theme.spacing(1),
    },
    submit: {
      marginTop: (theme) => theme.spacing(1),
      padding: (theme) => theme.spacing(1),
      textTransform: "initial",
    },
    loginLink: {
      fontSize: 18,
      color: (theme) => theme.palette.secondary.main,
      margin: (theme) => theme.spacing(1),
      textDecoration: "none",
    },
    accountIcon: {
      fontSize: (theme) => theme.spacing(8),
    },
  };
  const { resetPassword } = useAuth();

  const [values, setValues] = useState({ email: "" }); // form values e.g. values.email
  const [errors, setErrors] = useState({}); // form errors e.g. errors.email
  const [isSubmitting, setIsSubmitting] = useState(false); // flag
  const [touched, setTouched] = useState({}); // touched status for every form field e.g. touched.email
  const [emailSent, setEmailSent] = useState(false);

  // rerun validation if values changed
  useEffect(() => {
    setErrors(validate(values));
  }, [values]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(values);
    setErrors(errors);
    if (Object.keys(errors).length) return false;

    // check if email exists in our user database
    const emailFound = await isValueAvailableAsync("email", values.email);
    if (!emailFound) {
      setErrors({
        ...errors,
        email: `The email you have provided does not exists in our member database, so the recovery link cannot be sent`,
      });
      return false;
    }

    setIsSubmitting(true);

    resetPassword(values.email)
      .then((res) => {
        setIsSubmitting(false);
        setEmailSent(true);
      })
      .catch((err) => {
        setIsSubmitting(false);
        // console.log(err);
        alert(err);
      });
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setTouched({ ...touched, [event.target.name]: true });
  };

  const handleBlur = (event) => {
    // set touched only if has value (to avoid error display when immediately press the return to login)
    if (event.target.value.length) setTouched({ ...touched, [event.target.name]: true });
  };

  const handleResendClick = (event) => {
    event.preventDefault();
    setEmailSent(false);
  };

  const validate = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = t("Email address is required");
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = t("Email address is invalid");
    }
    return errors;
  };

  async function isValueAvailableAsync(field, value) {
    try {
      let response = await fetch(`${API_URL}/user/exists/field/${field}/value/${value}`);
      response = await response.json();
      return response.found;
    } catch (error) {
      return false;
    }
  }

  return (
    <>
      <Paper sx={styles.paper} elevation={0}>
        <Grid container maxwidth="sm" alignItems="center" style={{ marginTop: 24 }} justifyContent="center">
          <Grid item xs={12}>
            <div style={{ textAlign: "center", fontSize: 18 }}>{t("Recover your password")}</div>
          </Grid>
        </Grid>
        {!emailSent && (
          <form sx={styles.form} onSubmit={handleFormSubmit}>
            <FormControl margin="normal" required fullWidth>
              <TextField
                label={t("send_recovery_link")}
                id="email"
                name="email"
                // autoComplete="true"
                autoFocus
                value={values.email}
                placeholder={t("Enter email...")}
                variant="outlined"
                onBlur={handleBlur}
                required
                error={touched.email && errors && errors.email}
                helperText={touched.email && errors && errors.email}
                onChange={handleChange}
              />
            </FormControl>

            {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />*/}
            <Button
              type="submit"
              value="SUBMIT"
              // fullWidth
              variant="contained"
              color="secondary"
              size="large"
              sx={styles.submit}
              disabled={Boolean(Object.keys(errors).length || !(values.email && values.email.length))}
            >
              {t("Send recovery link")}
            </Button>
          </form>
        )}

        {isSubmitting && ""}

        {emailSent && (
          <>
            <Typography variant="subtitle1" style={{ marginBottom: 32, marginTop: 32 }}>
              {t("send_email_link_finish")}
            </Typography>
            <Typography variant="h6" gutterBottom style={{ textTransform: "lowercase" }}>
              {values.email}
            </Typography>
            <Email style={{ fontSize: 64 }} />
          </>
        )}

        <Grid container maxwidth="sm" justifycontent="center" style={{ marginTop: 24 }} justifyContent="center">
          <Grid item xs={12} md={6}>
            <div style={{ textAlign: "center" }}>
              <RouterLink to="/login" sx={styles.loginLink} style={{ fontSize: 16 }}>
                {t("Return to login")}
              </RouterLink>
            </div>
          </Grid>
          {emailSent && (
            <Grid item xs={12} md={6}>
              <div style={{ textAlign: "center" }}>
                <RouterLink
                  to="/password-reset"
                  onClick={handleResendClick}
                  sx={styles.loginLink}
                  style={{ fontSize: 16 }}
                >
                  Resend email
                </RouterLink>
              </div>
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
};

// withStyles is the higher order component that maps the defined
// classnames to a property 'styles' on the passed in Component
// export default withStyles(styles)(Login);

// multiple hoc export
export default PasswordReset;
