

const calculatePrice = ({ price, childsCount, tempAmount }) => {

  if (price.billing_scheme === 'per_unit') {
    tempAmount =childsCount*price.unit_amount;
  }
  if (price.tiers_mode === 'volume') {
    let amount = 0;
    let previousUpTo = 0;
    for (const tier of price.tiers) {
      if (childsCount > previousUpTo && childsCount <= tier.up_to && tier.up_to!==null) {
       
        if (tier.unit_amount !== null) {
          amount = childsCount * tier.unit_amount;
        }
        if (tier.flat_amount !== null) {
          amount += tier.flat_amount;
        }
        break;
      } else if (tier.up_to===null) {
        if (tier.unit_amount !== null) {
          amount = childsCount * tier.unit_amount;
        }
        if (tier.flat_amount !== null) {
          amount += tier.flat_amount;
        }
      }
      previousUpTo = tier.up_to;
    }
    tempAmount = amount;
  }
  if (price.tiers_mode === 'graduated') {
    let amount = 0;
    let previousUpTo = 0;
    for (const tier of price.tiers) {
      if (childsCount > tier.up_to && tier.up_to!==null) {
        const diff = tier.up_to - previousUpTo;
        amount += (tier.flat_amount + tier.unit_amount)*diff;
      } else if (childsCount === tier.up_to) {
        const diff = tier.up_to - previousUpTo;
        amount += (tier.flat_amount + tier.unit_amount)*diff;
        break;
      } else if (childsCount < tier.up_to && tier.up_to!==null) {
        const diff = childsCount - previousUpTo;
        amount += (tier.flat_amount + tier.unit_amount)*diff;
        break;
      } else if (tier.up_to===null) {
          const diff = childsCount-previousUpTo;
          amount += (tier.flat_amount + tier.unit_amount)*diff;
      }
      previousUpTo = tier.up_to;
    }
    tempAmount = amount;
  }
  return tempAmount;
};

const showSubscriptionsCalculations = (price, t) => {
  if (price.billing_scheme === 'per_unit') {
    //  ${price.unit_amount/100}
    return [t("Price per member is {{price_calc}} {{currency}}", { price_calc: price.unit_amount/100, currency:price.currency})];
  }
  if (price.tiers_mode === 'graduated') {
    return price.tiers.map(tier =>
      t("{{up}} {{label}} costs {{amount}} {{flat_amount}} {{currency}}", {
        up: tier.up_to===null?t('Next'):t('Next up to ')+tier.up_to,
        label: price.product.unit_label!==null?price.product.unit_label:'',
        amount: tier.unit_amount/100,
        flat_amount: tier.flat_amount!==null?t('Plus ')+tier.flat_amount/100:'',
        currency: price.currency
      }))
  }
  if (price.tiers_mode === 'volume') {
    return price.tiers.map(tier =>
      t("{{up}} cost {{unit_amount}} per {{label}} {{flat_amount}} {{currency}}", {
        up: tier.up_to===null?t('More'):t('Up to ')+tier.up_to,
        unit_amount: tier.unit_amount/100,
        label: price.product.unit_label,
        flat_amount: tier.flat_amount!==null?t('Plus ')+tier.flat_amount/100:'',
        currency: price.currency
      })) 
      // `${tier.up_to===null?'More':'Up to '+tier.up_to} cost 
      // ${tier.unit_amount/100} per ${price.product.unit_label} ${tier.flat_amount!==null?'Plus '+tier.flat_amount/100:''} (${price.currency})`);
  }
}

export { calculatePrice, showSubscriptionsCalculations };
