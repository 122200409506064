import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";


const SearchSubHeader = (props) => {
  const { result, filters, setFilters, orderOptions, handleChangeOrder } = props;
  const { t } = useTranslation();
  const styles = {
    root: {
      width: "100%",
      display: "flex",
      // padding: (theme) => theme.spacing(2),
      marginTop:(theme) => theme.spacing(7),
      // paddingTop:(theme) => theme.spacing(2),
      // paddingBottom:(theme) => theme.spacing(1),
      paddingLeft:(theme) => theme.spacing(2),
      // paddingRight:(theme) => theme.spacing(1),
      // flexWrap: "nowrap",
    },
    form: {
      marginRight:(theme) => theme.spacing(2),
    },
    button: {
      // textTransform: "initial",
      marginRight:(theme) => theme.spacing(1),
    },    
  }

  // const handleChange = event => {
  //   setFilters({
  //     ...filters,
  //     [event.target.name]: event.target.value
  //   });
  // }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event) => {
    setAnchorEl(null);
    // console.log(event.currentTarget.dataset)
    setFilters({
      ...filters,
      orderBy: event.currentTarget.dataset.field,
      orderDirection: event.currentTarget.dataset.direction,
    });
    // console.log('field',event.currentTarget.dataset.field, 'direction',event.currentTarget.dataset.direction)
    handleChangeOrder({
      orderBy: event.currentTarget.dataset.field,
      orderDirection: event.currentTarget.dataset.direction,
    });
  };

  return (
    <Grid container sx={styles.root} spacing={1} alignItems="center" justifyContent="space-between">
      <div>
        <Typography noWrap>{t("clubSearchReasults", { num: result ? result.length : 0 })}</Typography>
      </div>

      <Button
        sx={styles.button}
        endIcon={<ArrowDropDownIcon />}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Typography component="span" noWrap>
          {t("order_by", { measure1: filters.orderBy, measure2: filters.orderDirection })}
        </Typography>
      </Button>
      <Menu id="order-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {orderOptions &&
          orderOptions.map((option) => (
            <MenuItem
              onClick={handleMenuItemClick}
              key={option.value}
              data-field={option.field}
              data-direction={option.direction}
            >
              {t("ordery_by_options", { measure: option.text })}
            </MenuItem>
          ))}
      </Menu>

      {/* <form sx={styles.form}>

          <FormControl sx={styles.formControl}>
            
            <Select
              fullWidth
              sx={styles.filter}
              value={`${filters.orderBy}_${filters.orderDirection}`}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                name: 'orderBy',
                id: 'orderBy',
              }}
            >
            {
              orderOptions &&
                orderOptions.map( option =>(       
                <MenuItem value={option.value} key={option.value} >Order by {option.text}</MenuItem>
              ))
            }
            </Select>
          </FormControl> 
    </form> */}
    </Grid>
  );
};

export default SearchSubHeader;
