import { useMutation } from "@apollo/client";
import { Avatar, Chip, Grid, List, ListItem, ListItemAvatar, Stack, Typography } from "@mui/material";
import SuccessComponent from "components/common/SuccessComponent";
import ErrorComponent from "components/error-handling/ErrorComponent";
import { CHILD_CREATE_REGISTRATION, getRegistrationStatus } from "graphql/createChildRegistration";
import { CHILD_QUERY } from "graphql/getChild";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProfileHelper from "utils/ProfileHelper";
import AddChildButton from "./AddChildButton";

const SiteProfileItem = (props) => {
  // TODO: fetch sports of site for default season
  const { profile, child } = props;
  const styles = {
    profileCaption: {
      color: (theme) => theme.palette.primary.main,
      textTransform: "uppercase",
    },
  };
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const checkForRegistrations = (child, profile, createChildRegistration) => {
    if (child.child_registrations?.length !== 0) {
      const [registration] = child.child_registrations.filter(
        (registration) => registration.siteid === profile.siteid && registration.status !== 1
      );
      if (registration !== undefined) {
        return (
          <Stack
            sx={{
              maxWidth: 320,
              direction: { sm: "column", md: "row" },
              spacing: { sm: 1, md: 2 },
              // mt: 3,
            }}
          >
            <Typography variant="body2" gutterBottom>
              {t("Registration is requested for sport")}
            </Typography>
            {registration.data.map((sport) => (
              <Chip label={sport.name} color="primary" size="medium" />
            ))}
            <Typography variant="body2" gutterBottom>
              {t("and is")}
            </Typography>
            <Chip label={getRegistrationStatus(registration.status).label} color="primary" />
          </Stack>
        );
      }
    }
    return <AddChildButton profile={profile} child={child} registrationMutation={createChildRegistration} />;
  };

  const [createChildRegistration, { data, loading, error }] = useMutation(CHILD_CREATE_REGISTRATION, {
    refetchQueries: [
      {
        query: CHILD_QUERY,
        variables: { id: child.id },
      },
    ],
    onCompleted(data) {
      setIsSuccess(true);
      setTimeout(() => setIsSuccess(false), 5000);
    },
    onError() {
      setIsError(true);
      setTimeout(() => setIsError(false), 8000);
    },
  });
  if (loading) return <div>{t("Fetching")}</div>;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <List sx={{ width: "100%", maxWidth: 360 }}>
            <ListItem>
              <ListItemAvatar>
                {" "}
                <Avatar src={ProfileHelper.getSiteLogo(profile)} />
              </ListItemAvatar>
              <Typography variant="h6" sx={styles.profileCaption}>
                {`${profile.site.name}`}
              </Typography>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={4}>
          {checkForRegistrations(child, profile, createChildRegistration)}
        </Grid>
      </Grid>
      <SuccessComponent open={isSuccess} type="success" message={t("Child Added")} />
      {error && <ErrorComponent open={isError} error={error} />}
    </>
  );
};

export default SiteProfileItem;
