import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";
import SiteCardLogo from "./SiteCardLogo";
import SiteSports from "./SiteSports";
// import CardCarousel from './CardCarousel';
// import Paper from './Paper';
// import NavigationIcon from '@mui/material/Search';
// import Divider from '@mui/material/Divider';
// import Button from './Button';
import CloseIcon from "@mui/icons-material/Cancel";
import LocationIcon from "@mui/icons-material/LocationOn";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/system/Unstable_Grid/Grid";
import SiteHelper from "../utils/SiteHelper";

const CloseButton = (props) => {
  return (
    <IconButton
      onClick={props.handleClickClose}
      color="primary"
      sx={{
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 1,
        padding: 1,
        color: "white",
      }}
      aria-label="close info window"
      size="large"
    >
      <CloseIcon />
    </IconButton>
  );
};

const SiteCard = (props) => {
  const styles = {
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    button: {
      margin: (theme) => theme.spacing(2),
    },
    card: {
      textAlign: "left",
      maxWidth: 345,
      // position: "relative",
      // Provide some spacing between cards
      margin: 0,

      // border: "1px solid #dedede",
      // Use flex layout with column direction for components in the card
      // (CardContent and CardActions)
      display: "flex",
      flexDirection: "column",

      // Justify the content so that CardContent will always be at the top of the card,
      // and CardActions will be at the bottom
      justifyContent: "space-between",
    },
    cardContent: {
      padding: 2,
      color: "black",
    },
    cardLink: {
      textDecoration: "none",

      "&:focus, &:hover, &:visited, &:link, &:active": {
        textDecoration: "none",
      },
    },
    cardViewButton: {
      // color: "#673ab7"
    },
    cardActions: {
      padding: 0,
    },
    buttonIcon: {
      marginRight: (theme) => theme.spacing(1),
    },
    city: {
      textTransform: "uppercase",
    },
    region: {
      textTransform: "capitalize",
    },
    sport: {
      marginRight: (theme) => theme.spacing(0.5),
    },
    wrapIcon: {
      verticalAlign: "middle",
      display: "inline-flex",
    },
    locationIcon: {
      color: "#fb5f53",
    },
    locationDetails: {
      fontWeight: 100,
      fontSize: 14,
      color: "grey",
    },
    sportsTitle: {
      fontWeight: 100,
      fontSize: 14,
      color: "grey",
      marginLeft: 4,
    },
    siteLogoWrap: {},

    name: {
      fontSize: 16,
    },
    distance: {
      marginRight: 10,
    },
    cardPin: {
      cursor: "default",
      display: "block",
      height: 14,
      left: "calc(50% - 24px)",
      overflow: "hidden",
      position: "absolute",
      top: "100%",
      width: 38,
      zIndex: -1,
      "&:before": {
        background: "#fff",
        boxShadow: "0 2px 4px rgba(195,202,206,.5)",
        content: '""', // content must be wrapped in quotes https://stackoverflow.com/questions/53349900/pseudo-selector-in-material-ui-withstyles-not-rendering-to-page
        height: 33.84,
        left: "calc(50% - 16.92px)",
        position: "absolute",
        top: -16.92,
        transform: "rotate(45deg)",
        width: 33.84,
      },
    },
  };
  const { site, /* compact, */ handleClickClose } = props;

  return (
    <>
      <Card sx={styles.card} elevation={0}>
        {/* 
                using Link as component see 3rd answer: 
                https://stackoverflow.com/questions/37669391/how-to-get-rid-of-underline-for-link-component-of-react-router 
              */}
        <SiteCardLogo site={site} size={50} />
        <CloseButton handleClickClose={handleClickClose} />
        <CardActionArea sx={styles.cardLink} component={Link} to={`/academy/${site.siteid}`}>
          <CardMedia
            component="img"
            alt={site.name}
            height="80px"
            image={SiteHelper.getSiteDefaultImage({ site })}
            title={site.name}
          />
          {/*
                  Displays site profile gallery as carousel (disabled for now for speed purposes, if enabled then CardMedia must be disabled) 
                  <CardCarousel gallery = {site.gallery ? site.gallery : null } />
                */}
          <CardContent sx={styles.cardContent}>
            <Typography sx={styles.name} gutterBottom variant="h5" component="h2" noWrap>
              {site.name ? site.name : site.siteid}
            </Typography>

            {/*<Rating value={4} readOnly />*/}
            {/* <Typography gutterBottom component="p" color="textSecondary">{site.description}</Typography> */}
            <Typography
              sx={styles.cityRegion}
              gutterBottom
              variant="subtitle2"
              component="h6"
              color="textSecondary"
              noWrap
            >
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <LocationIcon sx={styles.locationIcon} />
                </Grid>
                <Grid item sx={styles.locationDetails}>
                  <span sx={styles.city}>{site.cityDetails && site.cityDetails.cities_name}</span>
                  <span sx={styles.region}>{site.regionDetails && `, ${site.regionDetails.prefectures_name}`}</span>
                </Grid>
              </Grid>
            </Typography>
            {site.distance && (
              <Typography component="p" noWrap gutterBottom>
                <span sx={styles.distance}>{`${site.distance.toFixed(1)} km away`}</span>
              </Typography>
            )}

            <SiteSports sports={site.sports} />
          </CardContent>
        </CardActionArea>
      </Card>
      <span sx={styles.cardPin} />
    </>
  );
};

export default SiteCard;
