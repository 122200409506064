import React, { useEffect, useState } from 'react';

const API_URL = process.env.REACT_APP_PUBLIC_API_URL;
// const DOMAIN_NAME = process.env.REACT_APP_SITE_DOMAIN_NAME
// const DOMAIN_EXT = process.env.REACT_APP_SITE_DOMAIN_EXT
// const DOMAIN_EXT_DEV = process.env.REACT_APP_SITE_DOMAIN_EXT_DEV


const AppDataContext = React.createContext();

// helper
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
const compareStrings = (a, b) => {
  var nameA = a.toUpperCase(); // ignore upper and lowercase
  var nameB = b.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
}

// helper
function compareNumbers(a, b) {
  return a - b;
}

// https://developerhandbook.com/react/build-a-complete-property-listings-page-with-react/
// https://dev.to/mongopark/learn-react-hooks-and-context-api-by-building-a-recipe-search-app-2-1g3o
// Also we can create a special context for search page
const AppDataProvider = (props) => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  // const [sites, setSites] = useState();
  // const [regions, setRegions] = useState();
  const [sports, setSports] = useState();
  // const auth = new AuthService(process.env.REACT_APP_API_URL);
  // const [filter, setFilter] = useState();

  const url = {
    sports : `${API_URL}/sports/`,
    regions : `${API_URL}/regions/`,
    sites : `${API_URL}/sites/`,
    // memberAdmin: '//akadim.gr/member-admin',
    adminLanding: '/explore',
    academySignup: '/register',
    // memberSignup: '//akadim.gr/member-signup',
  }

   // fetch all sports 
  const fetchSports = () => {
    setLoading(true);
    fetch(url.sports)
      .then(data => data.json()) 
      .then(data => {
        // set state in here
        setSports(data);
        setLoading(false);  
      })
      .catch(error => {setError(error); setLoading(false); })  
  }


  useEffect(() => {  
    // fetchData();
    fetchSports(); // sports are loaded from start
  }, []);

  // returns sport name for the provided (int) sportid
  const getSportName = (sportid) => {
    let name;
    sports.forEach(sport => {
      if(sport.sportid === sportid) {
        name = sport.translation
      }
    })
    return name;
  }

  // apply filters and returns the filtered result
  const applyFilter = (data, filters) => {
    if(!data || !data.length)
      return;
    
    const { sportid, regionid, siteid, orderBy, orderDirection, limit } = filters
    // SOS: in javascript arrays can't be copied with = because arrays are reference values, so we must copy them with splice or es6 ... . https://www.samanthaming.com/tidbits/35-es6-way-to-clone-an-array
    let result = [...data]; 
    // console.log(filters, result)
    if (sportid) {
      // each site has a nested sports object eg sports: {name: "football", sportid: 1, ....}
      result = result.filter(item => item.sports.some( sport => sport.sportid === sportid ))
    }
    if (regionid) {
      result = result.filter(item => item.region === regionid)
    }
    if (siteid) {
      result = result.filter(item => item.siteid === siteid)
    }
    if (orderDirection) {
      if (orderDirection === 'asc') {
        if(orderBy==='created_at')
          result = result.sort((a, b) => compareNumbers(a.created_at, b.created_at))
        else if(orderBy==='distance')
          result = result.sort((a, b) => compareNumbers(a.distance, b.distance))
        else
          result = result.sort((a, b) => compareStrings(a.name, b.name))
      }
      if (orderDirection === 'desc') {
        if(orderBy==='created_at')
          result = result.sort((a, b) => -(compareNumbers(a.created_at, b.created_at)))
        else if(orderBy==='distance')
          result = result.sort((a, b) => -(compareNumbers(a.distance, b.distance)))
        else
          result = result.sort((a, b) => -(compareStrings(a.name, b.name)))
      }
    }
    if(limit)
      return result.splice(0,limit)
    else
      return result
  }

  // const getSiteById = (siteid) => {
  //   siteid = siteid.toLowerCase(); 
  //   const result = sites.filter(item => item.siteid == siteid);

  //   return result[0];
  // }



  // SOS the 'value' prop is required
  return (
    <AppDataContext.Provider value={{
      loading,
      // sites,
      sports,
      // regions,
      applyFilter,
      // auth, // object
      getSportName,
      // getSiteById, 
    }}>
      {props.children}
    </AppDataContext.Provider>
  )
}

export { AppDataContext, AppDataProvider };

