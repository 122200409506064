import { Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <Box
      // component="footer"
      sx={{
        display: "flex",
        flexFlow: "column wrap",
        alignItems: "center",
        backgroundColor: "#f8f8f8", // theme.palette.secondary.light,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexShrink: 2,
          margin:(theme) => theme.spacing(2)
        }}
      >
        <Typography variant="body1">{t("explain_footer")}</Typography>
      </Box>
      <Box>
        <Box>
          <a
            href="https://vimeo.com/user123066515"
            sx={{
              width: 48,
              height: 48,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: (theme) => theme.palette.warning.main,
              marginRight: (theme) => theme.spacing(1),
              "&:hover": {
                backgroundColor: (theme) => theme.palette.warning.dark,
              },
            }}
          >
            <img src="/images/vimeo.png" alt="Vimeo" sx={{ width: 24, height: 24 }} />
          </a>
        </Box>
        <Box>© 2022 FindSportClub</Box>
        <Box>
          <Box>{t("Legal")}</Box>
          <Box>
            <Link href="/terms">{t("Terms")}</Link>
            <Link href="/privacy">{t("Privacy")}</Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
