import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import featuresEL from '../locales/el/features.json';
import loginEL from '../locales/el/login.json';
import mapsEL from '../locales/el/maps.json';
import translationEL from '../locales/el/translation.json';
import featuresEN from '../locales/en/features.json';
import loginEN from '../locales/en/login.json';
import mapsEN from '../locales/en/maps.json';
import translationEN from '../locales/en/translation.json';

// the translations
const resources = {
  "en": {
    "translation": translationEN,
    "login": loginEN,
    "features": featuresEN,
    "maps": mapsEN
  },
  "el": {
    "translation": translationEL,
    "login": loginEL,
    "features": featuresEL,
    "maps": mapsEL
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;