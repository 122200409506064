import FoundingIcon from "@mui/icons-material/Cake";
import AddressIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import SportIcon from "@mui/icons-material/SportsSoccer";
import DescriptionIcon from "@mui/icons-material/Subject";
// import Button from '../../../components/Button';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import SiteLogo from "../../../components/SiteLogo";
import SiteSports from "../../../components/SiteSports";

const AcademyDetails = (props) => {
    const { site } = props
    const theme = useTheme();
    const { t } = useTranslation();
    const styles = {
        typography: {
          flexGrow: 1,
          textAlign: "left",
          paddingTop: "8px",
           fontWeight: 100
        },
        academyDetails: {
          textAlign: "left",
          marginTop: theme.spacing(1),
          padding: theme.spacing(1),
        },
        button: {
          margin: theme.spacing(1),
        },
        buttonIcon: {
          marginRight: theme.spacing(1),
        },
        socialLink: {
          marginRight: theme.spacing(4),
        },
        socialIcon: {
          fontSize: "48px",
        },
        detailItemWithIcon: {
          display: "flex",
          flexDirection: "row",
          paddingLeft: theme.spacing(4),
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
            paddingLeft: 0,
          },
        },
        detailItemTextContainer: {
          paddingLeft: theme.spacing(4),
          [theme.breakpoints.down("lg")]: {
            paddingLeft: 0,
            paddingTop: theme.spacing(1),
          },
        },
        detailItemIcon: {
          display: "inline-block",
        },
        socialBox: {
          background: "#fff",
          padding: "24px",
          borderRadius: "5px",
          border: "1px solid #e0e0e0",
          boxShadow: "0 2px 4px 0 rgba(0,0,0,.08)",
        },
        city: {
          textTransform: "capitalize",
        },
        region: {
          textTransform: "capitalize",
        },
        notAvailable: {
          color: "grey",
        }
      };    
  return (
    <>
      <Grid style={styles.academyDetails} item xs={12}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <SiteLogo site={site} size={70} />
          </Grid>
          <Grid item>
            <Typography
              sx={styles.typography}
              variant="h4"
            >
              {site.name ? site.name : site.siteid}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid style={styles.academyDetails} item xs={12}>
        <Box sx={styles.detailItemWithIcon}>
          <DescriptionIcon sx={styles.detailItemIcon} color="secondary" />
          <Grid container direction="column" sx={styles.detailItemTextContainer}>
            <Typography sx={styles.typography} component="h3" variant="subtitle2" gutterBottom>
              {t("Description")}
            </Typography>
            <Typography sx={styles.typography} variant="body1" gutterBottom style={{ textTransform: "initial" }}>
              {site.description}
            </Typography>
          </Grid>
        </Box>
      </Grid>

      <Grid style={styles.academyDetails} item xs={12}>
        <Box sx={styles.detailItemWithIcon}>
          <SportIcon sx={styles.detailItemIcon} color="secondary" />
          <Grid container direction="column" sx={styles.detailItemTextContainer}>
            <Typography sx={styles.typography} component="h3" variant="subtitle2" gutterBottom>
              {t("Sports")}
            </Typography>
            <div>
              <SiteSports sports={site.sports} />
            </div>
          </Grid>
        </Box>
      </Grid>

      <Grid style={styles.academyDetails} item xs={12}>
        <Box sx={styles.detailItemWithIcon}>
          <AddressIcon sx={styles.detailItemIcon} color="secondary" />
          <Grid container direction="column" sx={styles.detailItemTextContainer}>
            <Typography sx={styles.typography} component="h3" variant="subtitle2" gutterBottom>
              {t("Address")}
            </Typography>
            <Typography sx={styles.typography} variant="body1" gutterBottom style={{ textTransform: "initial" }}>
              {site.street}
            </Typography>
            <Typography sx={styles.typography} component="h3" variant="subtitle2" gutterBottom>
              {t("Postal code")}
            </Typography>
            <Typography sx={styles.typography} variant="body1" gutterBottom style={{ textTransform: "initial" }}>
              {site.postal_code ? site.postal_code : <span sx={styles.notAvailable}>N/A</span>}
            </Typography>
            <Typography sx={styles.typography} component="h3" variant="subtitle2" gutterBottom>
              {t("City")}
            </Typography>
          </Grid>
        </Box>
      </Grid>

      <Grid style={styles.academyDetails} item xs={12}>
        <Box sx={styles.detailItemWithIcon}>
          <PhoneIcon sx={styles.detailItemIcon} color="secondary" />
          <Grid container direction="column" sx={styles.detailItemTextContainer}>
            <Typography
              sx={styles.typography}
              component="h3"
              variant="subtitle2"
              gutterBottom
              style={{ textTransform: "initial" }}
            >
              {t("Phone numbers")}
            </Typography>
            <Typography sx={styles.typography} variant="body1" gutterBottom style={{ textTransform: "initial" }}>
              {site.phone}
            </Typography>
            <Typography sx={styles.typography} variant="body1" gutterBottom style={{ textTransform: "initial" }}>
              {site.phone2}
            </Typography>
            <Typography sx={styles.typography} variant="body1" gutterBottom style={{ textTransform: "initial" }}>
              {site.mobilephone}
            </Typography>
            <Typography sx={styles.typography} variant="body1" gutterBottom style={{ textTransform: "initial" }}>
              {site.mobilephone2}
            </Typography>
          </Grid>
        </Box>
      </Grid>

      <Grid style={styles.academyDetails} item xs={12}>
        <Box sx={styles.detailItemWithIcon}>
          <PersonIcon sx={styles.detailItemIcon} color="secondary" />
          <Grid container direction="column" sx={styles.detailItemTextContainer}>
            <Typography
              sx={styles.typography}
              component="h3"
              variant="subtitle2"
              gutterBottom
              style={{ textTransform: "initial" }}
            >
              {t("Administrator")}
            </Typography>
            <Typography sx={styles.typography} variant="body1" gutterBottom style={{ textTransform: "initial" }}>
              {site.administrator}
            </Typography>
          </Grid>
        </Box>
      </Grid>

      <Grid style={styles.academyDetails} item xs={12}>
        <Box sx={styles.detailItemWithIcon}>
          <FoundingIcon sx={styles.detailItemIcon} color="secondary" />
          <Grid container direction="column" sx={styles.detailItemTextContainer}>
            <Typography
              sx={styles.typography}
              component="h3"
              variant="subtitle2"
              gutterBottom
              style={{ textTransform: "initial" }}
            >
              {t("Founding info")}
            </Typography>
            <Typography sx={styles.typography} variant="body1" gutterBottom style={{ textTransform: "initial" }}>
              {site.foundingDate && (
                <span>
                  Founded on <Moment format="DD/MM/YYYY">{site.foundingDate}</Moment>
                </span>
              )}
              {site.foundingDate && site.foundingLocation && <span> in {site.foundingLocation}</span>}
              {!site.foundingDate && site.foundingLocation && <span>Founded in {site.foundingLocation}</span>}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default AcademyDetails;
