import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import useAuth from "context/AuthContext";
import React from "react";
import { useNavigate } from "react-router";

// rendered in header only if user is logged in
const HeaderUserMenu = (props) => {
  const { setIsLoggedIn } = props;
  // const { auth } = useContext(AppDataContext);
  const { getProfile, logout } = useAuth();
  const navigate = useNavigate();

  const profile = getProfile();
  // console.log('logged',auth.loggedIn(),'profile',profile)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // const handleLogout = event => {

  // };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = () => {
    navigate("/home/profile");
  };

  const handleClickDashboard = () => {
    navigate("/home", {replace: true});
  };

  const handleClickLogout = () => {
    logout();
    setIsLoggedIn(false);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        size="large">
        <Avatar>{profile.firstname.substr(0, 1) + profile.lastname.substr(0, 1)}</Avatar>
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disabled={true} onClick={handleClickProfile}>
          <Typography variant="subtitle1">{`${profile.lastname} ${profile.firstname}`}</Typography>
        </MenuItem>
        <MenuItem onClick={handleClickProfile}>Profile</MenuItem>
        <MenuItem onClick={handleClickDashboard}>Dashboard</MenuItem>
        <MenuItem onClick={handleClickLogout}>Logout</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default HeaderUserMenu;
