import { Paper } from "@mui/material";

const TutorialItem = (props) => {
  const styles = {
    root: {
      // flexGrow: 1,
    },
    paper: {
      marginBottom: (theme) => theme.spacing(5),
      padding:(theme) => theme.spacing(2),
      textAlign: "center",
      color:(theme) => theme.palette.text.secondary,
      background: "transparent",
    },
    title: {
      fontSize: 26,
      padding: "25px 10px",
      textTransform: "initial",
      color: "inherit",
    },    
  }
  return (
    <>
        <Paper sx={styles.paper} elevation={0}>
          <div style={{
            padding: "54.98% 0 0 0",
            position: "relative"
          }}>
            <iframe
              src={`https://player.vimeo.com/video/${props.video.src}`}
              width="100%"
              height="100%"
              style={{
                position:"absolute",
                top:0,
                left:0,
                width:"100%",
                height:"100%",
              }}
              title={props.video.text}
              allow="autoplay; fullscreen picture-in-picture"
              allowFullScreen
            ></iframe>
            </div>
        </Paper>
    </>
  );
};
export default TutorialItem;
