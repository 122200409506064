import { useTheme } from "@emotion/react";
import { AccountCircle } from "@mui/icons-material";
import { default as UserIcon } from "@mui/icons-material/AccountCircle";
import ExitIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import {
  AppBar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import useAuth from "context/AuthContext";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

// sidebar width
const drawerWidth = 280;

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  // for overrides
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    // paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    // overflowX: "hidden",
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // width: 0,
    // // width:  theme.spacing.unit * 7, // uncomment to disable auto close drawer on screen width change
    // [theme.breakpoints.up("sm")]: {
    //   width: 0,
    //   // width: theme.spacing.unit * 9,  // uncomment to enable mini-variant (display only icons in drawer)
    // },
  },
});

const MyAppBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  // https://stackoverflow.com/questions/56484686/how-do-i-avoid-function-components-cannot-be-given-refs-when-using-react-route
  const NavLinkFix = React.forwardRef((props, ref) => <NavLink innerref={ref} {...props} />);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { open, handleDrawerToggle, searchClose } = props
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };



  const handleLogout = () => {
    logout();
    navigate("/login", { replace: true });
  };

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = styles(theme);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // Desktop menu
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <NavLinkFix to="/home/profile" style={{ textDecoration: "none", outlineStyle: "none" }}>
        <MenuItem button>
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          <ListItemText>{t("General Profile")}</ListItemText>
        </MenuItem>
      </NavLinkFix>
      <MenuItem button onClick={handleLogout}>
        <ListItemIcon>
          <ExitIcon />
        </ListItemIcon>
        <ListItemText>{t("Logout")}</ListItemText>
      </MenuItem>
    </Menu>
  );

  // Mobile Menu
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem>
          <IconButton color="inherit">
            <Badge badgeContent={11} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem> */}
      <NavLinkFix to="/home/profile" style={{ textDecoration: "none", outlineStyle: "none" }}>
        <MenuItem button>
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          <ListItemText>{t("General Profile")}</ListItemText>
        </MenuItem>
      </NavLinkFix>

      <MenuItem button onClick={handleLogout}>
        <ListItemIcon>
          <ExitIcon />
        </ListItemIcon>
        <ListItemText> {t("Logout")}</ListItemText>
      </MenuItem>

      {/*        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem> */}
    </Menu>
  );

  // Lang menu
  // const renderLangMenu = (
  //   <Menu
  //     anchorEl={anchorEl}
  //     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     open={isMenuOpen}
  //     onClose={this.handleMenuClose}
  //   >
  //     <NavLink exact to="/home/profile" style={{ textDecoration: "none", outlineStyle: "none" }}>
  //       <MenuItem button><ListItemIcon><UserIcon/></ListItemIcon><ListItemText>Profile</ListItemText></MenuItem>
  //     </NavLink>
  //     <MenuItem button onClick={this.handleLogout}><ListItemIcon><ExitIcon/></ListItemIcon><ListItemText>Logout</ListItemText></MenuItem>
  //   </Menu>
  // );

  return (
    <div>
      <AppBar
        position="fixed"
        style={classes.appBar}
        sx={{
          // width: searchClose?{ md: `calc(100% - ${drawerWidth}px)` }:"100%",
          // ml: searchClose?{ md: `${drawerWidth}px` }:"",
        }}
      >
        <Toolbar  style={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: open?'none':"block" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ display: { sm: 'none', md: 'block'} }}>
            {t("Dashboard")}
          </Typography>

          <div style={classes.grow} />

          <Box component='div' sx={{ display: { sm: 'none', md: 'block' }  }}>
            <IconButton
              aria-owns={isMenuOpen ? "material-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              size="large"
            >
              <AccountCircle />
            </IconButton>
          </Box>

          <Box component='div' sx={{ display: { sm: 'block', md: 'none' }  }}>
            <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit" size="large">
              <MoreIcon />
            </IconButton>
          </Box>

        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMobileMenu}
    </div>
  );
};

export default MyAppBar;
