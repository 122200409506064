import { Loader } from "@googlemaps/js-api-loader";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from '@mui/material/utils';
import parse from "autosuggest-highlight/parse";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { geocodeByPlaceID } from "utils/Geocode";

// declare google maps rest api services and check whether they are loaded
const autocompleteService = { current: null };
const geocoder = { current: null };
const loader = new Loader({
  apiKey: process.env.REACT_APP_GMAPS_API_KEY,
  id: "googleMapsApi",
  // version: "weekly",
  libraries: ["places","geocoding"],
});
const GoogleMapsAutocomplete = (props) => {
  const { filters, setFilters } = props;
  const { t } = useTranslation();
  const [value, setValue] = React.useState(filters.address ? filters.address : null);
  const [inputValue, setInputValue] = React.useState("A");
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(
    () =>
    debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
 
    let active = true;
    loader.importLibrary('places').then(({AutocompleteService}) => {
      autocompleteService.current = new AutocompleteService();

      // if (!autocompleteService.current && window.google) {
      //   autocompleteService.current = new window.google.maps.places.AutocompleteService();
      // }
      // if (!autocompleteService.current) {
      //   return undefined;
      // }

      if (inputValue === "") {
        setOptions(value ? [value] : []);
        return undefined;
      }
      fetch({ input: inputValue }, (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      });
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  // save coordinates to filters when value changes
  React.useEffect(() => {
 
    loader.importLibrary('geocoding').then(async ({Geocoder}) => {
    // loader.load().then(async (google) => {
      try {
        if (value !== null && value.place_id !== undefined) {
          geocoder.current = new Geocoder();
          const [{ geometry }] = await geocodeByPlaceID(geocoder.current, value.place_id);
          const coordinates = {
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
          };
          setFilters({
            ...filters,
            address: value.description,
            coordinates,
          });
        }
      } catch (e) {
        console.error(e);
      }
    });

    // const callGeoCoder = async () => {
    //   try {
    //     const [{ geometry }] = await geocodeByPlaceID(geocoder.current, value.place_id);
    //     const coordinates = {
    //       lat: geometry.location.lat(),
    //       lng: geometry.location.lng(),
    //     };
    //     setFilters({
    //     ...filters,
    //     address: value.description,
    //     coordinates,
    //   });
    //   } catch (e) {
    //     console.error(e);
    //   }
    // };
    // if (value && value.place_id) {
    //   if (!geocoder.current && window.google) {
    //     geocoder.current = new window.google.maps.Geocoder();
    //   }
    //   callGeoCoder();
    // }
  }, [value]);

  return (
    <Autocomplete
      id="google-maps"
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label={t("Add a location")} />}
      renderOption={(props, option) => {
        let matches = [];
        if (option.structured_formatting.main_text_matched_substrings !== undefined) {
          matches = option.structured_formatting.main_text_matched_substrings;
        };
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: "text.secondary", mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default GoogleMapsAutocomplete;
