import { useQuery } from "@apollo/client";
import MaterialTable from "@material-table/core";
import RejectedIcon from "@mui/icons-material/Block";
import AcceptedIcon from "@mui/icons-material/Done";
import PendingIcon from "@mui/icons-material/MoreHoriz";
import { Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import useAuth from "context/AuthContext";
import { APPLICATIONS_QUERY } from "graphql/getUserRegistrations";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { AppDataContext } from "../../../context/index";

// Subcomponent
const StatusChip = (props) => {
  const { status, t } = props;
  if (status === 0)
    return (
      <Chip
        label={t("Pending")}
        icon={<PendingIcon style={{ color: "white" }} />}
        style={{ backgroundColor: "blue", color: "white" }}
      />
    );
  else if (status === 1)
    return (
      <Chip
        label={t("Accepted")}
        icon={<AcceptedIcon style={{ color: "white" }} />}
        style={{ backgroundColor: "green", color: "white" }}
      />
    );
  else if (status === 2)
    return (
      <Chip
        label={t("Rejected")}
        icon={<RejectedIcon style={{ color: "white" }} />}
        style={{ backgroundColor: "red", color: "white" }}
      />
    );
  else return "unknown";
};

// main component
const Applications = (props) => {
  const ctx = React.useContext(AppDataContext);
  const { getSportName } = ctx;
  const { t, i18n } = useTranslation();
  const { user } = useAuth()
  // TODO: find a better way to parse query before render
  const getSportNames = (data) => {
    const pdata = JSON.parse(data);
    if (!pdata.sports) return "";

    let sportIds = pdata.sports;
    let resultString = "";
    if (sportIds && sportIds.length)
      sportIds.split(",").forEach((sportid) => {
        resultString += getSportName(parseInt(sportid))[i18n.language] + ", ";
      });
    return resultString.substring(0, resultString.lastIndexOf(","));
  };

  const { loading, error, data } = useQuery(APPLICATIONS_QUERY, {
    variables: { id: user.id },
  });
  if (loading) return <div>{t("Fetching")}</div>;
  if (error) return <div>{error.message}</div>;

  return (
    <div style={{ maxWidth: "100%" }}>
      <Typography variant="h5" gutterBottom>
        {t("My signup applications")}
      </Typography>
      <Typography variant="subtitle2" style={{ marginBottom: "20px" }} color="textSecondary" gutterBottom>
        {t("Here you can view the status of your signup applications")}
      </Typography>

      <TableContainer component={Paper}>
        <MaterialTable
          columns={[
            { title: t("sport club"), field: "siteid" },
            {
              title: t("Created at"),
              field: "comments",
              render: (row) => moment.unix(row.created_at).format("DD/MM/YYYY"),
            },

            {
              title: t("Sports"),
              field: "data",
              render: (row) => getSportNames(row.data),
            },
            {
              title: t("Comments"),
              field: "comments",
            },
            {
              title: t("Status"),
              field: "status",
              render: (row) => <StatusChip t={t} status={row.status} />,
              cellStyle: {
                width: "20%",
                textAlign: "left",
              },
              headerStyle: {
                width: "20%",
                textAlign: "left",
              },
            },
          ]}
          data={data.userRegistrations}
          options={{
            toolbar: false,
            paging: false,
            actionsColumnIndex: -1,
          }}
        />
      </TableContainer>
    </div>
  );
};

export default Applications;
