import ProfileIcon from "@mui/icons-material/AssignmentInd";
import SiteProfilesIcon from "@mui/icons-material/Contacts";
// import classNames from 'classnames';
import DashboardIcon from "@mui/icons-material/Dashboard";
import SubscriptionsIcon from "@mui/icons-material/EuroSymbol";
import ApplicationsIcon from "@mui/icons-material/PersonAdd";
import ChildsIcon from "@mui/icons-material/SupervisorAccount";
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const AppDrawerMenuList = (props) => {
  const { t /*, classes */ } = useTranslation();

  // const handleClickSearch = ev => {
  //   window.location = '/search'
  // }

  return (
    <List>
      <NavLink to="/home" style={{ textDecoration: "none" }}>
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t("Overview")} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/home/subscriptions" style={{ textDecoration: "none" }}>
        <ListItemButton>
          <ListItemIcon>
            <SubscriptionsIcon />
          </ListItemIcon>
          <ListItemText primary={t("Subscriptions")} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/home/childs" style={{ textDecoration: "none" }}>
        <ListItemButton>
          <ListItemIcon>
            <ChildsIcon />
          </ListItemIcon>
          <ListItemText primary={t("Childs")} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/home/profiles" style={{ textDecoration: "none" }}>
        <ListItemButton>
          <ListItemIcon>
            <SiteProfilesIcon />
          </ListItemIcon>
          <ListItemText primary={t("Site Profiles")} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/home/profile" style={{ textDecoration: "none" }}>
        <ListItemButton>
          <ListItemIcon>
            <ProfileIcon />
          </ListItemIcon>
          <ListItemText primary={t("General Profile")} />
        </ListItemButton>
      </NavLink>
      <NavLink to={`/home/search/${props.user.id}`} style={{ textDecoration: "none" }}>
        <ListItemButton>
          <ListItemIcon>
            <ProfileIcon />
          </ListItemIcon>
          <ListItemText primary={t("Search sport clubs")} />
        </ListItemButton>
      </NavLink>
      <NavLink to={`/home/applications`} style={{ textDecoration: "none" }}>
        <ListItemButton>
          <ListItemIcon>
            <ApplicationsIcon />
          </ListItemIcon>
          <ListItemText primary={t("Signup Applications")} />
        </ListItemButton>
      </NavLink>
    </List>
  );
};

export default AppDrawerMenuList;
