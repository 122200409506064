import { useQuery } from "@apollo/client";
import Cake from "@mui/icons-material/Cake";
import LocationIcon from "@mui/icons-material/LocationOn";
import { CardContent, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import useAuth from "context/AuthContext";
import { CHILD_QUERY } from "graphql/getChild";
import { SITE_PROFILES_QUERY } from "graphql/getProfiles";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useParams } from "react-router";
import "react-sweet-progress/lib/style.css";
import ChildSiteItem from "./components/ChildSiteItem";
import SiteProfileItem from "./components/SiteProfileItem";

const Child = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const styles = {
    card: {
      // maxWidth: 400,
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    actions: {
      display: "flex",
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: (theme) =>
        theme.transitions.create("transform", {
          duration: (theme) => theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      // backgroundColor: red[500],
    },
    bigAvatar: {
      width: "70px",
      height: "80px",
    },
  };

  const { id } = useParams(); // id is child userid passed as url param
  const childData = useQuery(CHILD_QUERY, {
    variables: { id },
  });
  const siteProfiles = useQuery(SITE_PROFILES_QUERY, {
    variables: { id: user.id },
  });
  const loading = childData.loading || siteProfiles.loading;
  const errors = childData.error || siteProfiles.error;
  if (loading) return <div>{t("Fetching")}</div>;
  if (errors) return <div>{t("Error")}</div>;
  const child = childData.data.child;
  return (
    <>
      <div sx={styles.root}>
        <Typography sx={styles.dividerFullWidth} variant="h5" gutterBottom>
          {`${t("Child Profiles in clubs")}: ${child.lastname} ${child.firstname} `}
        </Typography>
        <CardContent>
          <List component="nav">
            <ListItem divider>
              <ListItemIcon>
                <Cake />
              </ListItemIcon>
              <ListItemText
                primary={t("Birth Day")}
                secondary={<Moment format="l" date={child.birth_date} />}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LocationIcon />
              </ListItemIcon>
              <ListItemText primary={`${child.city} , ${child.address}, ${child.postcode}`} secondary={t("Address")} />
            </ListItem>
          </List>
        </CardContent>
        {siteProfiles.data.siteProfiles.map((siteProfile) => {
          for (const childProfile of child.siteProfiles) {
            if (childProfile.siteid === siteProfile.siteid) {
              return (
                <div style={{ marginTop: "40px" }} key={childProfile.id}>
                  <ChildSiteItem profile={childProfile} key={childProfile.id} />
                </div>
              );
            }
          }
          return (
            <div style={{ marginTop: "40px" }} key={siteProfile.id}>
              <SiteProfileItem child={child} profile={siteProfile} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Child;
