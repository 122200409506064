import React, {useState} from 'react'
import GeoSearchFormLanding from '../../../components/GeoSearchFormLanding';


const LandingSearch = props => {

  // Just use default filters.
  // The purpose of landing search is to allow user to enter filters directly from landing page. 
  // These filters will be passed to search page which will display the results immediately and also a more complicated search form to further refine search
  const [filters, setFilters] = useState({
    sportid: '',
    regionid: '',
    siteid: '',
    coordinates: null,
    address: '',
    orderBy: 'distance',
    orderDirection: 'asc'
  });

  return (
    <GeoSearchFormLanding filters={filters} setFilters={setFilters}/>
  )
}

export default LandingSearch