import {
  Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, FormGroup
} from '@mui/material';

const styles = {
    root: {
      borderRadius: 12,
      minWidth: 256,
      maxWidth: 600,
  
      textAlign: 'center',
    },
  };

const CheckoutChilds = (props) => {
    const  classes  = styles;
    const { childs, checkClick } = props;

  return (
    <>
      <Card width="400px">
        <CardHeader title="Childs" />
        <Divider variant="middle" />
        <CardContent>
          <FormGroup>
            {childs.map((child) => (
              <FormControlLabel
                key={child.id}
                control={<Checkbox checked={child.checked} onChange={checkClick} name={child.id} />}
                label={`${child.firstname} ${child.lastname}`}
              />
            ))}
          </FormGroup>
        </CardContent>
      </Card>
    </>
  );
};

export default CheckoutChilds;
