import { useTheme } from "@emotion/react";
import SaveIcon from "@mui/icons-material/Save";
import { Button, TextField } from "@mui/material";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ProfileForm = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = {
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: (theme) => theme.spacing(3),
      marginRight: (theme) => theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: (theme) => theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: (theme) => `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    },
    avatar: {
      margin: (theme) => theme.spacing(1),
      backgroundColor: (theme) => theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: (theme) => theme.spacing(1),
    },
    textField: {
      // marginLeft: theme.spacing.unit,
      // marginRight: theme.spacing.unit,
      // width: 200,
    },
    submit: {
      marginTop: (theme) => theme.spacing(3),
    },
  };
  const { personMutation, initialState, personId } = props;

  const ajv = new Ajv({ allErrors: true });
  addFormats(ajv);

  const [eachEntry, setEachEntry] = useState(initialState);
  const { lastname, firstname, address, mobilephone, email } = eachEntry;
  const [error, setError] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let person = {
      id: personId,
      lastname: lastname,
      firstname: firstname,
      address: address,
      mobilephone,
      email,
    };
    const valid = ajv.validate(schema, person);
    if (!valid) {
      setError(ajv.errors);
      return;
    }
    const vars = { input: person };
    personMutation({ variables: vars });
  };
  const handleInputChange = (e) => {
    const data = { [e.target.name]: e.target.value };
    const valid = ajv.validate(schema, data);
    if (!valid) {
      console.log(ajv.errors);
      setError(ajv.errors);
    }
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const schema = {
    type: "object",
    required: ["firstname", "lastname", "address", "mobilephone", "email"],
    properties: {
      firstname: { type: "string", minLength: 1, maxLength: 255 },
      address: { type: "string", minLength: 1, maxLength: 500 },
      mobilephone: { type: "string", minLength: 10, maxLength: 10 },
      email: { type: "string", format: "email", maxLength: 200 },
    },
  };
  const [lastnameError] = error.filter((err) => err.instancePath === "/lastname");
  const [firstnameError] = error.filter((err) => err.instancePath === "/firstname");
  const [addressError] = error.filter((err) => err.instancePath === "/address");
  const [mobilephoneError] = error.filter((err) => err.instancePath === "/mobilephone");
  const [emailError] = error.filter((err) => err.instancePath === "/email");

  return (
    <>
      <form sx={styles.form} onSubmit={(e) => handleSubmit(e)}>
        <TextField
          name="lastname"
          id="lastname"
          label={t("Lastname")}
          sx={styles.textField}
          fullWidth
          error={error.filter((err) => err.instancePath === "/lastname").length !== 0}
          helperText={lastnameError !== undefined ? lastnameError.message : ""}
          defaultValue={lastname}
          onChange={handleInputChange}
          required
          margin="normal"
        />
        <TextField
          name="firstname"
          id="firstname"
          label={t("Firstname")}
          sx={styles.textField}
          onChange={handleInputChange}
          error={error.filter((err) => err.instancePath === "/firstname").length !== 0}
          helperText={firstnameError !== undefined ? firstnameError.message : ""}
          fullWidth
          required
          margin="normal"
          defaultValue={firstname}
        />
        <TextField
          name="address"
          id="address"
          label={t("Address")}
          sx={styles.textField}
          onChange={handleInputChange}
          error={error.filter((err) => err.instancePath === "/address").length !== 0}
          helperText={addressError !== undefined ? addressError.message : ""}
          fullWidth
          required
          margin="normal"
          defaultValue={address}
        />
        <TextField
          name="mobilephone"
          id="mobilephone"
          label={t("Mobilephone")}
          sx={styles.textField}
          onChange={handleInputChange}
          error={error.filter((err) => err.instancePath === "/mobilephone").length !== 0}
          helperText={mobilephoneError !== undefined ? mobilephoneError.message : ""}
          fullWidth
          required
          margin="normal"
          defaultValue={mobilephone}
        />
        <TextField
          name="email"
          id="email"
          label={t("Email")}
          sx={styles.textField}
          onChange={handleInputChange}
          error={error.filter((err) => err.instancePath === "/email").length !== 0}
          helperText={emailError !== undefined ? emailError.message : ""}
          fullWidth
          required
          margin="normal"
          defaultValue={email}
        />

        <div>
          <Button sx={styles.submit} variant="contained" type="submit" color="primary" size="large">
            <SaveIcon />
            {t("Save")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ProfileForm;
