import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import HeaderUserMenu from "components/HeaderUserMenu";
import { ACCENT_COLOR } from "consts/colors";
import logo from "images/applogo-white.png";
import { Link as RouterLink } from "react-router-dom";

export const RegistrationMobileHeader = ({ isLoggedIn, setIsLoggedIn, handleToggle }) => {
  const styles = {
    menuButton: {
      left: 15,
    },
    logoIcon: {
      color: (theme) => theme.palette.secondary.main,
      width: "1.5em",
      height: "1.5em",
    },
  };
  return (
    <>
      <AppBar
        sx={{
          display: { sm: "block", md: "none" },
          flexGrow: 1,
          background: ACCENT_COLOR,
          color: "#fff",
        }}
        position="fixed"
        elevation={0}
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0.5, display: { sm: "flex", md: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleToggle}
              sx={styles.menuButton}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <RouterLink to="/">
            <img src={logo} style={{ height: 38 }} alt="" />
          </RouterLink>
          {isLoggedIn && <HeaderUserMenu setIsLoggedIn={setIsLoggedIn} />}
        </Toolbar>
      </AppBar>
      {/* When you render the app bar position fixed, the dimension of the element doesn't impact the rest of the page. This can cause some part of your content to be invisible, behind the app bar. Here are 3 possible solutions:
      You can render a second <Toolbar /> component: */}
      <Toolbar id="page-scrolltop-anchor" />
      {/*</HideOnScroll>*/}
    </>
  );
};

export default RegistrationMobileHeader;
