import { createInstance, MatomoProvider } from "@jonkoops/matomo-tracker-react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AuthProvider } from "context/AuthContext";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import theme from "theme";
import "typeface-roboto";
import App from "./App";
import "./components/i18n";
import { AppDataProvider } from "./context/index";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const instance = createInstance({
  urlBase: process.env.REACT_APP_MATOMO_SITE,
  siteId: process.env.REACT_APP_MATOMO_SITE_ID,
  // userId: 'UID76903202', // optional, default value: `undefined`.
  // trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
  // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  // heartBeat: {
  // optional, enabled by default
  // active: true, // optional, default value: true
  // seconds: 10, // optional, default value: `15
  // },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
});

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AppDataProvider>
          <AuthProvider>
            <MatomoProvider value={instance}>
              <App />
            </MatomoProvider>
          </AuthProvider>
        </AppDataProvider>
      </BrowserRouter>
    </ThemeProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
