import { gql } from "@apollo/client"

export const SITE_PROFILES_QUERY = gql`query SiteProfiles ($id: ID!) {
  siteProfiles(id:$id) {
    siteid
    seasons {
        name
      },
    sports {
      sportid,
      name,
      translation
    },
    site {
      name
      domain_extension
      logo
    }
  }
}`
