import SiteCardMap from './SiteCardMap';

// import Marker from './Marker'

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

// also usefull:
// https://codesandbox.io/s/react-google-maps-api-ir5ks?from-embed

// InfoWindow component
const InfoWindow = (props) => {
  const { place, handleClickClose } = props;
 
  // for single infowindow, absolute position on whole map
  const commonInfoWindowStyle = {
    position: 'absolute',
    top: '10vh',
    right: '5vw',
    // width: "250px",
    // maxWidth: "250px",
    maxHeight: 150,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 0,
    fontSize: 14,
    zIndex: 100,
    // animation: 'marker-show 0.25s cubic-bezier(.15,.65,.45,.95)',
    animation: 'marker-show 0.25s ease-in',
    // transformOrigin: 'center'
  };

  return (
    <div style={commonInfoWindowStyle}>
      <div style={{ fontSize: 12 }}>
        {/* {place.name} */}
        <SiteCardMap site={place} handleClickClose={handleClickClose} />
      </div>
      {/* <div style={{ fontSize: 14 }}>
        <span style={{ color: 'grey' }}>
          {place.rating}{' '}
        </span>
        <span style={{ color: 'orange' }}>
          {String.fromCharCode(9733).repeat(Math.floor(place.rating))}
        </span>
        <span style={{ color: 'lightgrey' }}>
          {String.fromCharCode(9733).repeat(5 - Math.floor(place.rating))}
        </span>
      </div>
      <div style={{ fontSize: 14, color: 'grey' }}>
        {place.types[0]}
      </div>
      <div style={{ fontSize: 14, color: 'grey' }}>
        {'$'.repeat(place.price_level)}
      </div>
      <div style={{ fontSize: 14, color: 'green' }}>
        {place.opening_hours.open_now ? 'Open' : 'Closed'}
      </div> */}
    </div>
  );
};

export default InfoWindow