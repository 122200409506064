import React from 'react'
// import SiteLogo from './SiteLogo'
// import { makeStyles } from '@mui/material';

// const useStyles = makeStyles(theme => ({

// }));


//  based on: https://gist.github.com/jobsamuel/56496033bfb4d0f3e316aeb88341ed16
// Also here we use a ref passed from parent to allow accessing each marker's dom element
const Marker = (props) => {
  // const classes = useStyles()
  return (
  <div className="marker">
    <div className="pin"></div>
    {props.pulse && <div className="pulse"></div>}
  </div>
  )
} 


export default Marker