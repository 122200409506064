import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField
} from "@mui/material";
import AppForm from "components/AppForm";
import Button from "components/Button";
import FormikSportsSelect from "components/FormikSportsSelect";
import { Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import * as MemberRoles from "../../../../utils/MemberRoles";
import userValidationSchema from "../userValidationSchema";
import ChildsFormField from "./ChildsFormField";
import RegistrationMembersSelect from "./RegistrationMembersSelect";
// import ChildsFormField from "./ChildsFormField";

const UserRegistrationForm = (props) => {
  const { onSubmit, siteid, setEmail, setSubmissionCompleted, site, sports, classes } = props;

  const { t } = useTranslation()

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordConfirm: "",
    mobilephone: "",
    address: "",
    role: MemberRoles.ROLE_DEFAULT,
    sports: [],
    childs: [
          {
          firstName: "",
          lastName: "",
          birthDate: "",
          address: "",
          sport: []
        }
    ],
    comments: "",
  };
  const [showChilds, setShowChilds] = useState(initialValues.role !== MemberRoles.ROLE_PLAYER);
  const [showPassword, setShowPassword] = useState(false);

  let validationSchema = userValidationSchema;

  if (siteid) {
    validationSchema = {
      ...validationSchema,
        childs: Yup.array().of(
          Yup.object().shape(
            {
              firstname: Yup.string().required('Required').min(3).max(255),
              lastname: Yup.string().required('Required').min(3).max(255),
              birth_date: Yup.date().required(),
              sport: Yup.array().min(1, 'Pick at least one sport'),
            }
          )
        ),
        sports: Yup.array() // easier to handle with multiple select
          .min(1, 'Pick at least one sport'),
      comments: Yup.string().max(500),
    };
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const helpText = (name, formikComp) => {
    return formikComp.touched[name] && t(formikComp.errors[name]);
  };
  validationSchema = Yup.object().shape(validationSchema);

  return (
    <AppForm maxWidth={"sm"}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => onSubmit(siteid, setEmail, setSubmissionCompleted, values, actions)}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  // variant='outlined'
                  label={t("Firstname")}
                  // InputLabelProps={{ required: true }}
                  name="firstname"
                  fullWidth
                  className={classes.textField}
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                  onBlur={formik.handleBlur}
                  helperText={helpText("firstname", formik)}
                  margin="normal"
                  // {...formik.getFieldProps('firstName')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  label={t("Lastname")}
                  name="lastname"
                  fullWidth
                  className={classes.textField}
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                  helperText={helpText("lastname", formik)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Email"
                  InputLabelProps={{ required: true }}
                  name="email"
                  type="email"
                  fullWidth
                  className={classes.textField}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  // value={values.email}
                  // validate={validateEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.errors.email && formik.touched.email && t(formik.errors.email)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label={t("Address")}
                  name="address"
                  InputLabelProps={{ required: true }}
                  fullWidth
                  className={classes.textField}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.errors.address && formik.touched.address && t(formik.errors.address)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label={t("Mobile phone")}
                  name="mobilephone"
                  InputLabelProps={{ required: true }}
                  fullWidth
                  className={classes.textField}
                  error={formik.touched.mobilephone && Boolean(formik.errors.mobilephone)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.errors.mobilephone && formik.touched.mobilephone && t(formik.errors.mobilephone)}
                  margin="normal"
                />
              </Grid>

              {siteid && (
                <>
                <RegistrationMembersSelect      
                FormikSportsSelect={FormikSportsSelect}
                site={site}
                formik={formik}
                MemberRoles={MemberRoles}
                sports={sports}
                initialValues={initialValues}
                setShowChilds={setShowChilds}
                 />

              <Grid item xs={12}>
                <Collapse in={showChilds}>
                  <ChildsFormField
                    errors={formik.errors}
                    touched={formik.touched}
                    values={formik.values}
                    handleChange={formik.handleChange}
                    sports={site ? site.sports : null}
                  />
                </Collapse>
              </Grid>
              </>
              )}

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  label={t("Password")}
                  InputLabelProps={{ required: true }}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  fullWidth
                  className={classes.textField}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={helpText("password", formik)}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  label={t("Confirm Password")}
                  InputLabelProps={{ required: true }}
                  name="passwordConfirm"
                  type={showPassword ? "text" : "password"}
                  id="password-confirm"
                  fullWidth
                  className={classes.textField}
                  {...formik.getFieldProps("passwordConfirm")}
                  error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                  helperText={helpText("passwordConfirm", formik)}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {siteid && (
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    multiline
                    label={t("Comments")}
                    name="comments"
                    fullWidth
                    {...formik.getFieldProps("comments")}
                    className={classes.textField}
                    helperText={helpText("comments", formik)}
                  />
                </Grid>
              )}

              {/* before actual submit display async validations (server) errors in a separate section */}

              <Grid item xs={12} style={{ marginTop: 24 }}>
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    disabled={formik.isSubmitting}
                    variant="contained"
                    className={classes.button}
                    color="secondary"
                    fullWidth
                  >
                    { t("Submit") }
                  </Button>
                  {formik.isSubmitting && (
                    <CircularProgress disableShrink size={24} className={classes.buttonProgress} />
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </AppForm>
  );
};

export default UserRegistrationForm;
