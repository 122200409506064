import { Popper } from "@mui/material";
import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

const SocialButtons = () => {
  const url = process.env.REACT_APP_URL_LIVE;
  const styles = {
    socialMediaPopper: {
      top: "230px  !important",
      left: "unset !important",
      right: "0px !important",
      display: "grid",
    },
    socialMediaButton: {
      "&:hover > svg": {
        height: "50px !important",
        width: "50px !important",
      },
    },
  };

  const [anchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "social" : undefined;
  return (
    <>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <LinkedinShareButton
          title="Findsportclub.com"
          source={url}
          url={url}
          summary={"Find the closest sport club or create a new one"}
          sx={styles.socialMediaButton}
        >
          <LinkedinIcon size={36} />
        </LinkedinShareButton>
        <FacebookShareButton
          url={url}
          quote={"Find your sport club"}
          hashtag="#findsportclub.com"
          sx={styles.socialMediaButton}
        >
          <FacebookIcon size={36} />
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          title={"Find the closest sport club or create a new one"}
          hashtag="#findsportclub.com"
          sx={styles.socialMediaButton}
        >
          <TwitterIcon size={36} />
        </TwitterShareButton>
      </Popper>
    </>
  );
};

export default SocialButtons;
