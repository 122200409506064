import { gql } from "@apollo/client"

export const STRIPE_PRICES = gql`query Prices($siteid: ID!) {
  stripePrices(siteid: $siteid) {
    id,
    billing_scheme,
    nickname,
    recurring,
    currency,
    product,
    tiers_mode,
    tiers,
    unit_amount
  }
}`
