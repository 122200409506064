import { useQuery } from "@apollo/client";
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import useAuth from "context/AuthContext";
import { SINGLE_SITE_PROFILE_QUERY } from "graphql/getSingleSiteProfile";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import ProfileHelper from "../../../utils/ProfileHelper";
import SiteChilds from "./components/SiteChilds";
import SiteProfileGeneral from "./components/SiteProfileGeneral";
import StripeSubscriptionsGeneral from "./components/StripeSubscriptionsGeneral";


const styles = {
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 0,
    width: 50,
    height: 50,
  },
};

const SiteProfile = (props) => {
  const { t } = useTranslation()
  const classes = styles;
  const { siteid } = useParams();
  const { user } = useAuth();
  const { id } = user;
  const { loading, error, data } = useQuery(SINGLE_SITE_PROFILE_QUERY, {
    variables: { id, siteid },
  });
  if (loading) return <div>{t("Fetching")}</div>;
  if (error) return <div>{error.message}</div>;
  const profile = data?.singleSiteProfile;
  return (
    <>
      <List dense className={classes.root}>
        <ListItem key={profile.id}>
          <ListItemAvatar>
            <Avatar alt={"Site Logo"} src={ProfileHelper.getSiteLogo(profile)} />
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="h5" color="inherit">
              {`${t("My profile in")} ${data.singleSiteProfile.site.name}`}
            </Typography>
          </ListItemText>
        </ListItem>
      </List>

      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <SiteProfileGeneral data={profile} />
          </Grid>

          <Grid item xs={12} md={4}>
            <SiteChilds data={profile.childs} />
            {/* <SiteProfileChilds data={data.singleSiteProfile.childs} /> */}
          </Grid>
          <Grid item xs={12} md={8}>
            <StripeSubscriptionsGeneral siteid={profile.siteid} childs={profile.childs} userid={id} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

// multiple hoc export
export default SiteProfile;
