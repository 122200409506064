import { gql } from "@apollo/client"

export const PROFILES_WITH_CHILDS_QUERY = gql`query ProfilesWithChilds ($id: ID!) {
  siteProfiles(id:$id) {
    id,
    userid,
    siteid,
    firstname,
    lastname,
    photo,
    personal_data_policy,
    mobilephone,
    phone,
    address,
    postcode,
    email,
    status,
    site {
      siteid,
      name,
      domain_name,
      logo
    },
    childs {
      id,
      userid,
      firstname,
      lastname,
      siteid,
      photo
    },
  }
}`
