import { gql } from "@apollo/client"
/**
 * fetch child by id
 */
export const CHILD_QUERY = gql`query Child ($id: ID!) {
  child(id: $id) {
    id,
    firstname,
    lastname,
    birth_date,
    child_registrations{
      id,
      siteid,
      status,
      data
    },
    siteProfiles {
      id,
      userid,
      siteid,
      firstname,
      birth_date,
      lastname,
      medical_data,
      general_information,
      photo,
      personal_data_policy,
      seasons {
        name
      },
      sports {
        name,
        translation
      },
      site {
        siteid,
        name,
        logo,
        domain_name
      },
    }
  }
}`