import { gql } from "@apollo/client";

export const STATUS_REGISTRARTION_PENDING = 0;
export const STATUS_REGISTRARTION_ACCEPTED = 1;
export const STATUS_REGISTRARTION_REJECTED = 2;

const ChildRegistrationStatuses = [
  { id: STATUS_REGISTRARTION_PENDING, label: "Pending" },
  { id: STATUS_REGISTRARTION_ACCEPTED, label: "Accepted" },
  { id: STATUS_REGISTRARTION_REJECTED, label: "Rejected" },
];

export const getRegistrationStatus = (status) => {
  const [data] = ChildRegistrationStatuses.filter((regstatus) => regstatus.id === status);
  return data
};
export const CHILD_CREATE_REGISTRATION = gql`
  mutation CreateChildRegistration($input: ChildRegistrationInput!) {
    createChildRegistration(childRegistration: $input) {
      userid
      siteid
      status
      data
      created_at
      updated_at
    }
  }
`;
