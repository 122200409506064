import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Button from "../Button";

function Error404(props) {
  const styles = {
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      marginTop: 60,
      height: "90vh",
      backgoundColor: (theme) => theme.palette.error.main,
    },
    title: {
      fontSize: 100,
    },
  };

  return (
    <div sx={styles.container}>
      <Grid container sx={styles.container} alignItems="center" justifyContent="center" direction="row">
        <Grid item xs={12}>
          <Typography sx={styles.title} variant="h1" marked="center">
            404
          </Typography>
          <Typography sx={styles.description} variant="h5" marked="center" gutterBottom>
            We are sorry, but the page you requested was not found
          </Typography>
          <div style={{ marginTop: 50 }}>
            <Button color="secondary" size="large" variant="contained" sx={styles.button} component={Link} to="/">
              Go home
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Error404;
