import {
  Card, CardContent, CardHeader, Checkbox, Divider, Grid,
  Typography
} from '@mui/material';
import FormikSportsSelect from 'components/FormikSportsSelect';
import { Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
const styles = {
  root: {
    borderRadius: 12,
    minWidth: 256,
    maxWidth: 600,

    textAlign: 'center',
  },
  title: {
    fontSize: 15,
  },
};

const CheckoutChildsAndSports = (props) => {
  const  classes = styles;
  const { childs, sports } = props;
  const { t, i18n } = useTranslation()

  return (
    <>
      <Card className={classes.root} width='400px'>
        <CardHeader title={t('Childs')} className={classes.header} />
        <Divider variant='middle' />
        <CardContent>
          {/* <FormGroup> */}
          <Grid container spacing={1}>
          <FieldArray name="childs"
            render={()=>( 
              <>
              {childs.map((child) => (
              <Grid container spacing={1} key={child.id}>
                <Grid item xs={4} sm={4}>
                  <Typography className={classes.title}>
                  {`${child.firstname} ${child.lastname}`}
                  <Checkbox
                      name={`childs[${child.id}].child`}
                    />
                    </Typography>
                </Grid> 
                <Grid item xs={8} sm={8}>
                  <Field
                    component={FormikSportsSelect}
                    name={`childs[${child.id}].sport`}
                    label={t("Child's sports?")}
                    id='field1' //  fix browser force autocomplete
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : t(option.translation[i18n.language])
                    }
                    options={sports} // show only sports availble for the specific site
                  />
                </Grid>
              </Grid>
            ))}
            </>)}
             />
          </Grid>
          {/* </FormGroup> */}
        </CardContent>
      </Card>
    </>
  );
};

export default CheckoutChildsAndSports;
