import MapIcon from "@mui/icons-material/Map";
import UnhappyIcon from "@mui/icons-material/SentimentDissatisfied";
import CardsIcon from "@mui/icons-material/ViewModule";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Fab from "@mui/material/Fab";
import { Box } from "@mui/system";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { AppDataContext } from "../context/index";
import Button from "./Button";
import Footer from "./Footer";
import SearchHeader from "./SearchHeader";
import SearchSubHeader from "./SearchSubHeader";
import SiteCards from "./SiteCards";
import SitesMap from "./SitesMap";
// import SitesMapNew from "./SitesMapNew";

const API_URL = process.env.REACT_APP_PUBLIC_API_URL;

const TOOLBAR_HEIGHT = 10;

// subcomponent to display empty results
const EmptyResults = (props) => {
  const { initialFilters, setFilters } = props;
  const { t } = useTranslation();

  const handleResetFiltersClick = (ev) => {
    setFilters(initialFilters);
  };

  return (
    <div style={{ textAlign: "center", padding: 32, minHeight: 300 }}>
      <div style={{ padding: 32 }}>
        <UnhappyIcon style={{ fontSize: "4rem" }} />
      </div>

      <Typography gutterBottom variant="h3" sx={{ fontSize: 18, textTransform: "initial", letterSpacing: 0 }}>
        <strong>{t("noResults")}</strong>
      </Typography>

      <Typography
        gutterBottom
        variant="h6"
        style={{
          fontSize: 14,
          textTransform: "initial",
          fontWeight: "normal",
        }}
      >
        {t("retryResults")}
      </Typography>

      <div style={{ paddingTop: 32 }}>
        <Button variant="contained" color="secondary" onClick={handleResetFiltersClick}>
          {t("ResetFilters")}
        </Button>
      </div>
    </div>
  );
};

const SearchWithMap = (props) => {
  const { t, i18n } = useTranslation();
  // console.log("props location", props.location.state);
  const useIsWidthDown = (breakpoint) => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down(breakpoint));
  };
  const theme = useTheme();

  const isWidthDown = useIsWidthDown("sm", props.width);
  // let params = useParams();
  const { userid } = props;

  // helper flag
  const dashboardMode = userid ? true : false;
  const styles = {
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180,
      width: "90%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2),
    },
    searchBar: {
      marginTop: theme.spacing(1),
    },
    searchButtonContainer: {
      // smarginTop: theme.spacing(4),
      position: "relative",
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
      zIndex: 2,
    },
    siteCardsOuter: {
      overflow: "hidden",
    },
    siteCardsContainer: {},
    currentViewButtonContainer: {
      position: "fixed",
      right: "10vw",
      top: "15vh",
      transform: "none",
      // transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
      zIndex: 2, // show above SiteCard academy logo
    },
    fab: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    emptyResults: {
      padding: theme.spacing(2),
    },
  };
  // TODO: detect location user's browser in case user visited directly the search page
  const defaultFilters =
    i18n.language === "en"
      ? {
          coordinates: {
            lat: 35.232935,
            lng: -80.846735,
          },
          address: "Northwest Washington, USA",
        }
      : {
          coordinates: {
            lat: 37.980682040052066,
            lng: 23.720542329151066,
          },
          address: "Πειραιώς 53, Αθήνα 105 53, Ελλάδα",
        };

  const dataContext = useContext(AppDataContext);

  const { sites, applyFilter } = dataContext;

  // const styles = useStyles({ dashboardMode: dashboardMode });

  const [places, setPlaces] = useState(null);

  const [loading, setLoading] = useState(true);

  // const [error, setError] = useState(null);

  // const [url, setUrl] = useState(`${API_URL}/sites`);

  const clientSearch = false; // if search will be done from server or by using already loaded data

  const [mustUpdateMap, setMustUpdateMap] = useState(true);

  // used to force a place to shown on map (centered and infowindow open), used in sitecard to reveal site on map
  const [place, setPlace] = useState();

  const orderOptions = [
    { text: t("Name (Asc)"), field: "name", direction: "asc", value: "name_asc" },
    {
      text: t("Name (Desc)"),
      field: "name",
      direction: "desc",
      value: "name_desc",
    },
    {
      text: t("Distance (Asc)"),
      field: "distance",
      direction: "asc",
      value: "distance_asc",
    },
    {
      text: t("Distance (Desc)"),
      field: "distance",
      direction: "desc",
      value: "distance_desc",
    },
  ];

  const location = useLocation();
  const initialFilters = {
    sportid: location.state && location.state.sportid ? location.state.sportid : "",
    regionid: location.state && location.state.regionid ? location.state.regionid : "",
    siteid: "", // todo
    coordinates: location.state && location.state.coordinates ? location.state.coordinates : defaultFilters.coordinates, // coordinates from landing page
    address: location.state && location.state.address ? location.state.address : defaultFilters.address, // address from landing page
    distance: 10, // default distance
    orderBy: "distance",
    orderDirection: "asc",
  };

  const [filters, setFilters] = useState(initialFilters);

  // Holds the current center as the user clicks on a marker or presses the "reveal in map" button from site card
  // Can be different from the current location coordinates in places autocomplete
  const [currentMapCenter, setCurrentMapCenter] = useState(
    location.state && location.state.coordinates ? location.state.coordinates : defaultFilters.coordinates
  );

  // const VIEW_BOTH = 'both';
  const VIEW_MAP = "map";
  const VIEW_CARDS = "cards";

  const [currentView, setcurrentView] = useState(VIEW_CARDS);


  // attempt to add meta-property to marker (site) to indicate that is opened/clicked
  // function prepareResultForMap(result) {
  //   result.map( item => {
  //     item.open = false
  //   })
  //   return result;
  // }

  async function doSearch() {
    if (clientSearch) filterData();
    else {
      const json = await fetchData();
      setPlaces(json);
      setLoading(false);
    }
  }

  /**
   * this is called when we request to reset the map and filter the sportclubs.
   * This can be a call from filter sport clubs based on criteria or search inside
   * the map circle
   * @param {*} event
   */
  async function handleFiterSites(event) {
    setCurrentMapCenter(filters.coordinates);
    setPlace(null);
    await doSearch();
    setMustUpdateMap(true); // setMustUpdateMap(coordinatesHasChanged)
  }

  const filterData = () => {
    setLoading(true);
    setPlaces(applyFilter(sites, filters));
    setLoading(false);
  };

  // reorders data using already fetched data, orderOptions are in filters
  const handleChangeOrder = (options) => {
    setLoading(true);
    // console.log("order", options);
    setPlaces(applyFilter(places, options));
    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    return fetch(
      `${API_URL}/sites?sport=${filters.sportid}&lat=${filters.coordinates ? filters.coordinates.lat : ""}&lng=${
        filters.coordinates ? filters.coordinates.lng : ""
      }&distance=${filters.distance >= 100 ? "" : filters.distance}&orderby=${
        filters.orderBy ? filters.orderBy : "distance"
      }&direction=${filters.orderDirection ? filters.orderDirection : "asc"}`
    ).then((res) => res.json());
  }, [filters.coordinates, filters.distance, filters.orderBy, filters.orderDirection, filters.sportid]);

  const handleCurrentViewClick = (ev) => {
    if (currentView === VIEW_MAP) setcurrentView(VIEW_CARDS);
    else setcurrentView(VIEW_MAP);
  };

  // https://www.robinwieruch.de/react-hooks-fetch-data/
  useEffect(() => {
    let ignore = false;
    async function startFetching() {
      const json = await fetchData();
      if (!ignore) {
        setPlaces(json);
        setLoading(false);
      }
    }
    startFetching();
    return () => {
      ignore = true;
    };
  }, [fetchData]);

  // Οταν ο χρηστης παταει ευρεση απο την landing page τοτε μεταφερεται στην search σελιδα (αλλο layout) και:
  //  1) αν εχει βαλει κριτηρια τοτε εμφανιζονται τα αποτελεσματα συμφωνα με τα κριτηρια καθως και τα πεδια αναζητησης εχουν τα κριτηρια
  //  2) αν δεν εχει βαλει κριτηρια τοτε εμφανιζονται ολα τα αποτελεσματα
  // στη συνεχεια κανει αναζητηση απο την search σελιδα
  // ο λογος που γινεται αυτο ειναι για να μπορει να κανει την πρωτη αναζητηση κατευθειαν απο την landing σελιδα
  // ΠΡΟΣΟΧΗ:
  //  1) https://stackoverflow.com/questions/54675523/state-inside-useeffect-refers-the-initial-state-always-with-react-hooks
  //  2) https://reactjs.org/docs/hooks-effect.html
  // με τα [] στην τελευταια παραμετρο του useEffect καθοριζουμε ποτε θα γινεται update, αν ειναι [] τοτε τρεχει μονο μια φορα οπως το componentDidMount(),
  // επισης αν θελουμε μπορουμε πχ να  βαλουμε [sportid, regionid] για να ανανεωνεται καθε φορα που αλλαζουν αυτα και ετσι μεσα στο useEffect εχουμε προσβαση στις ενημερωμενες τιμες τους (αλλιως ειναι παντα οι αρχικες!!!!)
  //
  // αν θελουμε η αναζητηση να τρεχει κατευθειαν οταν αλλαζει κατι στα κριτηρια (και οχι με το button) τοτε μπορουμε να βαλουμε [filters] στην τελευταια παραμετρο
  // useEffect(() => {
  //   doSearch();
  // }, []);

  // const updateDimensions = () => {

  // }
  // https://stackoverflow.com/questions/54002792/should-i-use-one-or-many-useeffect-in-component
  // useEffect(() => {
  //   window.addEventListener('resize', updateDimensions);
  // }, [])

  // exclusive mode:
  //  - true means either list or map is displayed (used in mobile),
  //  - false means both list and map are displayed (used in sm and up)
  let exclusiveMode = false;
  if (isWidthDown) exclusiveMode = true; // cards or list for mobile, user can change view mode with a button

  if (loading) return "loading";

  return (
    <>
      <Box
        id="search"
        sx={{
          position: "fixed",
          top: (props) => (dashboardMode ? 7 * TOOLBAR_HEIGHT : TOOLBAR_HEIGHT),
          width: "100%",
        }}
      >
        <SearchHeader
          filters={filters}
          setFilters={setFilters}
          handleSubmit={handleFiterSites}
          orderOptions={orderOptions}
          dashboardMode={dashboardMode}
        />
        <Box
          sx={{
            display: "flex",
            minHeight: 0,
            flexFlow: "row wrap",
            justifyContent: "space-around",
            m:(theme) => theme.spacing(2)
          }}
        >
          {(!exclusiveMode || currentView === VIEW_CARDS) && (
            <Box
              id="search-container"
              sx={{
                flex: 1,
                overflowY:"scroll",
                display: "flex",
                flexFlow: "column nowrap",
                m:(theme) => theme.spacing(2)
              }}
            >
              <SearchSubHeader
                result={places}
                filters={filters}
                setFilters={setFilters}
                orderOptions={orderOptions}
                handleChangeOrder={handleChangeOrder}
              />
              <Box
                sx={{
                  display: "flex",
                  maxHeight: 500,
                }}
              >
                {places && places.length ? (
                  <SiteCards
                    home={props.home ? props.home : false}
                    signedSites={props.signedSites ? props.signedSites : null}
                    pendingSites={props.pendingSites ? props.pendingSites : null}
                    itemsXS={1}
                    itemsSM={2}
                    itemsMD={2}
                    itemsLG={3}
                    loading={loading}
                    data={places}
                    setCurrentMapCenter={setCurrentMapCenter}
                    setMustUpdateMap={setMustUpdateMap}
                    setPlace={setPlace}
                  />
                ) : (
                  <EmptyResults initialFilters={initialFilters} setFilters={setFilters} />
                )}
              </Box>
            </Box>
          )}
          {(!exclusiveMode || currentView === VIEW_MAP) && (
            <Box
              id="search-map-container"
              sx={{
                display: "flex",
                flex: 1,
                // flexGrow: 1,
              }}
            >
              <SitesMap
                dashboardMode={dashboardMode}
                mustUpdateMap={mustUpdateMap}
                handleSubmit={handleFiterSites}
                filters={filters}
                setFilters={setFilters}
                center={currentMapCenter}
                setMustUpdateMap={setMustUpdateMap}
                setCurrentMapCenter={setCurrentMapCenter}
                place={place}
                setPlace={setPlace}
                places={places}
              />
            </Box>
          )}
        </Box>
        {exclusiveMode && (
          <Box sx={styles.currentViewButtonContainer}>
            <Fab
              variant="extended"
              aria-label="map or list"
              sx={styles.fab}
              onClick={handleCurrentViewClick}
              color="secondary"
            >
              {currentView === VIEW_CARDS && (
                <>
                  <MapIcon sx={styles.extendedIcon} />
                  Map
                </>
              )}
              {currentView === VIEW_MAP && (
                <>
                  <CardsIcon sx={styles.extendedIcon} />
                  List
                </>
              )}
            </Fab>
          </Box>
        )}
        {!dashboardMode && <Footer />}
      </Box>
    </>
  );
};

export default SearchWithMap;
