import { useMutation, useQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import SuccessComponent from "components/common/SuccessComponent";
import ErrorComponent from "components/error-handling/ErrorComponent";
import useAuth from "context/AuthContext";
import { DELETE_CHILD_MUTATION } from "graphql/deleteChild";
import { CHILDS_QUERY } from "graphql/getChilds";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const styles = {
  root: {}, // {width: '100%'},
  ListItem: { paddingTop: "10px", paddingBottom: "10px" }, //borderWidth: 1, borderColor: '#eeeeee',borderStyle: 'solid', borderRadius: '4px'} // or borderTop: '1px solid red'
  listButton: { margin: "10px" },

  badgeSubscriptions: {
    left: -3,
    // The border color match the background color.
    // border: `0px solid ${
    //   theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    // }`,
  },
  badgeChilds: {
    // The border color match the background color.
    left: 10,
    // border: `0px solid ${
    //   theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    // }`,
  },
};

const Childs = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const classes = styles;

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);


  const handleDeleteClick = (e, child) => {
    e.preventDefault();
    deleteChild({
      variables: { id: child.id },
      update: (cache) => {
        const existingChilds = cache.readQuery({
          query: CHILDS_QUERY,
          variables: {
            id: user.id,
          },
        });
        const newChilds = existingChilds.childs.filter((c) => c.id !== child.id);
        cache.writeQuery({
          query: CHILDS_QUERY,
          variables: {
            id: user.id,
          },
          data: { childs: newChilds },
        });
      },
    });
  };
  const childsQuery = useQuery(CHILDS_QUERY, {
    variables: { id: user.id },
  });
  const [deleteChild, deleteChildMutation] = useMutation(DELETE_CHILD_MUTATION, {
    onCompleted(data) {
      setIsSuccess(true);
      setTimeout(() => setIsSuccess(false), 5000);
    },
    onError() {
      setIsError(true);
      setTimeout(() => setIsError(false), 8000);
    },

  });

  const loading = childsQuery.loading || deleteChildMutation.loading;
  const error = childsQuery.error || deleteChildMutation.error;

  if (loading) return <div>{t("Fetching")}</div>;

  return (
    // <div style={{ maxWidth: "100%" }}>
      <Stack spacing={2} alignItems="center">
      <Typography variant="h5" gutterBottom>
        {t("Childs")}
      </Typography>
      <Typography variant="subtitle2" style={{ marginBottom: "20px" }} color="textSecondary" gutterBottom>
        {t("These are your childs ...")}
      </Typography>
      <SuccessComponent open={isSuccess} type="success" message={t("Child Deleted")} />
      {error && <ErrorComponent open={isError} error={error} />}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        color="primary"
        style={classes.listButton}
        component={NavLink}
        to={{
          pathname: `/home/childs/add`,
        }}
      >
        {t("Add child")}
      </Button>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {childsQuery.data.childs.map((child) => (
          <ListItem style={classes.ListItem} key={child.id}>
            <ListItemAvatar>
              <Avatar>{child.firstname.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <NavLink
                  to={{
                    pathname: `/home/child/${child.id}`,
                    state: { data: child },
                  }}
                >
                  {child.lastname} {child.firstname}
                </NavLink>
              }
            />
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              color="primary"
              style={classes.listButton}
              component={NavLink}
              to={{
                pathname: `/home/childs/update/${child.id}`,
              }}
            >
              {t("Update")}
            </Button>
            <Button
              variant="contained"
              startIcon={<DeleteIcon />}
              color="warning"
              style={classes.listButton}
              onClick={(e) => handleDeleteClick(e, child)}
            >
              {t("Delete")}
            </Button>
          </ListItem>
        ))}
      </List>
      </Stack>
  );
};

export default Childs;
