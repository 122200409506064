import {
  Button, Grid, List,
  ListItem, ListItemAvatar,
  ListItemSecondaryAction, ListItemText, Typography
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import SiteAvatar from '../../../../components/SiteAvatar';


const styles = {
    root: {width: '100%'},
    ListItem : { paddingRight: '120px' }, //borderWidth: 1, borderColor: '#eeeeee',borderStyle: 'solid', borderRadius: '4px'} // or borderTop: '1px solid red',
};

const Sites =(props) => {
    const { /* user, */ siteProfiles } = props
    const { t } = useTranslation()
    const classes = styles
    // console.log(data)
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h5">{t('My Sport Clubs')}</Typography> 
        </Grid>
        <Grid item xs={12}>
           <List style={classes.root}>
          {
            siteProfiles.map(profile=>(
                  <NavLink
                    to={`/home/siteprofile/${profile.siteid}`}
                    style={{ textDecoration: 'none' }}
                    key={profile.site.name}
                  >
                    <ListItem style={classes.ListItem} key={profile.site.name} button>
                      <ListItemAvatar>
                        <SiteAvatar siteid={profile.siteid} />
                        {/*<Avatar alt={`${profile.siteName} logo`} src={ProfileHelper.getSiteLogo(profile)} />*/}
                      </ListItemAvatar>
                      <ListItemText
                        primary={profile.site.name}
                        // secondary={`${t('Childs')}: ${profile.childs.length}, ${t('Subscriptions')}: ${profile.subscriptionContracts.length}`}
                      />
                      <ListItemSecondaryAction>
                        <Button color="primary" aria-label="View" variant="contained">
                          {t('View')}
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </NavLink>
                  
            ))
          }
          </List>
        </Grid>
      </Grid>
    );
}

export default Sites