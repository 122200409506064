import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button, Card, CardContent, CardHeader, Divider, Grid, TextField
} from '@mui/material';
import { Box } from '@mui/system';
import FormikSportsSelect from 'components/FormikSportsSelect';
import { Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

const ChildsFormField = (props) => {
  const { sports, values, errors, touched, handleChange } = props;
  const { t, i18n } = useTranslation()

  // const helpText = (name, errors) => {
  //   return errors[name];
  // };
//  console.log(errors)
  return (
    <Card sx={{ maxWidth: 500}}>
      <CardHeader title={t('Childs')} />
      <Divider variant='middle' />
      <CardContent>
        <FieldArray
          name='childs'
          render={(arrayHelpers) => (
            <Box sx={{ width: '100%', justifyContent:"center"}}>
              {values.childs.map((child, index) => (
                <Grid container spacing={1} key={index} justifyContent="center" >
                  <Grid item xs={8} sm={5}>
                    
                    <Field
                      as={TextField}
                      error={errors.childs && Boolean(errors?.childs[index]?.lastname)}
                      label={t('Lastname')}
                      name={`childs[${index}].lastname`}
                      InputLabelProps={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={8} sm={5}>
                    <Field
                      label={t('Firstname')}
                      as={TextField}
                      InputLabelProps={{ required: true }}
                      name={`childs.[${index}].firstname`}
                      error={errors.childs && Boolean(errors?.childs[index]?.firstname)}
                    />
                  </Grid>
                  <Grid item xs={8} sm={5}>
                    <Field
                      label={t('Birth Day')}
                      name={`childs.[${index}].birth_date`}
                      as={TextField}
                      type='date'
                      error={errors.childs && Boolean(errors?.childs[index]?.birth_date)}
                      // value={child.birth_date}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={8} sm={5}>
                    <Field
                      component={FormikSportsSelect}
                      name={`childs[${index}].sport`}
                      error={errors.childs && Boolean(errors?.childs[index]?.sport)}
                      label={t("Child's sports?")}
                      id='sports2' //  fix browser force autocomplete
                      getOptionLabel={(option) =>
                        typeof option === 'string' ? option : t(option.translation[i18n.language])
                      }
                      options={sports?sports:[]} // show only sports availble for the specific site
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      variant='contained'
                      color='secondary'
                      size='small'
                      onClick={() => arrayHelpers.remove(index)}
                      startIcon={<DeleteIcon />}
                    >
                      {t("Delete")}
                    </Button>
                  </Grid>
                </Grid>
              ))}
              <Button
                type='button'
                color='primary'
                onClick={() =>
                  arrayHelpers.push({
                    lastname: '',
                    firstname: '',
                    birth_date: '',
                    sport: [],
                  })
                }
              >
                {t("Add")}
              </Button>
            </Box>
          )}
        />
      </CardContent>
    </Card>
  );
};

export default ChildsFormField;
