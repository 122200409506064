
function SectionDivider(props) { 
  return(
    <div sx={{ 
      height: 4,
      width: 55, // 80
      borderRadius: 2,
      backgroundColor: '#dedede',
      margin: '20px auto'
    }}></div>
  );
}

export default SectionDivider;