import { useQuery } from "@apollo/client";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import useAuth from "context/AuthContext";
import { SITE_PROFILES_QUERY } from "graphql/getProfiles";
import { useTranslation } from "react-i18next";
import StripeCustomerIntegration from "../siteprofile/components/StripeCustomerIntegration";

const Subscriptions = (props) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  
  const { loading, error, data } = useQuery(SITE_PROFILES_QUERY, {
    variables: { id: user.id },
  });
  if (loading) return <div>{t("Fetching")}</div>;
  if (error) return <div>{error.message}</div>;
  return (
    <div style={{ maxWidth: "100%" }}>
      <Typography variant="h5" gutterBottom>
        {t("My subscriptions")}
      </Typography>
      <Typography variant="subtitle2" style={{ marginBottom: "20px" }} color="textSecondary" gutterBottom>
        {t("These are your subscriptions ...")}
      </Typography>

      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="subscriptions">
      <TableHead>
          <TableRow>
          <TableCell>{t("Sport Club")}</TableCell>
          <TableCell align="right">{t("Manage")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.siteProfiles.map((row) => (
            <TableRow
            key={row.siteid}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.site.name}
            </TableCell>
            <TableCell align="right"><StripeCustomerIntegration siteid={row.siteid} /></TableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

      {/* <MaterialTable
        columns={[
          {title:"Id", field: "siteid"},
          { title: t("Sport Club"), field: "site.name" },
          {
            field: "button",
            title: t("Manage"),
            render: (rowData) => <StripeCustomerIntegration siteid={rowData.siteid} />,
          },
        ]}
        data={data.siteProfiles}
        options={{
          toolbar: false,
          paging: false,
          idSynonym: 'Id',
        }}
      /> */}
    </div>
  );
};

export default Subscriptions;
