import { Autocomplete, TextField } from "@mui/material";

// based on https://github.com/mui-org/material-ui/issues/18344

const FormikSportsSelect = (props) => {
  const { form: { setFieldValue, }, field: { name } } = props

  const { error, helperText, ...autocompleteProps } = props;
  return (
    <Autocomplete
      {...autocompleteProps}
      filterSelectedOptions
      multiple
      onChange={(e, newValue) => {
        setFieldValue(name, newValue)
      }}
      renderInput={(params) => <TextField {...params} helperText={helperText} error={error} label={props.label} />}
    />
  );
};

export default FormikSportsSelect;
