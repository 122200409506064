import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const lngs = {
    "en": { nativeName: "English", flag: "https://flagcdn.com/16x12/gb.png" },
    "el": { nativeName: "Greek", flag: "https://flagcdn.com/16x12/gr.png" },
  };

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <>
      <Select
        labelId="demo-simple-select-label"
        id="language-select"
        value={i18n.language === "en-US" ? "en" : i18n.language}
        label="Language"
        onChange={handleChange}
      >
        {Object.keys(lngs).map((lng) => (
          <MenuItem key={lng} value={lng}>
            <img src={lngs[lng].flag} alt="lngs[lng].nativeName" />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default LanguageSwitcher;
