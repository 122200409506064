import { Typography } from "@mui/material";
import Container from '@mui/material/Container';
import React from "react";
import MemberFeatures from '../../../components/MemberFeatures';

function PlatformMemberFeatures (props) {
  // const classes = useStyles();
  return (
    <React.Fragment>
    <section style={{marginTop: 100, marginBottom: 30}}>
    <Container maxWidth="md" >
        <Typography variant="h6" component="p" gutterBottom marked="center" 
          // className={classes.sectionTitle}
          style={{fontSize: 22, fontWeight: 100, textTransform: 'initial'}}
        >
          Member Dashboard is a single place to manage multiple memberships to sport clubs who are using the FindSportClub platform
        </Typography>
    </Container>
    </section>
    <MemberFeatures />
    </React.Fragment>
  )
} 

export default PlatformMemberFeatures;
      