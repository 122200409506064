import { useMutation, useQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import SuccessComponent from "components/common/SuccessComponent";
import ErrorComponent from "components/error-handling/ErrorComponent";
import { SITE_USER_QUERY } from "graphql/getSiteUser";
import { SITE_USER_UPDATE } from "graphql/SiteUserUpdate";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import SiteUserForm from "./components/siteUserForm";


const UpdateSiteUser = (props) => {
    const { t } = useTranslation();
    const { userid, siteid } = useParams();
  
    const siteUserQuery = useQuery(SITE_USER_QUERY, {
      variables: { userid, siteid },
    });
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
  
    const [updateSiteUser, siteUserUpdateMutation] = useMutation(SITE_USER_UPDATE, {
      onCompleted(data) {
        setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 5000);
      },
      onError() {
        setIsError(true);
        setTimeout(() => setIsError(false), 8000);
      },
    });
    const loading = siteUserQuery.loading || siteUserUpdateMutation.loading;
    const error = siteUserQuery.error || siteUserUpdateMutation.error;
    if (loading) return <div>{t("Fetching")}</div>;
  
    const siteUser = siteUserQuery.data.singleSiteProfile;
    const initialInputState = {
      id: siteUser.id, 
      lastname: siteUser.lastname,
      firstname: siteUser.firstname,
      address: siteUser.address,
      postcode: siteUser.postcode,
      mobilephone: siteUser.mobilephone,
      };
  
    return (
      <>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h5" gutterBottom>
            {t("Update Site's Profile")}
          </Typography>
          <SuccessComponent open={isSuccess} type="success" message={t("User profile changed")} />
          {error && <ErrorComponent open={isError} error={error} />}
  
          <SiteUserForm
            siteUserId={siteUser.id}
            siteUserMutation={updateSiteUser}
            initialState={initialInputState}
            successMessage={t("Child Updated")}
          />
        </Stack>
      </>
    );
  

}

export default UpdateSiteUser;