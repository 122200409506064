import Avatar from "@mui/material/Avatar";
import SiteHelper from "../utils/SiteHelper";

// https://medium.com/@webcore1/react-fallback-for-broken-images-strategy-a8dfa9c1be1e
// https://github.com/mui-org/material-ui/issues/11128
const defaultImage = "/images/academy_logo_default.png";

function SiteLogo(props) {
  const { site, size, border } = props;
  const styles = {
    siteLogo: {
      height: size,
      width: size,
      borderRadius: "50%",
      border: border ? border : "2px solid #f3f3f3",
      margin: 3,
      marginLeft: 0,
    },
  };
  return (
    <>
      {
        // when logo set but for some reason image not found, will display a default image (slow load)
        site.logo && (
          <Avatar
            alt="logo"
            src={SiteHelper.getSiteLogo(site)}
            sx={styles.siteLogo}
            imgProps={{
              onError: (e) => {
                e.target.src = defaultImage;
              },
            }}
          />
        )
      }
      {
        // when logo not set display default without delay
        !site.logo && <Avatar alt="logo" src={defaultImage} sx={styles.siteLogo} />
      }
    </>
  );
}

export default SiteLogo;
