import CloseIcon from "@mui/icons-material/Close";
import { IconButton, List, ListItemButton, ListItemText } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import { ACCENT_COLOR } from "consts/colors";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const DRAWER_WIDTH = 300;

const Menu = ({ classes, handleToggle }) => {
  const { t } = useTranslation()
  return (
    <>
      <List>
        <NavLink to="/register" style={{ textDecoration: "none" }}>
          <ListItemButton sx={classes.listItem} onClick={handleToggle}>
            <ListItemText
              sx={{
                primary: classes.listItemText,
              }}
              primary={"Home"}
            />
          </ListItemButton>
        </NavLink>
        <NavLink to="/register/why" style={{ textDecoration: "none" }}>
          <ListItemButton sx={classes.listItem} onClick={handleToggle}>
            <ListItemText
              sx={{
                primary: classes.listItemText,
              }}
              primary={t("Why us")}
            />
          </ListItemButton>
        </NavLink>
        <NavLink to="/register/pricing" style={{ textDecoration: "none" }}>
          <ListItemButton sx={classes.listItem} onClick={handleToggle}>
            <ListItemText
              classes={{
                primary: classes.listItemText,
              }}
              primary={t("Pricing")}
            />
          </ListItemButton>
        </NavLink>
        <NavLink to="/register/architecture" style={{ textDecoration: "none" }}>
          <ListItemButton sx={classes.listItem} onClick={handleToggle}>
            <ListItemText
              classes={{
                primary: classes.listItemText,
              }}
              primary={t("Features")}
            />
          </ListItemButton>
        </NavLink>
        <NavLink to="/register-form" style={{ textDecoration: "none" }}>
          <ListItemButton sx={classes.listItem} onClick={handleToggle}>
            <ListItemText
              classes={{
                primary: classes.listItemText,
              }}
              primary={t("Register")}
            />
          </ListItemButton>
        </NavLink>
        {/* <NavLink to="/demo" style={{ textDecoration: "none" }}>
          <ListItemButton sx={classes.listItem} onClick={handleToggle}>
            <ListItemText
              classes={{
                primary: classes.listItemText,
              }}
              primary={t("Demo")}
            />
          </ListItemButton>
        </NavLink> */}
        <NavLink to="/support" style={{ textDecoration: "none" }}>
          <ListItemButton sx={classes.listItem} onClick={handleToggle}>
            <ListItemText
              classes={{
                primary: classes.listItemText,
              }}
              primary={t("How to")}
            />
          </ListItemButton>
        </NavLink>
        {/* <NavLink to="/blog" style={{ textDecoration: "none" }}>
          <ListItemButton sx={classes.listItem} onClick={handleToggle}>
            <ListItemText
              classes={{
                primary: classes.listItemText,
              }}
              primary={t("Blog")}
            />
          </ListItemButton>
        </NavLink> */}
        <NavLink to="/contact" style={{ textDecoration: "none" }}>
          <ListItemButton sx={classes.listItem} onClick={handleToggle}>
            <ListItemText
              classes={{
                primary: classes.listItemText,
              }}
              primary={t("Contact")}
            />
          </ListItemButton>
        </NavLink>
      </List>
    </>
  );
};

// https://medium.com/@tsubasakondo_36683/create-responsive-drawer-menu-with-react-material-ui-617a42764b69
export const RegistrationMobileMenu = (props) => {
  const theme = useTheme();

  const styles = {
    root: {
      flexGrow: 1,
      backgroundColor: ACCENT_COLOR,
      color: "#FFF",
    },
    menuButton: {
      position: "absolute",
      left: 15,
      marginLeft: -12,
      marginRight: 20,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
    // menuButton: {
    //   marginRight: theme.spacing(2),
    // },
    logoIcon: {
      color:(theme) => theme.palette.secondary.main,
      width: "1.5em",
      height: "1.5em",
    },
    logoLink: {
      marginRight: "auto",
    },
    signinButton: {
      color: "black",
    },
    signupButton: {
      color: "white",
    },
    title: {
      flexGrow: 1,
      textAlign: "left",
      marginLeft:(theme) => theme.spacing(1),
      marginTop: 3,
      // color: 'white'
    },
    betaTitle: {
      flexGrow: 1,
      textAlign: "left",
      marginLeft:(theme) => theme.spacing(1),
      // marginTop: 1,
      color: "red",
    },
    normalHeader: {
      // color: 'black'
      background: "white",
    },
    landingHeader: {
      background: "white",
      // boxShadow: 'none'
    },
    buttonIcon: {
      marginRight:(theme) => theme.spacing(1),
    },
    signinButtonMobile: {},
    signupButtonMobile: {},
  
    drawerPaper: {
      backgroundColor: ACCENT_COLOR,
      color: "#fff",
      width: DRAWER_WIDTH,
    },
    closeMenuButton: {
      marginRight: "auto",
      marginLeft: 0,
      color: "white",
    },
    listItem: {
      color: "#fff",
      padding: "15px 25px",
      transition: "all 0.2s ease",
      "&:hover": {
        // color: "#fff",
        backgroundColor: "#178e82",
      },
    },
    listItemText: {
      fontWeight: 400,
      fontSize: 22,
    },    
  }
  return (
    <nav style={styles.root}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

      <Drawer
        variant="temporary"
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={props.open}
        onClose={props.handleToggle}
        sx={{
          display: { sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': styles.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <IconButton onClick={props.handleToggle} sx={styles.closeMenuButton} size="large">
          <CloseIcon />
        </IconButton>
        <Menu classes={styles} handleToggle={props.handleToggle} />
      </Drawer>
    </nav>
  );
};

export default RegistrationMobileMenu;
