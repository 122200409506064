import { useMutation } from "@apollo/client";
// import classnames from 'classnames';
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Avatar, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography
} from "@mui/material";
import { SITE_PROFILE_UPDATE_POLICY } from "graphql/SiteProfileUpdatePersonalDataPolicy";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PolicyDialog from "../../../../components/common/PolicyDialog";
import ProfileHelper from "../../../../utils/ProfileHelper";


const SiteChild = (props) => {
  const [policy, setPolicy] = useState({
    open: false,
    selectedPolicy: props.data.personal_data_policy, //policies[1],
  });

  const handleClickOpen = () => {
    setPolicy((policy) => ({
      ...policy,
      ...{ open: true },
    }));
  };

  const { data } = props;
  const { t } = useTranslation();
  const policyLabel = t(ProfileHelper.getPolicyLabel(policy.selectedPolicy));
  const [updateSiteProfilePersonalPolicy, siteProfileUpdateMutation] = useMutation(SITE_PROFILE_UPDATE_POLICY);

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar alt={t("Photo")} src={ProfileHelper.getProfilePhoto(data)} />
      </ListItemAvatar>
      <ListItemText
        primary={`${data.lastname} ${data.firstname}`}
        secondary={
          <>
            <Typography component="span" color="textSecondary">
              {`${t("Personal data")}: ${policyLabel}`}
            </Typography>
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton aria-label="Settings" onClick={handleClickOpen} size="large">
          <SettingsIcon />
        </IconButton>
        <PolicyDialog
          selectedValue={policy.selectedPolicy}
          open={policy.open}
          onClose={(value) => {
            // update db only if there is a value selected and different from previous value
            if (value !== undefined && value !== policy.selectedPolicy) {
              setPolicy({ selectedPolicy: value, open: false });
              updateSiteProfilePersonalPolicy({ variables: { id: data.id, policy: value } });
            } else {
              setPolicy((policy) => ({ ...policy, ...{ open: false } }));
            }
          }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default SiteChild;
