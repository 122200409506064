const API_URL = process.env.REACT_APP_PUBLIC_API_URL;

export async function isValueAvailableAsync(entity, field, value) {
  try {
    let response;
    if (field === "sitenameid") response = await fetch(`${API_URL}/academies/exists-site/${value}`);
    else response = await fetch(`${API_URL}/${entity}/exists/field/${field}/value/${value}`);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    response = await response.json();

    return response.found; // we invert the value to return true if available
  } catch (error) {
    return false;
  }
}

export default isValueAvailableAsync;
