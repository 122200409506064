import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FeatureEnabledIcon from "@mui/icons-material/Check";
import FeatureDisabledIcon from "@mui/icons-material/Clear";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import clsx from "clsx";
import { ACCENT_COLOR, BG_DARK_COLOR, BG_DARK_TEXT, BG_LIGHT_COLOR, BG_LIGHT_TEXT } from "consts/colors";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Button from "./Button";

const API_URL = process.env.REACT_APP_PUBLIC_API_URL;

function FeatureItem(props) {
  const [name, value] = props.feature;
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" style={{ borderBottom: "1px solid #eaeaea", padding: "5px 0" }}>
      <Grid item xs={6} style={{ textAlign: "right" }}>
        {t(name)}
      </Grid>
      <Grid item xs={6}>
        {value === "false" ? (
          <FeatureDisabledIcon style={{ color: "red" }} />
        ) : value === "true" ? (
          <FeatureEnabledIcon style={{ color: "#17a799" }} />
        ) : (
          <strong>{value}</strong>
        )}
      </Grid>
    </Grid>
  );
}

const Pricing = (props) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const styles = {
    pricing: {
      backgroundColor: BG_DARK_COLOR,
    },
    pricingItem: {
      // padding: 10,
      // boxShadow: "0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12)",
      backgroundColor: BG_LIGHT_COLOR,
      transition: ".25s",
      color: BG_LIGHT_TEXT,
      // margin: 10
      // display: flex,
      flex: "1 0 26%",
      padding: (theme) => theme.spacing(2),
      borderRadius: (theme) => theme.spacing(1),
      margin: (theme) => theme.spacing(2),
      // boxShadow: "3px 3px 39px -15px",
    },
    title: {
      fontWeight: 400,
      fontSize: 20,
      color: "#333",
      // textTransform: "uppercase",
      borderBottom: "#eff2f3 2px solid",
      letterSpacing: -0.5,
    },
    actions: {
      padding: 2,
    },
    button: {
      // height: 60,
      borderRadius: 30,
      minWidth: 180,
      margin: (theme) => theme.spacing(1),
      color: "rgba(255,255,255,0.7)",
      borderColor: "rgba(255,255,255,0.5)",
      transition: "all 0.25s ease",
      "&:hover": {
        textDecoration: "none",
        borderColor: "rgba(255,255,255,1)",
      },
    },
    accentButton: {
      backgroundColor: ACCENT_COLOR,
      borderColor: ACCENT_COLOR,
      color: "rgba(255,255,255,1)",
      "&:hover": {
        backgroundColor: "white",
        color: ACCENT_COLOR,
        borderColor: ACCENT_COLOR,
      },
    },
    alert: {
      color: "#fff",
      backgroundColor: ACCENT_COLOR,
      fontSize: 16,
    },
  };

  const [packets, setPackets] = useState();

  //  `${process.env.REACT_APP_PRIVATE_API_URL}/stripe/integrate-customer`,
  useEffect(() => {
    // const lang = i18n.language === "en-US" ? "en" : i18n.language;
    let lang = i18n.language;
    if (i18n.language.startsWith("en")) lang = "en";
    else if (i18n.language.startsWith("el")) lang = "el";
    fetch(`${API_URL}/stripe/system-prices?country=${lang}`)
      .then((res) => res.json())
      .then((res) => {
        const products = res.data.map((item) => {
          return {
            name: item.nickname,
            price: item.unit_amount_decimal / 100,
            currency: item.currency,
            interval: item.recurring.interval,
            features: Object.entries(item.metadata),
          };
        });
        const freeProduct = {
          name: "Free",
          price: 0,
          currency: i18n.language === "en" ? "usd" : "eur",
          features: [
            [t("Athletes"), "50"],
            [t("Online payments"), "true"],
            [t("Online registration"), "true"],
            [t("Multi seasons & sports"), "true"],
            [t("Custom domain"), "true"],
            [t("Get registered on search page"), "true"],
            [t("Theme customization"), "true"],
            [t("News/presentation templates"), "true"],
            [t("Training attendance"), "true"],
            [t("own ads"), "false"],
          ],
        };
        const finalProducts = [...products, freeProduct];
        setPackets(finalProducts.sort((a, b) => a.price - b.price));
      });
  }, [i18n.language, t]);

  if (!packets) return "Loading...";

  return (
    <>
      <section id="pricing" style={styles.pricing}>
        <Container maxWidth="lg">
          <Typography
            sx={{
              color: BG_DARK_TEXT,
            }}
            variant="h1"
            gutterBottom
            marked="center"
          >
            {t("Reasonable Plans for everyone")}
          </Typography>
          <Typography
            sx={{
              color: BG_DARK_TEXT,
            }}
            variant="body1"
            gutterBottom
            marked="center"
          >
            {t("first_registration_packet")}
            <strong>{t("upgrade_to_paid_packet")}</strong>
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "info.main",
              bgcolor: BG_LIGHT_COLOR,
            }}
          >
            {t("stripe_fees")}
          </Typography>
          <Grid container spacing={1}>
            {packets &&
              packets.map((packet, packetIndex) => (
                <Grid key={`packet_${packetIndex}`} item lg={4} sm={6} md={4} xs={12}>
                  <Box sx={styles.pricingItem}>
                    <Typography
                      variant="h3"
                      gutterBottom
                    >
                      {t(packet.name)}
                    </Typography>
                    <Typography variant="h3">
                      {packet.price} {packet.currency === "usd" ? <AttachMoneyIcon /> : packet.currency}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {packet.interval ? `Per ${packet.interval}` : ""}
                    </Typography>
                    <Typography variant="body1" component="p">
                      {packet.descr}
                    </Typography>
                    <Grid container justifyContent="center" alignItems="flex-end" sx={styles.features}>
                      {packet.features &&
                        packet.features.map((feature, featureIndex) => (
                          <FeatureItem feature={feature} key={`${packetIndex}_feature_${featureIndex}`} />
                        ))}
                    </Grid>
                    <Grid container justifyContent="center" alignItems="flex-end" sx={styles.actions}>
                      {packet.name === "Free" && location.pathname !== "/register" && (
                        <Button
                          color="secondary"
                          size="large"
                          variant="contained"
                          sx={styles.button}
                          component={Link}
                          to="/register"
                        >
                          {t("Register")}
                        </Button>
                      )}
                      {packet.name === "Free" && location.pathname === "/register" && (
                        <Button
                          color="secondary"
                          size="large"
                          variant="outlined"
                          sx={clsx(styles.button, styles.accentButton)}
                          component={Link}
                          to={"/register-form"}
                          // href="#registration-wrapper"
                          underline="none"
                        >
                          {t("Register")}
                        </Button>
                      )}
                      {packet.name !== "Free" && t("upgrade_the_packet")}
                    </Grid>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default Pricing;
