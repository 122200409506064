import SaveIcon from "@mui/icons-material/Save";
import { Button, TextField } from "@mui/material";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import { useState } from "react";
import { useTranslation } from "react-i18next";


const ChildForm = (props) => {
  const { childMutation, initialState, childId } = props;
  const { t } = useTranslation();
  const ajv = new Ajv({ allErrors: true });
  addFormats(ajv);

  const [eachEntry, setEachEntry] = useState(initialState);
  const { lastname, firstname, birth_date } = eachEntry;
  const [error, setError] = useState([]);

  const handleInputChange = (e) => {
    const data = { [e.target.name]: e.target.value };
    const valid = ajv.validate(schema, data);
    if (!valid) {
      setError(ajv.errors);
    }
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const schema = {
    type: "object",
    required: ["firstname", "lastname", "birth_date"],
    properties: {
      firstname: { type: "string", minLength: 1, maxLength: 255 },
      lastname: { type: "string", minLength: 1, maxLength: 255 },
      birth_date: { type: "string", format: "date" },
    },
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let child = {
      lastname: lastname,
      firstname: firstname, // this.inputFirstname.current.value,
      birth_date: birth_date,
    };
    const valid = ajv.validate(schema, child);
    if (!valid) {
      setError(ajv.errors);
      return;
    }
    const vars = childId === undefined ? { input: child } : { id: childId, input: child };
    childMutation({ variables: vars });
  };
  const [lastnameError] = error.filter((err) => err.instancePath === "/lastname");
  const [firstnameError] = error.filter((err) => err.instancePath === "/firstname");
  const [birth_dateError] = error.filter((err) => err.instancePath === "/birth_date");
  // const lastnameErrorMessage = lastnameError.message;
  return (
    <>
      <form>
        <TextField
          name="lastname"
          id="lastname"
          label={t("Lastname")}
          // className={classes.textField}
          fullWidth
          error={error.filter((err) => err.instancePath === "/lastname").length !== 0}
          helperText={lastnameError !== undefined ? lastnameError.message : ""}
          defaultValue={lastname}
          onChange={handleInputChange}
          required
          margin="normal"
        />

        <TextField
          name="firstname"
          id="firstname"
          label={t("Firstname")}
          // className={classes.textField}
          onChange={handleInputChange}
          error={error.filter((err) => err.instancePath === "/firstname").length !== 0}
          helperText={firstnameError !== undefined ? firstnameError.message : ""}
          fullWidth
          required
          margin="normal"
          defaultValue={firstname}
        />
        <TextField
          name="birth_date"
          id="birth_date"
          label={t("Birth Day")}
          onChange={handleInputChange}
          error={error.filter((err) => err.instancePath === "/birth_date").length !== 0}
          helperText={birth_dateError !== undefined ? birth_dateError.message : ""}
          type="date"
          defaultValue={birth_date}
          // className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <div>
          <Button
            sx={{
              marginTop: (theme) => theme.spacing(3),
            }}
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            onClick={handleFormSubmit}
          >
            <SaveIcon />
            {t("Save")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ChildForm;
