import { useTheme } from "@emotion/react";
import { Add } from "@mui/icons-material";
import { Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import Button from "components/Button";
import { STATUS_REGISTRARTION_PENDING } from "graphql/createChildRegistration";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// add sports to the form
const AddChildButton = (props) => {
  const { profile, registrationMutation, child } = props;
  const { t, i18n } = useTranslation();
  const [sportData, setSportData] = useState([]);
  const theme = useTheme();
  const [error, setError] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!sportData?.length) {
      setError(true);
      return;
    }
    const finalSportData = sportData.map((name) => {
      const [find] = profile.sports.filter((sport) => sport.name === name)
      return {
        'id': find.sportid,
        'name': name
      }
    })
    const childRegistration = {
      userid: child.id,
      siteid: profile.siteid,
      status: STATUS_REGISTRARTION_PENDING,
      data: JSON.stringify(finalSportData)
    }
    registrationMutation({variables: { input: childRegistration }})
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSportData(value);
    if (sportData?.length!==0) {
      setError(false);
    }
  };
 // sport.translation[i18n.language] sport.name
  return (
    <>
      <Box sx={{ maxWidth: 320 }}>
      <Button sx={{ margin:1 }} variant="contained" startIcon={<Add />} color="primary" onClick={handleFormSubmit}>
        {t("Add child to sport club")}
      </Button>
      <FormControl required error={error} fullWidth>
      <InputLabel id="sports-label">{t("Sports")}</InputLabel>
      <Select
        labelId="sports-label"
        label="Sport"
        id="select-multiple-sports"
        multiple
        autoWidth
        value={sportData}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip-sport" label="Sport" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {profile.sports.map((sport) => (
          <MenuItem
            key={sport.sportid}
            value={sport.translation[i18n.language]}
            style={getStyles(sport, sportData, theme)}
          >
            {sport.translation[i18n.language]}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{t("Please select a sport")}</FormHelperText>}
      </FormControl>
      </Box>
    </>
  );
};

export default AddChildButton;
