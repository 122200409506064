import { useQuery } from "@apollo/client";
import { Avatar, Divider, List, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import useAuth from "context/AuthContext";
import { PROFILES_WITH_CHILDS_QUERY } from "graphql/getProfilesWithChilds";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import SiteAvatar from "../../../components/SiteAvatar";
import ProfileHelper from "../../../utils/ProfileHelper";


const styles = {
  badgeSubscriptions: {
    left: -3,
    // The border color match the background color.
    // border: `0px solid ${
    //   theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    // }`,
  },
  badgeChilds: {
    // The border color match the background color.
    left: 10,
    // border: `0px solid ${
    //   theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    // }`,
  },
};

const Profiles = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const classes = styles;
  const { loading, error, data } = useQuery(PROFILES_WITH_CHILDS_QUERY, {
    variables: { id: user.id },
  });
  if (loading) return <div>{t("Fetching")}</div>;
  if (error) return <div>{error.message}</div>;

  return (
    <div style={{ maxWidth: "100%" }}>
      <Typography variant="h5" gutterBottom>
        {t("My profiles")}
      </Typography>
      <Typography variant="subtitle2" style={{ marginBottom: "20px" }} color="textSecondary" gutterBottom>
        {t("These are your profiles ...")}
      </Typography>
      <List>
        {data?.siteProfiles.map((profile) => (
          <div key={profile.siteid}>
            <NavLink to={`/home/siteprofile/${profile.siteid}`} style={{ textDecoration: "none" }}>
              <ListItemButton key={profile.siteid}>
                <ListItemAvatar>
                  <Avatar className={classes.avatar} src={ProfileHelper.getProfilePhoto(profile)}></Avatar>
                </ListItemAvatar>

                <ListItemText
                  disableTypography={true}
                  primary={`${profile.lastname} ${profile.firstname}`}
                  secondary={
                    <>
                      <Typography>{`${t("in")} ${profile.site.name}`}</Typography>
                      <div style={{ marginTop: "8px", marginBottom: "5px" }}>
                        {/*<Badge badgeContent={profile.subscriptions ? profile.subscriptions.length : 0} color="secondary" classes={{ badge: classes.badgeSubscriptions }}></Badge>*/}
                        {/*<span style={{paddingLeft: '25px', paddingRight: '10px', borderRight: '1px solid #777'}}>{t('Subscriptions')}</span>*/}
                        {/*<Badge badgeContent={profile.childs ? profile.childs.length : 0} color="secondary" classes={{ badge: classes.badgeChilds }}></Badge>*/}
                        {/*<span style={{paddingLeft: '38px'}}>{t('Childs')}</span> */}

                        <span>{t("Childs")}: </span>
                        <span style={{ fontWeight: "bold" }}>{profile.childs.length}</span>
                      </div>
                    </>
                  }
                />
                <ListItemAvatar>
                  <SiteAvatar siteid={profile.siteid} />
                  {/*<Avatar className={classes.avatar} src={ProfileHelper.getSiteLogo(profile)}>
                          </Avatar>*/}
                </ListItemAvatar>
              </ListItemButton>
            </NavLink>
            <Divider />
          </div>
        ))}
      </List>
    </div>
  );
};

export default Profiles;
