import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import "./styles.css";

export const Why = (props) => {
  const { t } = useTranslation();
  return (
    <section id="why" className="why">
      <Container maxWidth="lg">
        <h1>{t("Why to choose our services")}</h1>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <h2>{t("Reasonable prices")}</h2>
            <div className="subtitle">
              <span className="inner-text">{t("free_use")}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <h2>{t("Batteries included web site")}</h2>
            <div className="subtitle">
              <span className="inner-text">{t("ready_made_site")}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <h2>{t("Multi-season and multi-sport")}</h2>
            <div className="subtitle">
              <span className="inner-text">{t("seasons_and_sports")}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <h2>{t("Get Registered at Findsportclub's search page")}</h2>
            <div className="subtitle">
              <span className="inner-text">{t("access_new_members")}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <h2>{t("Data Privacy")}</h2>
            <div className="subtitle">
              <span className="inner-text">{t("data_privacy_text")}</span>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Why;
