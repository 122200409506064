import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useContext } from "react";
import { AppDataContext } from "../context/index";
import GeoSearchFormHeader from "./GeoSearchFormHeader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function MobileFiltersDialog(props) {
  const { filters, setFilters, handleSubmit } = props;
  const [open, setOpen] = React.useState(false);
  
  const styles = {
    appBar: {
      position: "relative",
      backgroundColor: "#fff",
      color: "black",
    },
    title: {
      marginLeft: (theme) => theme.spacing(1),
      // flex: 1,
      fontSize: 12,
      color: "#5c5c5c",
    },
    toggleFiltersButton: {
      // marginRight: "auto",
      fontSize: "0.9rem",
      // fontWeight: 50,
      // textTransform: 'initial',
      backgroundColor: "rgb(238, 238, 238)",
      color: "#5c5c5c",
      maxWidth:"57vw",
      borderRadius: 2,
      justifyContent: "flex-start",
      height: 32,
      overflow: "hidden",
      // whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      // display: "flex",
      flexGrow: 1,
      border: "1px solid",
      // borderColor: theme.palette.secondary.main // #8BC34A'
    },
  };

  const dataContext = useContext(AppDataContext);

  const { sports } = dataContext;
  var sport = null;
  if (filters.sportid) {
    sport = sports.filter((s) => {
      return s.sportid === filters.sportid;
    })[0].name;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // console.log('header sport',filters, sports, sport)
  return (
    <>
      <Button
        sx={styles.toggleFiltersButton}
        startIcon={<SearchIcon style={{ marginLeft: 0.5 }} />}
        onClick={handleClickOpen}
      >
        <Typography style={{ fontSize: "0.9rem" }} noWrap component="span">
          {filters.sportid ? sport : "All sports"} near {filters.address}, in {filters.distance} km radius
        </Typography>
      </Button>

      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
         Open full-screen dialog
       </Button> */}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={styles.appBar} elevation={0}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="medium">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={styles.title}>
              Filters
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <GeoSearchFormHeader
          mobileMode={true}
          filters={filters}
          setFilters={setFilters}
          handleSubmit={handleSubmit}
          setOpen={setOpen}
        />
      </Dialog>
    </>
  );
}
