import { useQuery } from '@apollo/client';
import UserIcon from "@mui/icons-material/AccountCircle";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { PERSON_QUERY } from "graphql/getPerson";
import React from "react";
import { NavLink } from "react-router-dom";

const AppDrawerUser = props => {
  const { loading, error, data } = useQuery(PERSON_QUERY, {
    variables: { id: props.user.id }
  })
  if (loading) return <div>Fetching</div>;
  if (error) return <div>Error</div>;

  return (
    <div>
      <List>
        <NavLink to="/home/profile" style={{ textDecoration: "none" }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar alt="User Name">
                <UserIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={data.person.lastname}
              secondary={data.person.firstname}
            />
          </ListItem>
        </NavLink>
      </List>
    </div>
  );
};

export default AppDrawerUser;