import ArrowLeftIcon from "@mui/icons-material/ChevronLeft";
import ArrowRightIcon from "@mui/icons-material/ChevronRight";
import Fab from "@mui/material/Fab";
import clsx from "clsx";
import { useEffect } from "react";
import Slider from "react-slick";
// import { AppDataContext } from '../../../context/index';
import { useTheme } from "@mui/system";
import SiteHelper from "../../../utils/SiteHelper";

// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";

// const API_URL = process.env.REACT_APP_PUBLIC_API_URL;

function NextArrow(props) {
  const { /* sx, style, */ onClick, arrowClassName } = props;
  return (
    <div>
      <Fab sx={arrowClassName} color="primary" aria-label="previous slide" onClick={onClick}>
        <ArrowRightIcon />
      </Fab>
    </div>
  );
}

function PrevArrow(props) {
  const { /* sx, style, */ onClick, arrowClassName } = props;
  return (
    <div>
      <Fab sx={arrowClassName} color="primary" aria-label="next slide" onClick={onClick}>
        <ArrowLeftIcon />
      </Fab>
    </div>
  );
}

function AcademyProfileGallery(props) {
  // const dataContext = useContext(AppDataContext);
  // const {getSiteAddress} = dataContext;

  const { site } = props;
  const gallery = site && site.gallery ? site.gallery : null;
  // const [loading, setLoading] = useState(true);
  const defaultImage = SiteHelper.getSiteDefaultImage({ site, thumb: false });
  const theme = useTheme();
  const styles = {
    background: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      zIndex: -2,
      backgroundImage: `url(${defaultImage})`,
      backgroundPosition: "center center",
    },
    gallerySlide: {
      height: "24vw",
      [theme.breakpoints.down("lg")]: {
        height: "33.33vw",
      },
      [theme.breakpoints.down("sm")]: {
        height: "66.67vw",
      },
      // padding-bottom: 66.67%;
    },
    slideContent: {
      height: "100%",
      paddingBottom: 0,
      width: "100%",
      overflow: "hidden",
      position: "relative",
    },
    slideImage: {
      height: "100%",
      width: "auto",
      // trick to fill slide: https://stackoverflow.com/questions/16739908/make-image-fill-div-completely-without-stretching
      position: "relative",
      verticalAlign: "middle",
      top: "50%",
      transform: "translateY(-50%)", // https://material-ui.com/styles/advanced/
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
    },
    arrow: {
      position: "absolute",
      zIndex: 1,
      width: 48,
      height: 48,
      top: "45%",
      backgroundColor: "#d5d5d5",
    },
    leftArrow: {
      left: 16,
    },
    rightArrow: {
      right: 16,
    },
  };

  // const styles = useStyles();

  const siteUrl = SiteHelper.getSiteUrl(site);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // arrows: false // we create custom arrows,
    nextArrow: <NextArrow arrowClassName={clsx(styles.arrow, styles.rightArrow)} />,
    prevArrow: <PrevArrow arrowClassName={clsx(styles.arrow, styles.leftArrow)} />,
  };

  // const settingsDefault = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1
  // };

  useEffect(
    () => {
      // NOW  PROFILE GALLERY AND ITS IMAGES ARE LOADED ON APP INIT, SEE /koarestserver/models/site
      // setLoading(true);
      // fetch(`${API_URL}/gallery/${siteid}`)
      // .then(res => res.json())
      // .then(res => {
      //   console.log('gallery',res);
      //   // test for empty object => {}
      //   if(Object.keys(res).length > 0)
      //     setGallery(res);
      //   setLoading(false);
      // });
    },
    [
      /* siteid */
    ]
  );

  let content = "";

  // if(loading)
  //   return <h1>Loading</h1>

  // profile gallery not exists so we display a default image
  if (!gallery) {
    content = (
      <div style={styles.slideImage}>
        <div style={styles.background}>

        </div>
      </div>
    );
  } else {
    // gallery exists display cover first and gallery images after
    content = (
      <div style={{ position: "relative" }}>
        <Slider {...settings}>
          {gallery.photo && (
            <div style={clsx("slick-slide", styles.gallerySlide)}>
              <div style={styles.slideContent}>
                <img
                  style={styles.slideImage}
                  src={`${siteUrl}/admin/uploads/${site.siteid}/gallery/${gallery.id}/${gallery.photo}`}
                  imgprops={{
                    onError: (e) => {
                      e.target.src = defaultImage;
                    },
                  }}
                  alt="mainPhoto"
                />
              </div>
            </div>
          )}
          {gallery.files &&
            gallery.files.map((file) => (
              <div style={clsx("slick-slide", styles.gallerySlide)} key={file.id}>
                <div style={styles.slideContent}>
                  <img
                    style={styles.slideImage}
                    src={`${siteUrl}/admin/uploads/${site.siteid}/gallery/${gallery.id}/${file.file}`}
                    imgprops={{
                      onError: (e) => {
                        e.target.src = defaultImage;
                      },
                    }}
                    alt=""
                  />
                </div>
              </div>
            ))}
        </Slider>
      </div>
    );
  }

  return content;
}

export default AcademyProfileGallery;
