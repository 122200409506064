import isValueAvailableAsync from "utils/formHelpers";

const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
const registrationFormEndpoint = PUBLIC_API_URL + '/user/register';

const userSubmission = async (siteid, setEmail, setSubmissionCompleted, values, actions) => {
    // SOS MUST READ: https://stackoverflow.com/questions/52986962/how-to-properly-use-formiks-seterror-method-react-library
    // https://codesandbox.io/s/nw193yq32l
    // A way to keep backend errors with client validation https://github.com/jaredpalmer/formik/issues/150

    // actions contains setSubmitting etc...
    // was: { (values, {setSubmitting})=>  }
    // https://github.com/jaredpalmer/formik/issues/486

    // DO SERVER/API CHECKS BEFORE ACTUAL SUBMIT HERE...
    // setErrors will treat like a normal field error which on next onchange() or onblur() in any field will dissappear from display.
    // So for backend errors (like email, username) it is better to use setStatus to display api errors in a separate section
    actions.setStatus(undefined);
    let serverErrors = {};
    // κανονικα εδω θα πρεπει να γινει το submit και να επιστρεψει ο server τα validation errors αλλα προς το παρον το κανουμε με ξεχωριστες κλησεις

    if (values.email) {
      const emailFound = await isValueAvailableAsync(
        'user',
        'email',
        values.email
      );
      if (emailFound) {
        serverErrors = {
          ...serverErrors,
          email: `Email ${values.email} is already in use`,
        };
      }
  
    }

    // check mobilephone availability only if not empty
    if (values.mobilephone) {
      const mobilephoneAvailableFound = await isValueAvailableAsync(
        'user',
        'mobilephone',
        values.mobilephone
      );
      if (mobilephoneAvailableFound) {
        serverErrors = {
          ...serverErrors,
          mobilephone: `Mobile phone ${values.mobilephone} is already in use`,
        };
      }
    }
    // console.log(values)
    // ABORT IF SERVER/API VALIDATIONS FAIL...
    if (Object.keys(serverErrors).length) {
      actions.setStatus(serverErrors); // this will allow us to display server errors in a separate section
      actions.setErrors(serverErrors); // this will display server errors in each field's helper as client validation errors
      actions.setSubmitting(false);
      setSubmissionCompleted(false);
      return false;
    }
    // IF NO SERVER ERRORS CONTINUE WITH THE SUBMIT
    actions.setSubmitting(true);
    let reqData = {
      user: {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        address: values.address,
        mobilephone: values.mobilephone,
        password: values.password,
        passwordConfirm: values.passwordConfirm,
      },
    };
    if (siteid) {
      // If register for specific academy add the additional data to the request
      // sports is an array of objects e.g. [{sportid: 1, name: 'football'}, ...] so extract the ids
      let sportsIds = [];
      values.sports.forEach((sport) => {
        sportsIds.push(sport.sportid);
      });

      reqData = {
        ...reqData,
        childs:values.childs,
        user_registration: {
          siteid: siteid,
          comments: values.comments,
          data: {
            role: values.role,
            sports: sportsIds.toString(),
          },
        },
      };
    }
    // for credentials etc
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    fetch(registrationFormEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        // api returns 'success'
        if (res.message !== 'success') {
          throw new Error('Server API error');
        }
        actions.setSubmitting(false);
        setSubmissionCompleted(true);
        setEmail(values.email);
        actions.setStatus(null);
        // setSubmissionError(null);
      })
      .catch((error) => {
        // network failure or if anything prevented the request from completing
        actions.setSubmitting(false);
        setSubmissionCompleted(false);
        actions.setStatus({ error: error.message });
        // setSubmissionError(error);
        setEmail(null);

        // alternative (but server must send back the validation errors with same field names as in client validation schema)
        // const fieldErrorsFromResponse = error.response.data.field_errors;
        // fieldErrorsFromResponse from server has {'field_errors': {'email': 'Invalid'}}
        // if (fieldErrorsFromResponse !== null){
        //  setErrors(fieldErrorsFromResponse);
        // }
      });
  }

  export default userSubmission;