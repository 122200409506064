// ui
import { useTheme } from "@emotion/react";
import { Box, Toolbar } from "@mui/material";
import useAuth from "context/AuthContext";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import MyAppBar from "./components/AppBar";
import AppDrawer from "./components/AppDrawer";
import AppDrawerOverlay from "./components/AppDrawerOverlay";
// sidebar width

const pageStyle = (theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
});

const HomeLayout = (props) => {
  const drawerWidth = 280;

  const theme = useTheme();
  const location = useLocation();

  // xs, sm, md : drawer is closed by default or auto closes when resizing - when opened is above content(overlay)
  // lg, xl : drawer is opened by default, user can close/open, drawer shifts content
  // state of the drawer in desktop (persistent) or mobile (temporary) screen.
  // When searching for academies we autohide the drawer.
  const [open, setOpen] = useState(true);
  const [searchClose, setSearchClose] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (location.pathname.indexOf("/search") !== -1) {
      setSearchClose(false);
    } else if (!searchClose) {
      setSearchClose(true);
      setOpen(true);
    }
  }, [location, searchClose, setOpen]);

  // const onRouteChange = (route) => {
  //   // Handle what you need to do here
  //   // if you need to redirect you can do this
  //   // this.props.history.replace({pathname: '/desiredRoute'});

  //   //  autohide the drawer for searching
  //   if (route.pathname.indexOf("/search") !== -1) setOpen(false);
  // };
  const { user } = useAuth();
  const classes = pageStyle(theme);
  return (
    <Box sx={{ display: "flex" }}>
      {/* pass method handleDrawerOpen & handleDrawerClose as prop so the child component can call it and change the parent state */}
      <MyAppBar user={user} searchClose={searchClose} handleDrawerToggle={handleDrawerToggle} open={open} />

      <Box
        component="nav"
        sx={{
          // width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
      >
        <AppDrawerOverlay
          width={drawerWidth}
          classes={classes}
          user={user}
          open={open}
          searchClose={searchClose}
          theme={theme}
          onClose={handleDrawerToggle}
        />
        <AppDrawer searchClose={searchClose} width={drawerWidth} user={user} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: 0,
          ...(open && {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${drawerWidth}px`,
          }),
          p: (theme) => theme.spacing(1),
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default HomeLayout;
