import { useTheme } from "@emotion/react";
import { FormControl } from "@mui/material";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import DistanceSlider from "./DistanceSlider";
import GoogleMapsAutocomplete from "./common/GoogleMapsAutocomplete";
import SportSelector from "./common/SportSelector";

// for form
// https://stackblitz.com/edit/react-7wuxzm?file=AddressForm.js

// other example
// https://github.com/fulin426/Amp-Search

// const GMAPS_API_KEY = process.env.REACT_APP_GMAPS_API_KEY;

const GeoSearchFormHeader = (props) => {
  const { filters, setFilters, mobileMode, setOpen } = props;
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  // we use it to:
  // a) pass the initial value entered by user in landing page to places automplete component in search page
  // b) sync underlying input value with selected suggestion
  // https://stackoverflow.com/questions/51692830/mui-places-autocomplete-how-to-subscribe-to-the-clear-text-field-event
  // const [placeInputValue, setPlaceInputValue] = useState(filters.address ? filters.address : "");
  // const [expanded, setExpanded] = useState(false);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    // console.log('submit',ev.target);
    // setCurrentSuggestion(null);
    if (setOpen) setOpen(false);
    props.handleSubmit(ev);
  };

  return (
    <form 
    style={{ 
      flexGrow: 2,
      // gap: (theme) => theme.spacing(3),
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: mobileMode ? "column" : "row"
      // alignItems: "baseline",
      // flexFlow: "wrap",
}} 
    autoComplete="off" onSubmit={handleSubmit}>
      {/* <Box
        sx={{
          // flexGrow: "2",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "baseline",
          flexFlow: "row wrap",
          // [theme.breakpoints.down("md")]: {
          //   flexDirection: "column",
          //   width: "100%",
          //   alignItems: "stretch",
          // },
        }}
      > */}
        <SportSelector sx={
          {
            // textAlign: "left",
            margin: (theme) => theme.spacing(1),
            minWidth: 180,
            [theme.breakpoints.down("md")]: {
              // display: "block",
              // marginTop: (theme) => theme.spacing(4),
            },      
          }
        } t={t} filters={filters} setFilters={setFilters} lang={i18n.language} />
        <FormControl
          sx={
            {
              // textAlign: "left",
              // display: "flex",
              // flexDirection: "column"
              // fwrap: wrap;
              margin: theme.spacing(1),
              // marginLeft: theme.spacing(2),
              // marginRight: theme.spacing(2),
              minWidth: (theme) => '20vw',
              // [theme.breakpoints.down("md")]: {
              //   display: "block",
              //   width: "100%",
              //   marginTop: theme.spacing(4),
              // },
            }
          }
        >
        <GoogleMapsAutocomplete filters={filters} setFilters={setFilters} />
        {/* we use the textFieldProps to pass the initial value and to sync the value with selected suggestion */}
        </FormControl>
        {/* <IconButton
          className={classes.selectClearButton}
          style={{ visibility: showPlaceClear ? "visible" : "hidden" }}
          onClick={handleClickClear}
          title="Clear"
          aria-label="Clear"
          size="large">
          <ClearIcon style={{ fontSize: "1rem" }} />
        </IconButton>
        <IconButton
          className={classes.selectUsePositionButton}
          onClick={handleClickCurrentLocation}
          title="Use current location (approximately)"
          aria-label="Use current location (approximately)"
          size="large">
          <CurrentLocationIcon style={{ fontSize: "1rem" }} />
        </IconButton> */}
        <FormControl
          sx={
            {
              // textAlign: "left",
              // marginLeft: theme.spacing(2),
              // marginRight: theme.spacing(2),
              margin: theme.spacing(1),
              minWidth: (theme) => theme.spacing(20),
              // [theme.breakpoints.down("md")]: {
              //   display: "block",
              //   marginTop: theme.spacing(4),
              // },
            }
          }
        >
        <DistanceSlider filters={filters} setFilters={setFilters} fullWidth />
        </FormControl>

        <Button
          sx={
            {
              // marginRight: theme.spacing(2),
              // marginLeft: theme.spacing(4),
              minWidth: (theme) => theme.spacing(20),
              margin: theme.spacing(2),
              // // paddingLeft: theme.spacing(4),
              // // paddingRight: theme.spacing(4),
              // [theme.breakpoints.down("md")]: {
              //   marginTop: theme.spacing(2),
              //   marginLeft: theme.spacing(2),
              //   marginRight: theme.spacing(2),
              //   // padding: "10px 20px",
              // },
            }
          }
          type="submit"
          color="secondary"
          variant="contained"
        >
          {t("Apply Filters")}
        </Button>
      {/* </Box> */}
    </form>
  );
};

export default GeoSearchFormHeader;
