import { gql } from "@apollo/client"

export const CHILD_UPDATE = gql`mutation UpdateChild ($id: ID!, $input: ChildInput!) {
  updateChild (id: $id, child: $input) {
  lastname,
  firstname,
  address,
  id
  }
}`
