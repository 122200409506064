import { FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { Field } from "formik";
import { useTranslation } from "react-i18next";


const RegistrationMembersSelect = (props) => {
    const { 
        FormikSportsSelect,
        site,
        formik,
        MemberRoles,
        sports,
        // initialValues,
        setShowChilds
    } = props
    const { t, i18n } = useTranslation();
    // const [showChilds, setShowChilds] = useState(initialValues.role !== MemberRoles.ROLE_PLAYER);
    const helpText = (name, formikComp) => {
        return formikComp.touched[name] && t(formikComp.errors[name]);
      };

    return (
        <>
        <Grid item xs={12}>
          <Field
            component={FormikSportsSelect}
            name="sports"
            label={t("available_sports_label")}
            id="field1" //  fix browser force autocomplete
            getOptionLabel={(option) => (typeof option === "string" ? option : t(option.translation[i18n.language]))}
            options={site ? site.sports : sports}
            error={formik.touched.sports && Boolean(formik.errors.sports)}
            helperText={helpText("sports", formik)}
            // renderInput={(params) => <TextField {...params} label={props.label} />}
          />
          <FormHelperText>
            {t("show_sports_of_club")}
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <FormLabel component="legend" style={{ textAlign: "left", marginTop: 20 }}>
            {t("What will be your role?")}
          </FormLabel>
          <RadioGroup
            name="role"
            value={formik.values.role}
            onChange={(ev) => {
              formik.handleChange(ev);
              setShowChilds(ev.currentTarget.value !== MemberRoles.ROLE_PLAYER);
            }}
          >
            {MemberRoles.roles.map((role, index) => (
              <FormControlLabel
                value={role.value}
                control={<Radio disabled={formik.isSubmitting} />}
                label={t(role.label)}
                disabled={formik.isSubmitting}
                key={`role_${index}`}
              />
            ))}
          </RadioGroup>
        </Grid>
      </>
    )
}

export default RegistrationMembersSelect