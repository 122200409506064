import { useMutation, useQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import SuccessComponent from "components/common/SuccessComponent";
import ErrorComponent from "components/error-handling/ErrorComponent";
import { CHILD_QUERY } from "graphql/getChild";
import { CHILD_UPDATE } from "graphql/updateChild";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import ChildForm from "./childForm";

const UpdateChild = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const childQuery = useQuery(CHILD_QUERY, {
    variables: { id },
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [updateChild, childUpdateMutation] = useMutation(CHILD_UPDATE, {
    onCompleted(data) {
      setIsSuccess(true);
      setTimeout(() => setIsSuccess(false), 5000);
    },
    onError() {
      setIsError(true);
      setTimeout(() => setIsError(false), 8000);
    },
  });
  const loading = childQuery.loading || childUpdateMutation.loading;
  const error = childQuery.error || childUpdateMutation.error;
  if (loading) return <div>{t("Fetching")}</div>;

  const child = childQuery.data.child;
  const initialInputState = { lastname: child.lastname, firstname: child.firstname, birth_date: child.birth_date };

  return (
    <>
      <Stack spacing={2} alignItems="center">
        <Typography variant="h5" gutterBottom>
          {t("Update child")}
        </Typography>
        <SuccessComponent open={isSuccess} type="success" message={t("Child Updated")} />
        {error && <ErrorComponent open={isError} error={error} />}

        <ChildForm
          childId={child.id}
          childMutation={updateChild}
          initialState={initialInputState}
          successMessage={t("Child Updated")}
        />
      </Stack>
    </>
  );
};

export default UpdateChild;
