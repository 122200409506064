import { Alert, Button, Collapse, Stack } from "@mui/material";
import { useState } from "react";

const StripeCustomerIntegration = (props) => {
  const [error, setError] = useState(null);
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // make a post request to server to redirect
    const token = sessionStorage.getItem("access_token");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
      body: JSON.stringify({ siteid: props.siteid }),
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_PRIVATE_API_URL}/stripe/integrate-customer`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          response.json().then((res) => {
            setError(res.message);
            setIsError(true);
            setTimeout(() => setIsError(false), 8000);
          });
        } else {
          response.json().then((res) => {
            window.location = res.url;
          });
        }
      })
      .catch((error) => {
        setError(error);
        setIsError(true);
        setTimeout(() => setIsError(false), 8000);
      });
  };

  return (
    <> <Stack spacing={2} alignItems="center">
      {error && (
        <Collapse in={isError}>
          <Alert severity="error">{error}</Alert>
        </Collapse>
      )}
      <form method="POST" onSubmit={handleSubmit}>
        <Button type="submit" variant="contained" color="primary">
          Manage billing
        </Button>
      </form>
      </Stack>
    </>
  );
};

export default StripeCustomerIntegration;
