import { Alert, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { Formik } from "formik";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
const contactFormEndpoint = PUBLIC_API_URL + "/user/contact"; //'process.env.REACT_APP_CONTACT_ENDPOINT;

function Contact(props) {
  const { t } = useTranslation();
  const styles = {
    hero: {
      marginBottom: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    textField: {
      width: "100%",
      marginTop: 0,
    },
    button: {
      padding: "15px 40px",
    },
  };
  const [open, setOpen] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [isSubmissionCompleted, setSubmissionCompleted] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  const recaptchaRef = React.createRef();

  function onChange(value) {
    setCaptcha(value);
  }

  function handleClose() {
    setOpen(false);
  }
  const handleClickRetry = (event) => {
    recaptchaRef.current.reset();
    setSubmissionError(false);
    setSubmissionCompleted(false);
  };

  function handleClickOpen() {
    setSubmissionCompleted(false);
    setOpen(true);
  }

  return (
    <Box sx={{ mt: (theme) => theme.spacing(5) }}>
      <Box sx={{ mt: (theme) => theme.spacing(5) }}>
        <Typography variant="h4" gutterBottom>
          {t("question_start")}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {t("question_end")}
        </Typography>
      </Box>
      <Button sx={styles.button} variant="contained" color="primary" onClick={handleClickOpen} size="large">
        {t("Contact us!")}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {!isSubmissionCompleted && (
          <>
            <DialogTitle id="form-dialog-title">{t("Contact")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("Send us a comment!")}</DialogContentText>
              <Formik
                initialValues={{
                  email: "",
                  name: "",
                  comment: "",
                  captcha: "",
                }}
                onSubmit={(values, actions) => {
                  actions.setStatus(undefined);
                  const reqData = {
                    email: values.email,
                    name: values.name,
                    comment: values.comment,
                    captcha: captcha,
                  };

                  fetch(contactFormEndpoint, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(reqData),
                  })
                    .then((res) => res.json())
                    .then((res) => {
                      if (res.message !== "success") {
                        throw new Error(res.message.errors[0].message);
                      }
                      actions.setSubmitting(false);
                      setSubmissionError(false);
                      setSubmissionCompleted(true);
                      actions.setStatus(null);
                      // setSubmissionError(null);
                    })
                    .catch((error) => {
                      // network failure or if anything prevented the request from completing
                      actions.setSubmitting(false);
                      setSubmissionCompleted(false);
                      setSubmissionError(error);
                      actions.setStatus({ error: error.message });

                      // alternative (but server must send back the validation errors with same field names as in client validation schema)
                      // const fieldErrorsFromResponse = error.response.data.field_errors;
                      // fieldErrorsFromResponse from server has {'field_errors': {'email': 'Invalid'}}
                      // if (fieldErrorsFromResponse !== null){
                      //  setErrors(fieldErrorsFromResponse);
                      // }
                    });
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email().required("Required"),
                  name: Yup.string().required("Required"),
                  comment: Yup.string().required("Required"),
                  // captcha: Yup.string().required('Required')
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            label="name"
                            name="name"
                            sx={styles.textField}
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.name && touched.name && errors.name}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={errors.email && touched.email}
                            label="email"
                            name="email"
                            sx={styles.textField}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.email && touched.email && errors.email}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="comment"
                            name="comment"
                            sx={styles.textField}
                            value={values.comment}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.comment && touched.comment && errors.comment}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onChange}
                          />
                        </Grid>
                      </Grid>
                      <DialogActions>
                        <Button type="button" sx="outline" onClick={handleReset} disabled={!dirty || isSubmitting}>
                          Reset
                        </Button>
                        <Button type="submit" disabled={isSubmitting} variant="contained" color="secondary">
                          Submit
                        </Button>
                        {/* <DisplayFormikState {...props} /> */}
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </>
        )}
        {submissionError && (
          <>
            <DialogContent>
              <DialogContentText>
                <Alert severity="error">An error ocurred. {submissionError.message}</Alert>
              </DialogContentText>
              <DialogActions>
                <Button variant="contained" sx={styles.button} color="secondary" onClick={handleClickRetry}>
                  {t("Please try again")}
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        )}
        {isSubmissionCompleted && (
          <>
            <DialogTitle id="form-dialog-title">Thanks!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Alert severity="success">Thank you for contact us</Alert>
              </DialogContentText>
              <DialogActions>
                <Button type="button" sx="outline" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        )}
      </Dialog>
    </Box>
  );
}
export default Contact;
