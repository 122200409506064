import { gql } from "@apollo/client"

export const CREATE_STRIPE_SUBSCRIPTION = gql`mutation CreateStripeSubscription ($input: StripeSubscriptionInput!) {
  createStripeSubscription (subscription: $input) {
  id,
  created,
  current_period_end,
  status,
  latest_invoice
  }
}`
