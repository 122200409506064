import { CheckCircle } from "@mui/icons-material";
import {
  Container,
  Divider, Grid, Link as MuiLink, Typography
} from "@mui/material";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import MemberFeatures from "../../../components/MemberFeatures";
import { AppDataContext } from "../../../context/index";
import UserRegistrationForm from "./components/UserRegistrationForm";
import userSubmission from "./userSubmission";

const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;

// MAIN
const UserRegistration = () => {
  const { siteid } = useParams(); // can be null which means signup without specific site
  const { sports } = useContext(AppDataContext);


  const styles = {
    sectionTitle: {
      textTransform: "initial",
      fontSize: 30,
    },
    buttonWrapper: {
      position: "relative", // for circular progress to displayed absolutery in this
    },
    buttonProgress: {
      color: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      width: 32,
      height: 32,
      marginTop: -16,
      marginLeft: -16,
    },
    registerSuccess: {
      marginTop:(theme) => theme.spacing(4),
      marginBottom:(theme) => theme.spacing(4),
      minHeight: 400,
    },
    buttonSuccess: {
      marginTop:(theme) => theme.spacing(4),
      marginBottom:(theme) => theme.spacing(4),
    },
    successIcon: {
      fontSize: 72,
      color:(theme) => theme.palette.secondary.main,
    },
    errorIcon: {
      fontSize: 48,
      color:(theme) => theme.palette.error.dark,
    },
    loginLink: {
      fontSize: 18,
      color:(theme) => theme.palette.secondary.main,
      margin:(theme) => theme.spacing(0, 1),
    },
    consentLink: {
      fontSize: 14,
      color:(theme) => theme.palette.secondary.main,
      margin:(theme) => theme.spacing(0, 1),
    },
    status: {
      backgroundColor:(theme) => theme.palette.error.light,
      padding:(theme) => theme.spacing(1),
      margin:(theme) => theme.spacing(2, 1, 1, 1),
    },
    selectLabel: {
      textAlign: "left",
      marginTop:(theme) => theme.spacing(2),
    },
    selectField: {
      textAlign: "left",
    },
    childsShow: {
      transition: "opacity 0.5s",
      opacity: 1,
    },
    childsHide: {
      opacity: 0,
      pointerEvents: "none",
    },    
  }
  const [site, setSite] = useState();
  const [isSubmissionCompleted, setSubmissionCompleted] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (siteid) {
      fetch(`${PUBLIC_API_URL}/sites/${siteid}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          // catch network error
          if (!res.ok) throw new Error("Network response was not ok");
          return res.json(); // pass json to chain
        })
        .then((res) => {
          //catch api error
          setSite(res);
        })
        .catch(() => {
          setError("Failed to fetch site data");
        });
    }
  }, [siteid]);

  // because form is hidden after submission we must scroll manually the window to show the success/error
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isSubmissionCompleted]);

  return (
    <>
      {!isSubmissionCompleted && (
        <Container maxwidth="lg" sx={{marginTop: (theme) => theme.spacing(10)}}>
          <Typography
            sx={styles.sectionTitle}
            variant="h1"
            component="h1"
            gutterBottom
            marked="center"
            align="center"
          >
            {siteid ? t("Sign_up_to",{ "siteid": siteid}) : t("Sign up")}
          </Typography>
          <Typography variant="subtitle1" component="h3" gutterBottom marked="center" align="center">
            {t("sign_up_message")}
          </Typography>
          <Typography variant="subtitle2" component="h3" gutterBottom marked="center" align="center">
            {t("registration_guide")}
          </Typography>

          {!siteid && (
            <Grid container alignItems="center" justifyContent="center" style={{ marginTop: 32 }}>
              <Grid item xs={12}>
                <div>
                  <span style={{ fontSize: 18, marginRight: 8 }}>{t("specific_club")}</span>
                  <Button
                    color="secondary"
                    size="small"
                    sx={styles.loginLink}
                    style={{ fontSize: 18 }}
                    component={Link}
                    to="/search"
                  >
                   {t("search_clubs")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
          <UserRegistrationForm 
          onSubmit={userSubmission} 
          siteid={siteid}
          site={site} 
          setEmail={setEmail}
          setSubmissionCompleted={setSubmissionCompleted}
          sports={sports} 
          classes={styles}
          />
        </Container>
      )}

      {isSubmissionCompleted && (
        <Container maxwidth="lg" sx={styles.registerSuccess}>
          <div style={{ margin: 32 }}>
            <CheckCircle sx={styles.successIcon} />
          </div>
          <Typography variant="h3" style={{ fontSize: 20, textTransform: "initial" }}>
            {t("thank_you_for_registration")}
          </Typography>
          <Typography
            style={{
              fontSize: 18,
              textTransform: "lowercase",
              color: "#3F51B5",
              margin: "25px 0",
            }}
            variant="subtitle2"
          >
            {email}
          </Typography>
          <Typography
            variant="h3"
            style={{
              fontSize: 18,
              textTransform: "initial",
              fontWeight: "normal",
            }}
          >
             {t("check_inbox")}
          </Typography>

          <Grid container spacing={2} justifyContent="center" alignItems="center" sx={styles.buttonSuccess}>
            <Button variant="contained" component={Link} sx={styles.button} color="secondary" to={"/"}>
            {t("Return to home")}
            </Button>
          </Grid>
        </Container>
      )}

      {/* do not display if submission completed */}
      {!isSubmissionCompleted && (
        <>
          <Grid container maxwidth="sm" alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <div>
                <span style={{ fontSize: 18, marginRight: 8 }}>{t("Already have an account?")}</span>
                <Link to="/login" sx={styles.loginLink} style={{ fontSize: 18 }}>
                  {t("Login")}
                </Link>
              </div>
              <div style={{ marginTop: 16 }}>
                <span>
                  <Trans i18nKey='agree_terms'>
                  By singing up or logging in you agree to our
                  {/* {t("By singing up or logging in you agree to our")} */}
                  <MuiLink sx={styles.consentLink} href="/terms" target="_blank">
                    {/* {t("terms of service")} */}
                    terms of service
                  </MuiLink>
                  and
                  <MuiLink sx={styles.consentLink} href="/privacy" target="_blank">
                    {/* {t("privacy policy")} */}
                    privacy policy
                  </MuiLink>
                  </Trans>
                </span>
              </div>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: 80 }} />
          <MemberFeatures />
        </>
      )}
    </>
  );
};

export default UserRegistration;
