import LoginIcon from "@mui/icons-material/LockOpen";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import smallLogo from "../images/applogo-small.png";
// import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import GeoSearchFormHeader from "./GeoSearchFormHeader";
import MobileFiltersDialog from "./MobileFiltersDialog";


const SearchHeader = (props) => {
  const { t } = useTranslation();
  const styles = {
    root: {
      flexGrow: 1,
    },
    menuButton: {
      // marginLeft: -12,
      marginRight: (theme) => theme.spacing(1),
    },
    // menuButton: {
    //   marginRight: theme.spacing(2),
    // },
    logoIcon: {
      color: (theme) => theme.palette.secondary.main,
      width: "1.5em",
      height: "1.5em",
    },
    logoLink: {
      marginRight: 20, // 'auto'
    },
    mobileLogoLink: {
      marginRight: 10, // 'auto'
    },
    loginButton: {
      color: "black",
    },
    title: {
      flexGrow: 1,
      textAlign: "left",
      marginLeft: (theme) => theme.spacing(1),
      marginTop: 3,
      // color: 'white'
    },
    header: {
      // color: 'black'
      //background: "white",
      position: "absolute",
      // borderBottom: '1px solid #eee',
      borderBottom: "1px solid #f9f9f9",
      backgroundColor: "#fdfdfd",
      // top: (props) => (props.dashboardMode ? 10 : 0), // display search toolbar below dashboard appbar
    },
    buttonIcon: {
      marginRight:(theme) => theme.spacing(1),
    },    
  }

  const useIsWidthDown = (breakpoint) => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down(breakpoint));
  }

  const isWidthDown = useIsWidthDown("md", props.width);
  const desktopMode = !isWidthDown;

  // revert to position fixed, to enable hide on scroll uncomment component HideOnScroll
  return (
    <>
      {/* <CssBaseline /> */}
      {/*<HideOnScroll {...props}>*/}
      <AppBar elevation={0} sx={styles.header}>
        <Toolbar sx={{
            display: "flex",
            justifyContent: "space-between",
            flexFlow: "row wrap"
            // justifyContent: "center",
            // flexDirection: "row"
          }}>
          {!props.dashboardMode && (
            <Link sx={desktopMode ? styles.logoLink : styles.mobileLogoLink} to="/">
              <img src={smallLogo} style={{ height: 38 }} alt="" />
            </Link>
          )}

          {desktopMode ? (
            <>
              <GeoSearchFormHeader
                filters={props.filters}
                setFilters={props.setFilters}
                handleSubmit={props.handleSubmit}
                orderOptions={props.orderOptions}
              />
              {!props.dashboardMode && (
                <>
                  <Button
                    color="secondary"
                    sx={styles.menuButton}
                    variant="outlined"
                    component={Link}
                    to="/register"
                  >
                   {t("Register Sport Club")}
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    sx={styles.loginButton}
                    component={Link}
                    to="/login"
                  >
                    <LoginIcon sx={styles.buttonIcon} />
                    {t("Sign in")}
                  </Button>
                </>
              )}
            </>
          ) : (
            <Box sx={{flexFlow: "row wrap"}}>
              <MobileFiltersDialog
                filters={props.filters}
                setFilters={props.setFilters}
                handleSubmit={props.handleSubmit}
                orderOptions={props.orderOptions}
              />
              
                <IconButton
                  title="login"
                  color="secondary"
                  variant="outlined"
                  component={Link}
                  to="/login"
                  size="large">
                  <LoginIcon />
                </IconButton>
            </Box>
          )}
        </Toolbar>
        {/* <Toolbar id="page-scrolltop-anchor" /> */}
      </AppBar>
      {/*</HideOnScroll>*/}
    </>
  );
}

export default SearchHeader;
