import SuccessIcon from "@mui/icons-material/CheckCircle";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import Button from "../../../components/Button";

const RegistrationSuccess = (props) => {
  const styles = {
    registerSuccess: {
      marginTop: (theme) => theme.spacing(4),
      marginBottom: (theme) => theme.spacing(4),
      minHeight: 400,
    },
    buttonSuccess: {
      marginTop: (theme) => theme.spacing(4),
      marginBottom: (theme) => theme.spacing(4),
    },
    successIcon: {
      fontSize: 72,
      color: (theme) => theme.palette.secondary.main,
    },
  };
  const { t } = useTranslation();
  return (
    <Container maxWidth="lg" sx={styles.registerSuccess}>
      <div style={{ margin: 32 }}>
        <SuccessIcon sx={styles.successIcon} />
      </div>
      <Typography variant="h3" style={{ fontSize: 20, textTransform: "initial" }}>
        {t("Thank_you_registration")}
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={styles.buttonSuccess}>
        <Button variant="contained" component={RouterLink} sx={styles.button} color="secondary" to={"/"}>
          {t("Return to home")}
        </Button>
      </Grid>
    </Container>
  );
};

export default RegistrationSuccess;
